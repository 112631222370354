import * as React from "react";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import dayjs from "dayjs";

export default function DateTimeSelector({ setDateRange, sx }) {
  const [selectedOption, setSelectedOption] = React.useState("");

  const handleChange = (event) => {
    setSelectedOption(event.target.value);
    updateDateRange(event.target.value);
  };

  const updateDateRange = (selectedValue) => {
    if (selectedValue === "none") {
      setDateRange(null, null);
      return;
    }

    const today = dayjs();
    let startDate, endDate;

    switch (selectedValue) {
      case "thisDay":
        startDate = today.startOf("day").toDate();
        endDate = today.endOf("day").toDate();
        break;
      case "lastWeek":
        startDate = today.subtract(1, "week").startOf("day").toDate();
        endDate = today.endOf("day").toDate();
        break;
      case "lastMonth":
        startDate = today.subtract(1, "month").startOf("month").toDate();
        endDate = today.endOf("month").toDate();
        break;
      case "threeMonthsBefore":
        startDate = today.subtract(3, "month").startOf("month").toDate();
        endDate = today.endOf("month").toDate();
        break;
      case "beforeYear":
        startDate = today.subtract(1, "year").startOf("year").toDate();
        endDate = today.endOf("year").toDate();
        break;
      default:
        startDate = null;
        endDate = null;
    }
    if (startDate && endDate) {
      startDate = startDate.toISOString().split('T')[0];
      endDate = endDate.toISOString().split('T')[0];
    }
    setDateRange(startDate, endDate);
  };

  return (
    <Box
      sx={{
        minWidth: 200,
        "& .MuiInputBase-root": {
          borderRadius: 2,
          backgroundColor: "grey.200",
        },
        ...sx
      }}
    >
      <FormControl fullWidth size="small">
        <InputLabel id="label-selector">Select Date Range</InputLabel>
        <Select
          labelId="label-selector"
          id="demo-simple-select"
          value={selectedOption}
          label="Select Date Range"
          onChange={handleChange}
        >
          <MenuItem value="">None</MenuItem>
          <MenuItem value={"thisDay"}>This Day</MenuItem>
          <MenuItem value={"lastWeek"}>Last Week</MenuItem>
          <MenuItem value={"lastMonth"}>Last Month</MenuItem>
          <MenuItem value={"threeMonthsBefore"}>3 Months Before</MenuItem>
          <MenuItem value={"beforeYear"}>Before Year</MenuItem>
        </Select>
      </FormControl>
    </Box>
  );
}
