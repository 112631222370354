import * as React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import DoneOutlineIcon from "@mui/icons-material/DoneOutline";

function CircularProgressWithLabel(props) {
  return (
    <Box sx={{ position: "relative", display: "inline-flex" }}>
      <CircularProgress variant="determinate" {...props} />

      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: "absolute",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {Math.round(props.value) === 100 ? (
          <DoneOutlineIcon color="success" />
        ) : (
          <Typography variant="caption" component="div" color="text.secondary">
            {`${Math.round(props.value)}%`}
          </Typography>
        )}
      </Box>
    </Box>
  );
}

export default function CircularSpinner({ progress }) {
  return <CircularProgressWithLabel value={progress} />;
}
