import * as React from "react";
import TextField from "@mui/material/TextField";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { IconButton } from "@mui/material";

export default function PasswordField({ value, ...other }) {
  const [showPassword, setShowPassword] = React.useState(false);

  return (
    <TextField
      value={value}
      size="small"
      type={showPassword ? "text" : "password"}
      InputProps={{
        endAdornment: (
          <IconButton
            onClick={() => setShowPassword(!showPassword)}
            edge="end"
          >
            {showPassword ? (
              <VisibilityIcon />
            ) : (
              <VisibilityOffIcon />
            )}
          </IconButton>
        ),
      }}
      {...other}
    />
  );
}