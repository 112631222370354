import * as React from "react";
import { styled, useTheme } from "@mui/material/styles";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import SmartDisplayIcon from "@mui/icons-material/SmartDisplay";
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";
import {
  Box,
  Button,
  Collapse,
  StepContent,
  useMediaQuery,
} from "@mui/material";
import ContactsIcon from "@mui/icons-material/Contacts";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import BackupIcon from "@mui/icons-material/Backup";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';


const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 22,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage:
        "linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)",
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage:
        "linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)",
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 3,
    minHeight: 0,
    border: 0,
    backgroundColor:
      theme.palette.mode === "dark" ? theme.palette.grey[800] : "#eaeaf0",
    borderRadius: 1,
  },
}));
const ColorlibStepIconRoot = styled("div")(({ theme, ownerState }) => ({
  backgroundColor:
    theme.palette.mode === "dark" ? theme.palette.grey[700] : "#ccc",
  zIndex: 1,
  color: "#fff",
  width: 50,
  height: 50,
  display: "flex",
  borderRadius: "50%",
  justifyContent: "center",
  alignItems: "center",
  ...(ownerState.active && {
    backgroundImage:
      "linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)",
    boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
  }),
  ...(ownerState.completed && {
    backgroundImage:
      "linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)",
  }),
}));

function ColorlibStepIcon(props) {
  const { active, completed, className } = props;

  const icons = {
    1: <SmartDisplayIcon />,
    2: <ContactsIcon />,
    3: <AccountBoxIcon />,
    4: <BackupIcon />,
  };

  return (
    <ColorlibStepIconRoot
      ownerState={{ completed, active }}
      className={className}
    >
      {icons[String(props.icon)]}
    </ColorlibStepIconRoot>
  );
}

const StepBody = (props) => {
  const handleNextStep = () => {
    props.setActiveStep((pre) => pre + 1);
  };
  const handleBackStep = () => {
    props.setActiveStep((pre) => (pre > 0 ? pre - 1 : 0));
  };
  const Buttons = () => (
    <Box display="flex" justifyContent="space-between" mt={2}>
      <Button
        disabled={props.backDisabled}
        variant="outlined"
        color="inherit"
        sx={{ borderRadius: 2, textTransform: "none" }}
        onClick={handleBackStep}
        startIcon={<ArrowBackIcon />}
      >
        Back
      </Button>
      <Button
        disabled={props.nextDisabled}
        sx={{ borderRadius: 2, textTransform: "none" }}
        color="success"
        variant="outlined"
        onClick={handleNextStep}
        endIcon={<ArrowForwardIcon />}
      >
        Next
      </Button>
    </Box>
  )
  return (
    <Box width="100%">
      <Buttons />
      <br />
      {props.children}
      <Buttons />
    </Box>
  );
};
export default function SteppsIndexes({
  steps,
  activeStep,
  setActiveStep,
  backDisabled = false,
  nextDisabled = false,
}) {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Box width="100%" display="flex" flexDirection="column" gap={3}>
      <Stepper
        // nonLinear={isSmallScreen}
        nonLinear
        // alternativeLabel={!isSmallScreen}
        alternativeLabel
        activeStep={activeStep}
        // orientation={isSmallScreen ? "vertical" : "horizontal"}
        orientation={"horizontal"}
        connector={<ColorlibConnector />}
      >
        {steps.map((step, index) => (
          <Step key={step.label}>
            <StepLabel StepIconComponent={ColorlibStepIcon}>
              {step.label}
            </StepLabel>
            {isSmallScreen && (
              <StepContent>
                <StepBody
                  backDisabled={activeStep === 0}
                  nextDisabled={activeStep === steps.length - 1}
                  setActiveStep={setActiveStep}
                >
                  <step.Content />
                </StepBody>
              </StepContent>
            )}
          </Step>
        ))}
      </Stepper>
      <Collapse
        unmountOnExit
        // in={activeStep < 4 && !isSmallScreen}
        in={activeStep < 4}
        sx={{ ml: 5, mr: 5, mt: 2 }}
      >
        <StepBody
          backDisabled={backDisabled}
          nextDisabled={nextDisabled}
          setActiveStep={setActiveStep}
        >
          {steps[activeStep].Content()}
        </StepBody>
      </Collapse>
    </Box>
  );
}
