import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Grid } from "@mui/material";
import { toast } from "react-toastify";
import api from "../../../conf/api";
import TextInput from "../../../Components/Fields/TextInput.js";
import Autocomplete from "@mui/material/Autocomplete";
import CircularProgress from "@mui/material/CircularProgress";
import { isValidYtId } from "../../../utils.js";

export default function CompilationPopupBox({
  open,
  is_update,
  selectedRow,
  refreshFunc,
  onClose,
}) {
  const initialFormData = {
    channel: selectedRow.channel_obj?.name || "",
    video_title: selectedRow.video_title || "",
    youtube_video_id: ""
  };
  const [formData, setFormData] = React.useState(initialFormData);
  const [channels, setChannels] = React.useState({ options: [], loading: false });

  async function fetchChannels() {
    setChannels((pre) => ({ ...pre, loading: true }));
    await api
      .get("/promo/channels", { params: { page: 1, page_size: 10000, approved: true } })
      .then((res) => {
        setChannels((pre) => ({ ...pre, options: res.data?.results }));
      })
      .finally(() => {
        setChannels((pre) => ({ ...pre, loading: false }));
      });
  }

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleClose = () => {
    onClose();
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (formData.youtube_video_id.length > 0 && !isValidYtId(formData.youtube_video_id)) {
      toast.error("Youtube id is not valid.")
      return;
    }
    if (is_update) {
      const updateCompilation = async () => {
        await api
          .patch(`/promo/compilations/${selectedRow.id}/`, formData)
          .then((res) => {
            if (res.status === 200) {
              toast.success("Compilation updated successfully.");
              refreshFunc();
              setFormData(formData);
              onClose();
            }
          })
          .catch((error) => {
            const errorObject = error.response?.data;
            try {
              for (const field in errorObject) {
                if (errorObject?.hasOwnProperty(field)) {
                  const errors = errorObject[field];
                  const formattedFieldErrors = errors.map(
                    (errorMessage) => `${field}:${errorMessage}`
                  );
                  formattedFieldErrors?.map((msg) => toast.error(msg));
                }
              }
            } catch {
              try {
                toast.error(JSON.stringify(errorObject));
              } catch {
                toast.error("Something went wrong");
              }
            }
          });
      };
      updateCompilation();
    } else {
      const data = formData;
      await api
        .post("/promo/compilations/", data)
        .then((response) => {
          if (response.status === 201) {
            toast.success(response.data?.message);
            refreshFunc();
            onClose();
          }
        })
        .catch((error) => {
          const errorObject = error.response?.data;
          try {
            for (const field in errorObject) {
              if (errorObject?.hasOwnProperty(field)) {
                const errors = errorObject[field];
                const formattedFieldErrors = errors.map(
                  (errorMessage) => `${field}:${errorMessage}`
                );
                formattedFieldErrors?.map((msg) => toast.error(msg));
              }
            }
          } catch {
            try {
              toast.error(JSON.stringify(errorObject));
            } catch {
              toast.error("Something went wrong");
            }
          }
        });
    }
  };

  return (
    <Dialog maxWidth="sm" open={open} onClose={handleClose}>
      <DialogTitle>{is_update ? "Update" : "Create"} Compilation</DialogTitle>
      <DialogContent>
        <DialogContentText mb={2}>
          Please fill in the required information to{" "}
          {is_update ? "update" : "create"} a new compilation.
        </DialogContentText>
        <form onSubmit={handleSubmit}>
          <Grid container marginBottom={3} spacing={3} alignItems="center">
            <Grid item xs={12}>
              <Autocomplete
                disablePortal
                loading={channels.loading}
                onOpen={fetchChannels}
                getOptionLabel={(op) => op.name}
                size="small"
                fullWidth
                options={channels.options}
                placeholder="Select Channel"
                value={selectedRow.channel_obj}
                onChange={(e, newValue) => {
                  setFormData((pre) => ({ ...pre, channel: newValue?.name }))
                }}
                renderInput={(params) => (
                  <TextInput
                    {...params}
                    required
                    label="Channels"
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {channels.loading ? (
                            <CircularProgress color="inherit" size={20} />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      ),
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <TextInput
                label="Video Title"
                type="text"
                fullWidth
                required
                name="video_title"
                value={formData.video_title}
                onChange={handleChange}
              />
            </Grid>

          </Grid>
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button variant="contained" type="submit" color="primary">
              {is_update ? "Update" : "Create"} Compilation
            </Button>
          </DialogActions>
        </form>
      </DialogContent>
    </Dialog >
  );
}
