import * as React from "react";
import { useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { toast } from "react-toastify";
import PlayCircleIcon from "@mui/icons-material/PlayCircle";
import CloseIcon from "@mui/icons-material/Close";
import CircularSpinner from "../../../Components/Progress/CircularSpinner";
import { useDropzone } from "react-dropzone";
import InfoIcon from "@mui/icons-material/Info";
import {
  Box,
  IconButton,
  LinearProgress,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import api from "../../../conf/api";
import Cookies from "js-cookie";
import axios from "axios";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function ReUploadDialogBox({
  open,
  setOpen,
  selectedVideo,
  refrashFunc,
}) {
  const [video, setVideo] = useState(null);
  const [progress, setProgress] = useState({ loading: false, progress: 0 });
  const videoFormats = [
    "mp4",
    "webm",
    "mkv",
    "mov",
    "flv",
    "wmv",
    "mpeg",
    "mpg",
    "ogv",
  ];
  const onDrop = async (acceptedFiles) => {
    const file = acceptedFiles[0];
    if (file.type.startsWith("video/")) {
      const formate = file.path.split(".").splice(-1)[0];
      setVideo(null);
      if (!videoFormats.includes(formate)) {
        toast.error(`${file.name} not spported.`);
        return;
      }
      // Load the video to get its duration
      const _video = document.createElement("video");
      _video.src = URL.createObjectURL(file);

      _video.onloadedmetadata = () => {
        const duration = _video.duration;
        if (duration >= 20) {
          const newVideo = {
            loading: false,
            title: file.name.replace(/\.[^/.]+$/, ""),
            file: file,
            extension: file.name.split(".").pop(),
            duration: duration,
          };

          setVideo(newVideo);
        } else {
          toast.info(
            `Video ${file.name} is too short. It should be at least 20 seconds.`
          );
        }

        // Clean up the video element
        URL.revokeObjectURL(_video.src);
      };
    }
  };
  const submitVideo = async () => {
    if (!video) {
      return;
    }
    const formData = new FormData();
    formData.append("file", video.file);
    formData.append("data", JSON.stringify(video));
    await api
      .post(`/videos/reupload/${selectedVideo.id}/`, formData, {
        headers: {
          Authorization: `Bearer ${Cookies.get("access_token")}`,
        },
        timeout: 10 * 60 * 1000,
        onUploadProgress: (progressEvent) => {
          const progress = (progressEvent.loaded / progressEvent.total) * 100;
          setProgress({ loading: true, progress: progress });
        },
      })
      .then((res) => {
        if (res.status === 200) {
          toast.success(res.data);
          refrashFunc();
          setOpen(false);
        }
      })
      .catch((error) => {
        if (axios.isCancel(error)) {
          // Handle request cancellation (if needed)
          toast.error("Request canceled:", error.message);
        } else if (error.code === "ECONNABORTED") {
          // Handle timeout error
          toast.error("The request timed out. Please try again.");
          console.log("Timeout error:", error);
        } else {
          // Handle other errors
          toast.error(
            error.response
              ? error.response.data
              : "An unexpected error occurred."
          );
          console.log("Error updating data:", error);
        }
      })
      .finally((res) => {
        setProgress({ progress: 0, loading: false });
      });
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: "video/*",
    multiple: false,
  });

  const handleClose = () => {
    setOpen(false);
  };
  const handleVideoTitle = (e) => {
    let _video = video;
    _video.title = e.target.value;
    setVideo(_video);
  };
  const removeVideo = (event) => {
    event.stopPropagation();
    setVideo(null);
  };

  return (
    <Dialog
      open={open}
      maxWidth="lg"
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle>{`Reupload video (${selectedVideo.id}) ?`}</DialogTitle>
      <DialogContent>
        <DialogContentText mb={2} id="alert-dialog-slide-description">
          <strong>Selected video Title: </strong>
          {selectedVideo.title}
        </DialogContentText>
        <Box
          {...getRootProps()}
          sx={{
            width: { xs: 400, md: 800 },
            border: "2px dashed",
            borderColor: "primary.dark",
            borderRadius: 4,
            padding: 2.5,
            minHeight: 250,
            textAlign: "center",
            cursor: "pointer",
            "&:hover": {
              backgroundColor: "grey.100",
            },
          }}
        >
          <input {...getInputProps()} />
          <Typography variant="h6" fontWeight="bold" mt={2}>
            Drag video file here, or{" "}
            <Typography component="span" color="primary" fontWeight="bold">
              click
            </Typography>{" "}
            to select files
          </Typography>
          <Typography variant="body1">
            Allowed{" "}
            <Tooltip title={videoFormats.map((fmt) => fmt + ", ")}>
              <Box
                component="strong"
                display="inline-flex"
                alignItems="center"
                sx={{ textDecoration: "underline", color: "primary.main" }}
              >
                formats <InfoIcon fontSize="small" />
              </Box>
            </Tooltip>
          </Typography>

          <Box sx={{ mt: 2 }}>
            <Box>
              {video && (
                <Box
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center"
                >
                  <CloudUploadIcon
                    sx={{
                      fontSize: { md: 150, xs: 100 },
                      color: "grey",
                      mt: 2,
                    }}
                  />
                  <Typography variant="h5">Upload Videos</Typography>
                </Box>
              )}
            </Box>

            <List
              disablePadding
              sx={{
                zIndex: 2,
                width: "100%",
                display: video ? "flex" : "none",
              }}
            >
              {video && (
                <ListItem
                  secondaryAction={
                    <IconButton disabled={video.loading} onClick={removeVideo}>
                      <CloseIcon />
                    </IconButton>
                  }
                >
                  <ListItemAvatar>
                    {video.loading ? (
                      <CircularSpinner
                        progress={video?.file?.uploadedPercentage}
                      />
                    ) : (
                      <Tooltip title={"." + video.extension}>
                        <PlayCircleIcon fontSize="large" color="success" />
                      </Tooltip>
                    )}
                  </ListItemAvatar>

                  <ListItemText
                    primary={
                      <TextField
                        size="small"
                        variant="standard"
                        fullWidth
                        disabled={video.loading}
                        defaultValue={video.title}
                        onClick={(e) => {
                          e.stopPropagation();
                        }}
                        onChange={handleVideoTitle}
                      />
                    }
                    secondary={`${(video.file.size / 1024).toFixed(2)} KB`}
                  />
                </ListItem>
              )}
            </List>
          </Box>
        </Box>
        {progress.loading && <LinearProgress sx={{ mt: 1 }} />}
      </DialogContent>
      <DialogActions>
        <Button disabled={progress.loading} onClick={handleClose}>
          Close
        </Button>
        {progress.loading ? (
          <CircularSpinner progress={progress.progress} />
        ) : (
          <Button onClick={submitVideo} variant="contained">
            Confirm upload
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}
