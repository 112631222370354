import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Grid } from "@mui/material";
import { toast } from "react-toastify";
import TextInput from "../../../../Components/Fields/TextInput.js"
import api from "../../../../conf/api";

export default function LanguageBox({
  open,
  is_update,
  selectedRow,
  refreshFunc,
  onClose
}) {
  let initialFormData = {
    name: "",
  };

  const [formData, setFormData] = React.useState(initialFormData);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleClose = () => {
    onClose();
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (is_update) {
      const updateLanguage = async () => {
        await api
          .patch(`/youtube/languages/${selectedRow}/`, formData)
          .then((res) => {
            if (res.status === 200) {
              toast.success("Language updated successfully.");
              refreshFunc();
              setFormData(formData);
              onClose();
            }
          })
          .catch((error) => {
            const errorObject = error.response?.data;
            try {
              for (const field in errorObject) {
                if (errorObject?.hasOwnProperty(field)) {
                  const errors = errorObject[field];
                  const formattedFieldErrors = errors.map(
                    (errorMessage) => `${field}:${errorMessage}`
                  );
                  formattedFieldErrors?.map((msg) => toast.error(msg));
                }
              }
            } catch {
              try {
                toast.error(JSON.stringify(errorObject));
              } catch {
                toast.error("Something went wrong");
              }
            }
          });
      };
      updateLanguage();
    } else {
      const data = formData;
      await api
        .post("/youtube/languages/", data)
        .then((response) => {
          if (response.status === 201) {
            toast.success(response.data?.message);
            refreshFunc();
            onClose();
          }
        })
        .catch((error) => {
          const errorObject = error.response?.data;
          try {
            for (const field in errorObject) {
              if (errorObject?.hasOwnProperty(field)) {
                const errors = errorObject[field];
                const formattedFieldErrors = errors.map(
                  (errorMessage) => `${field}:${errorMessage}`
                );
                formattedFieldErrors?.map((msg) => toast.error(msg));
              }
            }
          } catch {
            try {
              toast.error(JSON.stringify(errorObject));
            } catch {
              toast.error("Something went wrong");
            }
          }
        });
    }
  };

  React.useEffect(() => {
    async function fetchData() {
      await api
        .get("/youtube/languages/" + selectedRow)
        .then((res) => {
          const _data = res.data;
          setFormData(_data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
    if (is_update) {
      fetchData();
    }
    // eslint-disable-next-line
  }, []);

  return (
    <Dialog maxWidth="md" open={open} onClose={handleClose}>
      <DialogTitle>{is_update ? "Update" : "Add"} Language</DialogTitle>
      <DialogContent>
        <DialogContentText mb={2}>
          Please fill in the required information to{" "}
          {is_update ? "update" : "add"} a new language.
        </DialogContentText>
        <form onSubmit={handleSubmit}>
          <Grid container marginBottom={3} spacing={3} alignItems="center">
            <Grid item xs={12}>
              <TextInput
                label="Language Name"
                type="text"
                fullWidth
                required
                name="name"
                value={formData.name}
                onChange={handleChange}
              />
            </Grid>
          </Grid>
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button variant="contained" type="submit" color="primary">
              {is_update ? "Update" : "Add"} Language
            </Button>
          </DialogActions>
        </form>
      </DialogContent>
    </Dialog>
  );
}
