import React from "react";
import {
  Avatar,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Tooltip,
  Typography,
} from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ReportIcon from '@mui/icons-material/Report';

function InfoCard({ data, handleSelect, verified = false, disbaleSelection = false, selected = false, children, translationFailed, sx }) {
  return (
    <Card sx={{
      height: "100%",
      minWidth: 250,
      borderRadius: 4,
      minHeight: 150,
      bgcolor: !selected && "transparent",
      border: !selected && "1px solid #a6a6a6",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      ...sx
    }}>
      <CardContent>
        <Box display="flex" gap={2.5} alignItems="center" position="relative">
          <Avatar
            alt={data.title}
            src="https://cdn1.iconfinder.com/data/icons/user-pictures/101/malecostume-512.png"
          />
          {verified && (
            <Box position="absolute" top={-5} left={-5}>
              <CheckCircleIcon fontSize="small" sx={{ color: "primary.light" }} />
            </Box>
          )}
          {translationFailed && (
            <Tooltip title={"Translation Failed! Contains Original Data"} placement="right-end">
              <Box position="absolute" top={-5} right={-5}>
                <ReportIcon sx={{ color: "warning.light" }} />
              </Box>
            </Tooltip>
          )}
          <Box>
            <Typography variant="subtitle1">{data.title}</Typography>
            <Typography variant="caption">{data.email}</Typography>
          </Box>
        </Box>

        {children}
      </CardContent>
      {!disbaleSelection && (
        <CardActions>
          <Button
            size="small"
            variant="contained"
            color={selected ? "error" : "success"}
            sx={{ textTransform: "none" }}
            onClick={() => {
              handleSelect && handleSelect(data);
            }}
          >
            {selected ? "Unselect" : "Select"}
          </Button>
        </CardActions>
      )}
    </Card>
  );
}

export default InfoCard;
