import React, { useState, useEffect } from "react";
import {
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableSortLabel,
  TablePagination,
  Box,
  Typography,
  IconButton,
} from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import Scrollbars from "react-custom-scrollbars-2";

const GenericTable = ({
  data,
  columns,
  defaultOrderBy,
  defaultOrder,
  defaultRowsPerPage,
  fetchData,
  pagination = true,
  rowCountState,
  onRowRemove = (row) => {},
}) => {
  const [orderBy, setOrderBy] = useState(defaultOrderBy);
  const [order, setOrder] = useState(defaultOrder);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(defaultRowsPerPage);

  useEffect(() => {
    fetchData(page, rowsPerPage, orderBy, order);
    // eslint-disable-next-line
  }, [page, rowsPerPage, orderBy, order]);

  const handleSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const sortedData = [...data].sort((a, b) => {
    const result = order === "asc" ? -1 : 1;
    return (a[orderBy] < b[orderBy] ? -1 : 1) * result;
  });

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <Box>
      <TableContainer
        component={Scrollbars}
        sx={{
          p: { xs: 2, md: 4 },
          minHeight: 600,
        }}
      >
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell key={column.id} sx={{ ...column.sx }}>
                  {column.sortable ? (
                    <TableSortLabel
                      active={orderBy === column.id}
                      direction={orderBy === column.id ? order : "asc"}
                      onClick={() => handleSort(column.id)}
                    >
                      {column.label}
                    </TableSortLabel>
                  ) : (
                    column.label
                  )}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>

          <TableBody>
            {sortedData
              // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => (
                <TableRow
                  sx={{
                    bgcolor: row.dublicated ? "#ffdad9" : "transparent",
                  }}
                  key={row.id}
                >
                  {columns.map((column, i) => (
                    <TableCell key={column.id}>
                      {i === 0 && row.dublicated && (
                        <IconButton
                          size="small"
                          onClick={() => onRowRemove(row)}
                        >
                          <CancelIcon fontSize="small" />
                        </IconButton>
                      )}
                      {column.render ? column.render(row) : row[column.id]}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            {sortedData.length < 1 && (
              <TableRow>
                <TableCell colSpan={5}>
                  <Typography textAlign="center" variant="h6" color="grey">
                    No data
                  </Typography>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {pagination && (
        <TablePagination
          component="div"
          count={rowCountState}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      )}
    </Box>
  );
};

export default GenericTable;
