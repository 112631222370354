import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  TextField,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import api, { baseURL } from "../../conf/api";
import { DataGrid } from "@mui/x-data-grid";
import Scrollbars from "react-custom-scrollbars-2";
import ThumbDownAltIcon from "@mui/icons-material/ThumbDownAlt";
import ThumbUpAltIcon from "@mui/icons-material/ThumbUpAlt";
import { toast } from "react-toastify";
import ReUploadDialogBox from "./components/ReUploadDialogBox";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import DownloadingIcon from "@mui/icons-material/Downloading";
import VideoPreviewBox from "./components/VideoPreviewBox";
import CircleIcon from "@mui/icons-material/Circle";
import CloudSyncIcon from "@mui/icons-material/CloudSync";
import ModelBox from "../../Components/Dialogs/ModelBox";
import DeleteIcon from '@mui/icons-material/Delete';
import { connect } from "react-redux";


function VideoPreview(props) {
  const [isDropBoxConnected, setDropboxStatus] = useState(undefined);
  const [loading, setLoading] = useState(false);
  const [rejectionDialog, showRejectionDialog] = useState(false);
  const [videos, setVideos] = useState([]);
  const [selectedVideo, selectVideo] = useState({});
  const [uploadDialog, openUploadDialog] = useState(false);
  const [videoPreviewBox, openVideoPreviewBox] = useState(false);
  const initialPagination = {
    page: 0,
    rowPerPage: 10,
    count: 0,
  };
  const [pagination, setPagination] = useState(initialPagination);

  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("md"));

  function downloadVideo(url) {
    const videoUrl = url;

    // Create an anchor element
    const link = document.createElement("a");
    link.target = "_blank";
    link.href = videoUrl;

    // Extract the file name from the URL (you can customize this based on your backend logic)
    const fileName = videoUrl.substring(videoUrl.lastIndexOf("/") + 1);

    // Set the download attribute with the file name
    link.download = fileName;

    // Append the anchor element to the document
    document.body.appendChild(link);

    // Trigger a click event on the anchor element to start the download
    link.click();

    // Remove the anchor element from the document
    document.body.removeChild(link);
  }
  const columns = [
    props.user?.is_staff && {
      field: "action",
      align: "center",
      headerAlign: "center",
      headerName: "Approve / Reject",
      minWidth: 200,
      renderCell: (params) => (
        <Box display="flex" gap={1}>
          {!params.row.approved && (
            <Tooltip title="Click to approve">
              <IconButton
                disabled={!isDropBoxConnected}
                onClick={() => {
                  handlePartialUpdate(params.row.id, "approve");
                }}
              >
                <ThumbUpAltIcon
                  color={isDropBoxConnected ? "success" : "grey"}
                />
              </IconButton>
            </Tooltip>
          )}
          {params.row.status !== "Pending" &&
            params.row.status !== "Running" &&
            params.row.status !== "INQUEUE" && (
              <Tooltip title="Click to reupload">
                <IconButton
                  onClick={() => {
                    selectVideo(params.row);
                    openUploadDialog(true);
                  }}
                >
                  <CloudUploadIcon color="primary" />
                </IconButton>
              </Tooltip>
            )}

          {!params.row?.rejected &&
            !params.row?.dropbox_link &&
            params.row.status !== "Running" &&
            params.row.status !== "INQUEUE" && (
              <Tooltip title="Click to reject">
                <IconButton
                  onClick={() => {
                    showRejectionDialog(true);
                    selectVideo(params.row);
                  }}
                >
                  <ThumbDownAltIcon color="error" />
                </IconButton>
              </Tooltip>
            )}
          {params.row.status === "Failed" && (
            <Tooltip title="Restart process">
              <IconButton onClick={handleReStart(params.row.id)}>
                <CloudSyncIcon color="info" />
              </IconButton>
            </Tooltip>
          )}
          {params.row.status !== "Pending" &&
            params.row.status !== "Running" &&
            params.row.status !== "INQUEUE" &&
            !params.row.approved &&
            <Tooltip title="Delete Video">
              <IconButton onClick={handleDelete(params.row.id)}>
                <DeleteIcon color="error" />
              </IconButton>
            </Tooltip>
          }
        </Box>
      ),
    },
    {
      field: "file",
      headerName: "Download",
      minWidth: 90,
      renderCell: (params) => (
        <Tooltip title="Download">
          <IconButton
            onClick={() => {
              if (params.row.dropbox_link) {
                downloadVideo(params.row.dropbox_link);
              } else {
                // downloadVideo(params.value);
                selectVideo(params.row);
                openVideoPreviewBox(true);
              }
            }}
          >
            <DownloadingIcon color={"primary"} />
          </IconButton>
        </Tooltip>
      ),
    },
    { field: "id", headerName: "ID", minWidth: 120 },
    {
      field: "assert_id",
      headerName: "Assets",
      minWidth: 120,
      renderCell: (params) =>
        params.value ? params.value : <i style={{ color: "grey" }}>none</i>,
    },
    {
      field: "status",
      headerName: "Status",
      minWidth: isSmallScreen ? 100 : 120,
    },
    {
      field: "title",
      headerName: "Title",
      minWidth: isSmallScreen ? 150 : 300,
    },
    {
      field: "duration",
      headerName: "Duration",
      minWidth: isSmallScreen ? 70 : 90,
    },
    {
      field: "approved",
      type: "boolean",
      headerName: "Approved",
      minWidth: isSmallScreen ? 70 : 90,
    },
    {
      field: "approval_date",
      type: "datetime",
      headerName: "Approval Date",
      minWidth: isSmallScreen ? 150 : 200,
      valueGetter: (params) => {
        if (!params.value) {
          return "00/00/000, 00:00";
        }
        const date = new Date(params.value);
        return date.toLocaleString();
      },
    },
    {
      field: "rejected",
      type: "boolean",
      headerName: "Rejected",
      minWidth: isSmallScreen ? 70 : 90,
    },
    {
      field: "rejection_note",
      headerName: "Rejection Reason",
      minWidth: isSmallScreen ? 150 : 200,
      renderCell: (params) =>
        params.value ? params.value : <i style={{ color: "grey" }}>none</i>,
    },
    {
      field: "rejected_date",
      type: "datetime",
      headerName: "Rejection Date",
      minWidth: isSmallScreen ? 150 : 200,
      valueGetter: (params) => {
        if (!params.value) {
          return "00/00/000, 00:00";
        }
        const date = new Date(params.value);
        return date.toLocaleString();
      },
    },
    {
      field: "duration",
      headerName: "Duration",
      minWidth: isSmallScreen ? 70 : 90,
    },
    {
      field: "dropbox_link",
      headerName: "DropBox",
      minWidth: isSmallScreen ? 300 : 600,
      renderCell: (params) =>
        params.value ? (
          <a href={params.value} target="_blank" rel="noopener noreferrer">
            {params.value}
          </a>
        ) : (
          <Typography variant="caption">Not link</Typography>
        ),
    },
  ];
  const FetchData = async (page = pagination.page, page_size = pagination.rowPerPage) => {
    setLoading(true);
    try {
      const res = await api.get("/videos/api", {
        params: {
          page: page + 1,
          page_size: page_size,
        },
      });

      if (res.status === 200) {
        setVideos(res.data.results);
        setPagination((prev) => ({ ...prev, count: res.data.count }));
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const res = await api.get("/videos/api", {
          params: {
            page: pagination.page + 1,
            page_size: pagination.rowPerPage,
          },
        });

        if (res.status === 200) {
          setVideos(res.data.results);
          setPagination((prev) => ({ ...prev, count: res.data.count }));
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [pagination.page, pagination.rowPerPage]);

  const handlePartialUpdate = async (videoId, dataToUpdate, note = "") => {
    // try {
    await api
      .post(`/videos/approve/${videoId}/${dataToUpdate}/`, {
        note: note,
      })
      .then((res) => {
        if (res.status === 200) {
          toast.success(res.data);
          setPagination(initialPagination);
          FetchData();
        } else {
        }
      })
      .catch((err) => {
        toast.error(err.response?.data);
        // fetchData();
      });
    // } catch (resp) {
    //   toast.error(resp.response.data);
    //   console.log("Error updating data:", resp);
    // }
  };
  const handleReStart = (videoID) => async () => {
    api
      .get(`/videos/restartvideo/${videoID}`)
      .then((res) => {
        if (res.status === 200) {
          toast.success(res.data);
          FetchData();
        }
      })
      .catch((res) => {
        toast.error(res.response.data);
      });
  };
  const handleDelete = (videoID) => () => {
    api
      .delete(`/videos/api/${videoID}`)
      .then((res) => {
        if (res.status === 204) {
          toast.info(videoID + " Deleted successfully.");
          FetchData(pagination.page, pagination.rowPerPage);
        }
      })
      .catch((res) => {
        toast.error(res.response?.data);
        console.log(res.response)
      });

  };

  const VideoDialog = useCallback(() => {
    return (
      <ReUploadDialogBox
        open={uploadDialog}
        setOpen={openUploadDialog}
        selectedVideo={selectedVideo}
        refrashFunc={FetchData}
      />
    );
    // eslint-disable-next-line
  }, [uploadDialog, selectedVideo]);

  const VideoPreviewDialogBox = useCallback(() => {
    return (
      <VideoPreviewBox
        downloadVideo={downloadVideo}
        video={selectedVideo}
        open={videoPreviewBox && selectedVideo.file}
        onClose={() => {
          openVideoPreviewBox(false);
        }}
      />
    );
  }, [videoPreviewBox, selectedVideo]);

  useEffect(() => {
    const CheckDropBoxSatatus = async () => {
      try {
        const res = await api.get("/auth/dropbox/authenticated");

        if (res.status === 200) {
          setDropboxStatus(true);
        }
      } catch (err) {
        setDropboxStatus(false);
        // GetDropboxURL();
      }
    };

    // Run the function initially
    CheckDropBoxSatatus();

    // Set up an interval to run the function every 2 minutes (120000 milliseconds)
    const intervalId = setInterval(CheckDropBoxSatatus, 120000);

    // Clean up the interval when the component is unmounted
    return () => clearInterval(intervalId);
  }, []);

  const RejectionNoteDialog = useCallback(() => {
    const onConfirm = () => {
      const note = document.getElementById("rejection_note").value;
      if (note && selectedVideo.id) {
        handlePartialUpdate(selectedVideo.id, "reject", note);
        document.getElementById("rejection_note").value = "";
        showRejectionDialog(false);
      } else {
        toast.warning("Write some note.");
      }
    };
    return (
      <ModelBox
        open={rejectionDialog}
        setOpen={showRejectionDialog}
        title={"Rejection Note ( " + selectedVideo.id + " )"}
      >
        <Box mt={1} textAlign="right">
          <TextField
            multiline
            minRows={4}
            id="rejection_note"
            fullWidth
            placeholder="Cause of rejection."
          />
          <Button
            sx={{ mt: 2, textTransform: "none" }}
            variant="contained"
            color="warning"
            startIcon={<ThumbDownAltIcon color="error" />}
            onClick={onConfirm}
          >
            Confirm reject
          </Button>
        </Box>
      </ModelBox>
    );
    // eslint-disable-next-line
  }, [rejectionDialog, selectedVideo]);

  return (
    <Box display="flex" flexDirection="column" justifyContent="center" gap={2}>
      <Typography variant="h4" paragraph>
        Videos
      </Typography>

      {props.user?.is_staff && (
        <Box
          display={isDropBoxConnected === undefined ? "none" : "flex"}
          alignItems="center"
          component={!isDropBoxConnected && "a"}
          href={`${baseURL}/auth/dropbox/access_token/`}
          target="_blank"
        >
          <Tooltip
            title={!isDropBoxConnected && "Click to authenticate dropbox"}
          >
            <IconButton size="large">
              <CircleIcon
                fontSize="large"
                color={isDropBoxConnected ? "success" : "error"}
              />
            </IconButton>
          </Tooltip>
          <Tooltip
            title={!isDropBoxConnected && "Click to authenticate dropbox"}
          >
            <Typography variant="h5">
              Storage is {isDropBoxConnected ? "connected" : "disconnected"}
            </Typography>
          </Tooltip>
        </Box>
      )}

      {loading ? (
        <Box display="flex" justifyContent="center">
          <CircularProgress />
        </Box>
      ) : (
        <Scrollbars style={{ minHeight: 600, width: "100%" }}>
          <Box flex="1">

            <DataGrid
              columns={columns}
              rows={videos}
              loading={loading}
              autoHeight
              paginationMode="server"
              paginationModel={{
                page: pagination.page,
                pageSize: pagination.rowPerPage,
              }}
              rowCount={pagination.count}
              pageSizeOptions={[10, 50, 100]}
              onPaginationModelChange={(newPage) => {
                const { page, pageSize } = newPage;
                setPagination((prev) => ({
                  ...prev,
                  page: page,
                  rowPerPage: pageSize,
                }));
              }}
            />
          </Box>
        </Scrollbars>
      )}

      <VideoPreviewDialogBox />
      <VideoDialog />
      <RejectionNoteDialog />
    </Box>
  );
}

const mapStatesToProps = (state) => {
  return {
    user: state.user,
  };
};

export default connect(mapStatesToProps, null)(VideoPreview);
