import React, { useCallback, useEffect, useState } from "react";
import {
  Box,
  IconButton,
  Paper,
  Tooltip,
  Typography,
  Grid,
  Button,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import api from "../../conf/api";
import Scrollbars from "react-custom-scrollbars-2";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import CompilationPopupBox from "./components/CompilationPopupBox.js";
import CreateIcon from "@mui/icons-material/Create";
import DeleteIcon from "@mui/icons-material/Delete";
import ConfirmDeleteCompilationBox from "./components/ConfirmDeleteCompilationBox.js";
import DateInput from "../../Components/Fields/DatePicker.js";
import { isValidDateRange } from "../../utils.js";
import { toast } from "react-toastify";
import UserAutoComplete from "../../Components/AutoCompletes/UserAutoComplete.js"
import { useSearchParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";

function PromoCompilations(props) {
  const [searchParams, setSearchParams] = useSearchParams();
  const initialFilters = {
    date_from: searchParams.get("date_from") || null,
    date_to: searchParams.get("date_to") || null,
    user: searchParams.get("user") || null
  };
  const [filters, setFilters] = useState(initialFilters);
  const [params, setParams] = useState(null);
  const [data, setData] = useState([]);
  const [compilationBox, setCompilationBox] = useState(false);
  const [loading, setLoading] = useState(false);
  const [rowCountState, setRowCountState] = useState(0);
  const [selectedRow, setSelectedRow] = useState(0);
  const [is_update, setIsUpdate] = useState(false);
  const [confirmBox, setConfirmBox] = useState(false);
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 10,
    page: 0,
  });
  const navigation = useNavigate();

  const columns = [
    {
      field: "id",
      headerName: "ID",
      sortable: true,
      width: 90
    },
    {
      field: "channel",
      headerName: "Channel",
      minWidth: 180,
      flex: 1,
    },
    {
      field: "video_title",
      headerName: "Video Title",
      sortable: true,
      minWidth: 300
    },
    {
      field: "action",
      headerName: "Action",
      align: "center",
      headerAlign: "center",
      width: 200,
      renderCell: (params) => (
        <Box display="flex" gap={1}>
          <Tooltip title="Edit Compilation">
            <IconButton onClick={(e) => {
              e.stopPropagation();
              handleUpdateCompilation(params.row?.id)();
            }}>
              <CreateIcon color="primary" />
            </IconButton>
          </Tooltip>
          <Tooltip title="Delete Compilation">
            <IconButton onClick={(e) => {
              e.stopPropagation(); // Stop event propagation
              handleDeleteCompilation(params.row?.id)();
            }}>
              <DeleteIcon sx={{ color: "error.dark" }} />
            </IconButton>
          </Tooltip>
        </Box>
      ),
    },
  ];

  const handleFilterChange = (name, value) => {
    if (value !== null) {
      setFilters((pre) => ({
        ...pre, [name]: value,
      }));
      const queryParams = new URLSearchParams(searchParams);
      queryParams.set(name, value);
      setSearchParams(queryParams);
    }
  }

  const handleFilterClear = () => {
    const queryParams = new URLSearchParams(searchParams);
    const _filters = { ...filters };
    for (let key in _filters) {
      _filters[key] = "";
      queryParams.delete(key);
    }
    setFilters(_filters);
    setParams((pre) => ({
      ...pre,
      ..._filters
    }));
    setSearchParams(queryParams);
  }

  const handleFilterApply = (event) => {
    event.preventDefault();
    if (!isValidDateRange(filters.date_from, filters.date_to)) {
      toast.error("Invalid Date Range.");
      return;
    }
    setParams((pre) => ({
      ...pre,
      page: paginationModel.page + 1,
      page_size: paginationModel.pageSize,
      ...filters
    }));
  }

  async function fetchData(
    page = paginationModel.page,
    pageSize = paginationModel.pageSize
  ) {
    setLoading(true);
    await api
      .get("/promo/compilations", {
        params: {
          ...params,
          page: page + 1,
          page_size: pageSize
        },
      })
      .then((res) => {
        setData(res.data?.results);
        setRowCountState(res.data?.count);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  async function fetchDetail(_id) {
    await api
      .get(`/promo/compilations/${_id}`)
      .then((res) => {
        if (res.status === 200) {
          setSelectedRow(res.data);
          setCompilationBox(true);
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  useEffect(
    () => {
      fetchData();
    },
    // eslint-disable-next-line
    [paginationModel.page, paginationModel.pageSize, params]
  );

  const handleUpdateCompilation = (_id) => async () => {
    setIsUpdate(true);
    fetchDetail(_id);
  };

  const handleDeleteCompilation = (_id) => async () => {
    setSelectedRow(_id);
    setConfirmBox(true);
  };

  const CompilationDialog = useCallback(
    () => (
      <CompilationPopupBox
        open={compilationBox}
        is_update={is_update}
        selectedRow={selectedRow}
        refreshFunc={fetchData}
        onClose={() => {
          setIsUpdate(false);
          setSelectedRow(0);
          setLoading(false);
          setCompilationBox(false);
        }}
      />
    ),
    // eslint-disable-next-line
    [compilationBox, is_update, selectedRow]
  );

  const DeleteCompilationDialog = useCallback(
    () => (
      <ConfirmDeleteCompilationBox
        selectedRow={selectedRow}
        open={confirmBox}
        setOpen={setConfirmBox}
        refreshFunc={fetchData}
      />
    ),
    // eslint-disable-next-line
    [confirmBox, selectedRow]
  );

  return (
    <Box>
      <Box
        mt={2}
        display="flex"
        alignItems="center"
        justifyContent="space-between"
      >
        <Typography variant="h4" sx={{ color: "grey.600" }} fontWeight={"bold"}>
          Compilations
        </Typography>
      </Box>
      <Box>
        <form onSubmit={handleFilterApply}>
          <Grid container spacing={2} alignItems="center" padding={2}>
            <Grid item xs={12} md={6} lg={4}>
              <UserAutoComplete
                value={filters.user}
                user={filters.user}
                onChange={(user) => handleFilterChange("user", user)}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <DateInput
                label="Date From"
                value={filters.date_from}
                onChange={(date) => handleFilterChange('date_from', date)}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <DateInput
                label="Date To"
                value={filters.date_to}
                onChange={(date) => handleFilterChange('date_to', date)}
              />
            </Grid>
          </Grid>
          <Box
            display="flex"
            justifyContent="right"
            padding="0 30px"
          >
            <Button onClick={handleFilterClear} sx={{ marginRight: "10px" }}>Clear</Button>
            <Button variant="contained" type="submit" color="success">Apply Filters</Button>
          </Box>
        </form>
      </Box>
      <Box mt={4} component={Paper} borderRadius={4}>
        <Box
          border="1px solid"
          borderColor="grey.400"
          borderRadius={"10px 10px 0px 0px"}
          p={1}
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography color="grey" fontWeight={600}>
            All Compilations
          </Typography>
          <Tooltip title="Add New Channel">
            <IconButton
              size="small"
              onClick={() => {
                setCompilationBox(true);
              }}
            >
              <AddCircleIcon color="success" />
            </IconButton>
          </Tooltip>
        </Box>
        <Scrollbars style={{ height: 500, width: "100%" }}>
          <DataGrid
            rows={data}
            columns={columns}
            loading={loading}
            paginationMode="server"
            rowCount={rowCountState}
            paginationModel={paginationModel}
            onPaginationModelChange={setPaginationModel}
            rowSelection={false}
            onRowClick={(row) => {
              navigation(`/promo/videos/${row.id}`);
            }}
            disableColumnSelector
            disableDensitySelector
            disableSelectionOnClick
          />
        </Scrollbars>
      </Box>
      {/* Add new compilation */}
      <CompilationDialog />
      {/* Confirm Box  */}
      <DeleteCompilationDialog />
    </Box>
  );
}

export default PromoCompilations;
