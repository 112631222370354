import * as React from 'react';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, ButtonBase, IconButton, Tooltip, Typography } from '@mui/material';
import PromoVideoBox from '../../Promo/components/PromoVideoBox';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 900,
    bgcolor: 'background.paper',
    borderRadius: 1,
    boxShadow: 24,
    p: 4,
};

export default function VideoPreviewDialogBox({ open, setOpen, video }) {
    const handleClose = () => setOpen(false);
    const [compilationBox, setCompilationBox] = React.useState(null);

    const AddVideoAssertBox = React.useCallback(() => {
        return <PromoVideoBox open={compilationBox} onClose={setCompilationBox} is_update={false} refreshFunc={() => { }} selectedRow={{
            "video_key": video.unique_key,
            "video_title": video.title,

        }} />
    }, [compilationBox])

    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={open}
            onClose={handleClose}
            closeAfterTransition
            slots={{ backdrop: Backdrop }}
            slotProps={{
                backdrop: {
                    timeout: 500,
                },
            }}
        >
            <Fade in={open}>
                <Box sx={style}>
                    {/* Video */}
                    <div style={{ marginBottom: '16px', position: 'relative' }}>
                        <Box >
                            <Box mb={1} display="flex" justifyContent="space-between" alignItems="center">
                                <Tooltip title="Add compilation asserts">
                                    <Box component={ButtonBase} onClick={() => setCompilationBox(true)} display="flex" alignItems="center">
                                        <AddCircleOutlineIcon />
                                        <Typography variant='body2' sx={{ textDecoration: "underline" }}>{video.unique_key}</Typography>
                                    </Box>
                                </Tooltip>
                                <Typography variant='body2' sx={{ color: "primary.light" }}>{video.location}</Typography>
                            </Box>
                            <video controls width="100%" style={{ borderRadius: "22px", maxHeight: 400 }}>
                                <source src={video.url} type="video/mp4" />
                            </video>
                        </Box>
                    </div>
                    {/* Description */}
                    <Box mb={2}>
                        <Accordion>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                            >
                                {video.title}
                            </AccordionSummary>
                            <AccordionDetails>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
                                malesuada lacus ex, sit amet blandit leo lobortis eget.
                            </AccordionDetails>
                        </Accordion>
                    </Box>
                    <AddVideoAssertBox />
                </Box >
            </Fade >
        </Modal >
    );
}
