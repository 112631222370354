import React, { useCallback, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import Slide from "@mui/material/Slide";
import PasswordIcon from '@mui/icons-material/Password';
import { Box, Grid } from "@mui/material";
import PasswordField from "../../../Components/Fields/PasswordField.js";
import ConfirmPassReset from "./ConfirmPassReset.js";
import { toast } from "react-toastify";
import { passwordValidation } from "../../../utils.js";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function PasswordResetBox({
  open,
  setOpen,
  selectedRow
}) {
  const [pass, setPass] = useState({
    password: "",
    confirm_password: ""
  });
  const [confirmDialog, setConfirmDialog] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };
  const handleChange = (event) => {
    const { name, value } = event.target;
    setPass((pre) => ({
      ...pre,
      [name]: value,
    }));
  };
  const handleReset = () => {
    if (passwordValidation(pass.password)) {
      if (pass.password !== pass.confirm_password && pass.password) {
        toast.error("Repeat password not matches.");
      }
      else {
        setConfirmDialog(true);
      }
    }
  };

  const ConfirmPassResetDialog = useCallback(
    () => {
      return (
        <ConfirmPassReset
          open={confirmDialog}
          setOpen={setConfirmDialog}
          setPassDialog={setOpen}
          username={selectedRow.username}
          data={pass}
        />
      )
    },
    // eslint-disable-next-line
    [confirmDialog]
  );

  return (
    <Box>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        maxWidth="md"
        onClose={handleClose}
      >
        <DialogTitle>
          <Box display="flex" gap={2}>
            <PasswordIcon fontSize="large" color="warning" />
            {`Update (${selectedRow.username})'s password`}
          </Box>
        </DialogTitle>
        <DialogContent>
          <DialogContentText mb={2}>
            Please enter the new password to update.
          </DialogContentText>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12}>
              <PasswordField
                label="Password"
                name="password"
                fullWidth
                required
                value={pass.password}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12}>
              <PasswordField
                label="Confirm Password"
                name="confirm_password"
                fullWidth
                required
                value={pass.confirm_password}
                onChange={handleChange}
              />
            </Grid>
          </Grid>
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button variant="contained" onClick={handleReset} color="warning">
              Reset
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
      <ConfirmPassResetDialog />
    </Box>
  );
}
