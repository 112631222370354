import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import thunk from "redux-thunk";
import { Provider } from "react-redux";
import reducer from "./store/reducers/reducer.js";
import { legacy_createStore, applyMiddleware } from "redux";
import { composeWithDevTools } from "@redux-devtools/extension";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { setupInterceptors } from './conf/api.js';

const store = legacy_createStore(
  reducer,
  composeWithDevTools(applyMiddleware(thunk))
);

setupInterceptors(store.dispatch);

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <Provider store={store}>
    <ToastContainer style={{ width: "auto" }} />
    <App />
  </Provider>
);
