import * as React from 'react';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';

export default function TimeDurationPicker({ sx, ...other }) {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} >
      <div>
        <TimePicker
          views={['hours', 'minutes', 'seconds']}
          format="HH:mm:ss"
          sx={{
            "& .MuiInputBase-root": {
              height: 40.5
            },
            "& .MuiFormLabel-root": {
              marginTop: "-5px"
            },
            ...sx
          }}
          {...other}
        />
      </div>
    </LocalizationProvider>
  );
}
