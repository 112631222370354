import {
  Collapse,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Tooltip,
} from "@mui/material";
import React from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import DashboardIcon from "../../static/icons/dashboard.svg";
import VideoUploadIcon from "../../static/icons/video_upload.svg";
import VideosIcon from "../../static/icons/videos.svg";
import WhitelistingIcon from "../../static/icons/whitelisting.svg";
import AssetsIcon from "../../static/icons/assets.svg";
import RevenueUploadIcon from "../../static/icons/revenue_upload.svg";
import EarningsIcon from "../../static/icons/earnings.svg";
import UserManagementIcon from "../../static/icons/user_management.svg";
import YoutubeManagementIcon from "../../static/icons/youtube_management.svg";
import PromoChannelsIcon from "../../static/icons/promo_channels.svg";
import PromoCompilationsIcon from "../../static/icons/promo_compilations.svg";
import PromoVideosIcon from "../../static/icons/promo_videos.svg";
import PromoMenu from "../../static/icons/promo_menu.svg";
import SubscriptionsIcon from "@mui/icons-material/Subscriptions";
import LocalAtmIcon from '@mui/icons-material/LocalAtm';
import ExpandMore from "@mui/icons-material/ExpandMore";
import ExpandLess from "@mui/icons-material/ExpandLess";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { useState } from "react";
import YouTubeIcon from '@mui/icons-material/YouTube';
import ConnectedTvIcon from '@mui/icons-material/ConnectedTv';
import SettingsIcon from '@mui/icons-material/Settings';

function MenuItems({ open, setOpen, user, ...props }) {
  const redirect = useNavigate();
  const navigator = (url) => {
    redirect(url);
  };
  const [menuDropDowns, setMenuDropDowns] = useState({
    revenue_upload: false,
    earnings: false
  });

  var menuItems = [];
  if (user.is_promouser) {
    menuItems = [
      {
        title: "Dashboard",
        label: "dashboard",
        icon: DashboardIcon,
        url: "/promo/dashboard",
      },
      { title: "Video Library", label: "Library", icon: <SubscriptionsIcon fontSize="small" color="success" />, url: "/video/library" },
      {
        title: "Channels",
        label: "promo_channels",
        icon: PromoChannelsIcon,
        url: "/promo/channels",
      },
      {
        title: "Compilations",
        label: "promo_compilations",
        icon: PromoCompilationsIcon,
        url: "/promo/compilations",
      },
      {
        title: "Compilations Assets",
        label: "promo_compilation_videos",
        icon: PromoVideosIcon,
        url: "/promo/videos",
      },
    ]
  }
  else if (user.is_staff) {
    menuItems = [
      { title: "Dashboard", label: "dashboard", icon: DashboardIcon, url: "/" },
      { title: "Upload Video", label: "upload_video", icon: VideoUploadIcon, url: "/uploads" },
      { title: "Videos", label: "videos", icon: VideosIcon, url: "/videos" },
      { title: "Video Library", label: "Library", icon: <SubscriptionsIcon fontSize="small" color="success" />, url: "/video/library" },
      { title: "WhiteListing", label: "whitelisting", icon: WhitelistingIcon, url: "/whitelisting" },
      { title: "Assets", label: "assets", icon: AssetsIcon, url: "/assets" },
      {
        title: "Revenue Upload",
        label: "revenue_upload",
        icon: RevenueUploadIcon,
        subItems: [
          { title: "Creator Revenue", icon: <CloudUploadIcon color="primary" fontSize="small" />, url: "/revenue" },
          { title: "Promo Revenue", icon: <CloudUploadIcon color="primary" fontSize="small" />, url: "/promo_revenue" }
        ]
      },
      {
        title: "Earnings",
        label: "earnings",
        icon: EarningsIcon,
        subItems: [
          {
            title: "Creator Earnings", icon: <LocalAtmIcon color="primary" fontSize="small" />, url: "/earning/assets"
          },
          { title: "Promo Earnings", icon: <LocalAtmIcon color="primary" fontSize="small" />, url: "/promo_earning/assets" }
        ]
      },
      {
        title: "User Management",
        label: "user_management",
        icon: UserManagementIcon,
        url: "staff",
      },
      {
        title: "Youtube",
        label: "Youtube Managment",
        icon: <YouTubeIcon color="error" />,
        subItems: [
          {
            title: "Content Publishing",
            label: "Youtube Content Publishing",
            icon: YoutubeManagementIcon,
            url: "/youtube",
          },
          {
            title: "Videos",
            label: "Youtube Videos",
            icon: <SubscriptionsIcon color="error" fontSize="small" />,
            url: "/youtube/list?screen=completed&orderBy=-created_date",
          },
          {
            title: "Channels",
            label: "youtube_videos",
            icon: <ConnectedTvIcon color="error" fontSize="small" />,
            url: "/youtube/channels",
          },
          {
            title: "Configrations",
            label: "Youtube Configrations",
            icon: <SettingsIcon color="error" fontSize="small" />,
            url: "/mediaScope",
          },
        ]
      },
      {
        title: "Promo",
        label: "promo",
        icon: PromoMenu,
        subItems: [
          {
            title: "Channels",
            icon: PromoChannelsIcon,
            url: "/promo/channels",
          },
          {
            title: "Compilations",
            icon: PromoCompilationsIcon,
            url: "/promo/compilations",
          }
        ]
      }
    ]
  }
  else if (user.creator) {
    menuItems = [
      { title: "Dashboard", label: "dashboard", icon: DashboardIcon, url: "/" },
      { title: "Upload Video", label: "upload_video", icon: VideoUploadIcon, url: "/uploads" },
      { title: "Videos", label: "videos", icon: VideosIcon, url: "/videos" },
      { title: "WhiteListing", label: "whitelisting", icon: WhitelistingIcon, url: "/whitelisting" },
      { title: "Assets", label: "assets", icon: AssetsIcon, url: "/assets" },


    ]
  }

  return (
    <List>
      {menuItems.map((item, index) => (
        <ListItem key={index} disablePadding sx={{ display: "block" }}>
          <Tooltip title={item.title} placement="right-end">
            <ListItemButton
              onClick={() => {
                if (item.subItems) {
                  setMenuDropDowns((pre) => ({ ...pre, [item.label]: !pre[item.label] }));
                } else {
                  navigator(item.url);
                }
              }}
              sx={{
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
                px: 2.5,
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : "auto",
                  justifyContent: "center",
                }}
              >
                {typeof item.icon === "string" ? (
                  <img width={22} src={item.icon} alt={item.title} />
                ) : (
                  item.icon
                )}
              </ListItemIcon>
              {open && <ListItemText sx={{
                "& .MuiTypography-root": {
                  fontSize: 13,
                  fontWeight: 500
                }
              }} primary={item.title} />}
              {open &&
                item.subItems &&
                (menuDropDowns[item.label] ? <ExpandLess /> : <ExpandMore />)}
            </ListItemButton>
          </Tooltip>
          {item.subItems && (
            <Collapse
              in={menuDropDowns[item.label]}
              timeout="auto"
              unmountOnExit
            >
              <List component="div">
                {item.subItems.map((subItem, subIndex) => (
                  <ListItem key={subIndex} sx={{
                    display: "block", "& .MuiTypography-root": {
                      fontSize: 13,
                      fontWeight: 500
                    }
                  }}>
                    <Tooltip title={subItem.title} placement="right-end">
                      <ListItemButton
                        onClick={() => {
                          subItem.onClick && subItem.onClick();
                          navigator(subItem.url);
                        }}
                        sx={{
                          minHeight: 48,
                          justifyContent: menuDropDowns[item.label] ? "initial" : "center",
                          px: open ? 2.5 : 1.5,
                        }}
                      >
                        <ListItemIcon
                          sx={{
                            minWidth: 0,
                            mr: menuDropDowns[item.label] ? 3 : "auto",
                            justifyContent: "center",
                          }}
                        >
                          {typeof subItem.icon === "string" ? (
                            <img width={22} src={subItem.icon} alt={subItem.title} />
                          ) : (
                            subItem.icon
                          )}
                        </ListItemIcon>
                        <ListItemText
                          primary={subItem.title}
                          sx={{ opacity: menuDropDowns[item.label] ? 1 : 0 }}
                        />
                      </ListItemButton>
                    </Tooltip>
                  </ListItem>
                ))}
              </List>
            </Collapse>
          )}
        </ListItem>
      ))}
    </List>
  );
}
const mapStatesToProps = (state) => {
  return {
    user: state.user,
    is_promouser: state.user.is_promouser || false,
    is_admin: state.user?.is_admin
  };
};
export default connect(mapStatesToProps, null)(MenuItems);
