import axios from "axios";
import Cookies from "js-cookie";
import { getRefreshToken, authLogout } from "../store/actions/actions";
import { sleep } from "../utils";

export const baseURL = process.env.REACT_APP_BASE_URL;
const api = axios.create({
  baseURL: baseURL,
  timeout: 5000,
});

let isRefreshing = false;
let refreshPromise = null;

export const setupInterceptors = (dispatch) => {
  api.interceptors.request.use((config) => {
    const accessToken = Cookies.get("access_token");
    if (accessToken) {
      config.headers.Authorization = `Bearer ${accessToken}`;
    }
    return config;
  });

  api.interceptors.response.use(
    (response) => {
      return response;
    },
    async (error) => {
      const originalRequest = error.config;
      if (
        error.response &&
        error.response.status === 401 &&
        error.response.data?.code === "token_not_valid"
      ) {
        const refreshToken = Cookies.get("refresh_token");
        if (!isRefreshing) {
          isRefreshing = true;
          try {
            const response = await getRefreshToken(refreshToken);
            if (response) {
              Cookies.set("access_token", response.access, {
                secure: true,
              });
              Cookies.set("refresh_token", response.refresh, {
                secure: true,
              });
              originalRequest.headers.Authorization = `Bearer ${response.access}`;
              return api(originalRequest);
            } else {
              dispatch(authLogout());
            }
          } catch (refreshError) {
            console.error("Error refreshing access token:", refreshError);
          } finally {
            isRefreshing = false;
          }
        } else {
          if (!refreshPromise) {
            refreshPromise = new Promise((resolve, reject) => {
              const wait = async () => {
                await sleep(2000);
                try {
                  const response = await api(originalRequest);
                  resolve(response);
                } catch (retryError) {
                  reject(retryError);
                } finally {
                  refreshPromise = null;
                }
              };
              wait();
            });
          }
          return refreshPromise;
        }
      }
      return Promise.reject(error);
    }
  );
};

export default api;
