import * as React from "react";
import Avatar from "@mui/material/Avatar";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import PersonIcon from "@mui/icons-material/Person";
import { blue } from "@mui/material/colors";
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  Typography,
} from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import Scrollbars from "react-custom-scrollbars-2";
import api from "../../../conf/api";
import { toast } from "react-toastify";

export default function ApprovalDialogBox({
  open,
  setOpen,
  rows = [],
  refreshFunc,
  is_promo = false
}) {
  const total_payable = rows
    .reduce((sum, r) => sum + r.partner_revenue, 0)
    .toFixed(2);
  const handleClose = () => {
    setOpen(false);
  };

  const handleListItemClick = () => {
    setOpen(false);
  };
  const ApproveEarning = async () => {
    const data = rows.map((r) => r.id);
    const url = is_promo ? "/earning/promo/approval/" : "/earning/approval/";
    await api
      .post(url, data)
      .then((res) => {
        if (res.status > 199) {
          toast.success(res.data);
          refreshFunc();
          setOpen(false);
        }
      })
      .catch((err) => {
        toast.error("something wents wrong");
        console.log(err);
      });
  };

  return (
    <Dialog onClose={handleClose} open={open} maxWidth="sm" fullWidth>
      <DialogTitle>You are going to approve the following earning</DialogTitle>
      <DialogContent>
        <Box>
          <Typography variant="h6">
            Total: <strong>{total_payable}</strong>
          </Typography>
        </Box>
        <Scrollbars style={{ minHeight: 400, width: "100%" }}>
          <List sx={{ pt: 0 }}>
            {rows.map((r) => (
              <ListItem disableGutters key={r.id}>
                <ListItemButton onClick={handleListItemClick}>
                  <ListItemAvatar>
                    <Avatar sx={{ bgcolor: blue[100], color: blue[600] }}>
                      <PersonIcon />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={r.username}
                    secondary={<Typography>$ {r.partner_revenue}</Typography>}
                  />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        </Scrollbars>
      </DialogContent>
      <DialogActions>
        <Button
          sx={{ width: 200 }}
          onClick={handleClose}
          variant="outlined"
          color="primary"
        >
          Cancel
        </Button>
        <Button
          sx={{ width: 200 }}
          onClick={ApproveEarning}
          startIcon={<CheckIcon />}
          variant="contained"
          color="primary"
        >
          Confirm Approve
        </Button>
      </DialogActions>
    </Dialog>
  );
}
