import { Autocomplete, CircularProgress, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import api from '../../conf/api';

function UserAutoComplete({ onChange, fetchOptions = undefined, params = {}, user }) {
  const [options, setOptions] = useState({ loading: false, data: [] });
  const [selectedUser, selectUser] = useState(null);

  const fetchUsers = async () => {
    if (fetchOptions !== undefined) {
      fetchOptions(setOptions)
    }
    else {
      try {
        setOptions((pre) => ({ ...pre, loading: true }));
        const res = await api.get("/users/list", { params: params });
        setOptions((pre) => ({ ...pre, data: res.data?.results }));

        if (user) {
          const _user = res.data.results.find(usr => usr.id === user);
          if (_user) {
            selectUser(_user);
          }
        }
      } catch (err) {
        console.error(err);
      } finally {
        setOptions((pre) => ({ ...pre, loading: false }));
      }
    }
  };

  useEffect(() => {
    if (user !== "") {
      fetchUsers();
    }
    else {
      selectUser(null)
    }
    // eslint-disable-next-line
  }, [user]);

  useEffect(() => {
    if (selectedUser !== null) {
      onChange(selectedUser?.id);
    }
    // eslint-disable-next-line
  }, [selectedUser]);

  return (
    <Autocomplete
      onOpen={fetchUsers}
      sx={{ minWidth: 200 }}
      size="small"
      value={selectedUser}
      onChange={(e, _value) => {
        selectUser(_value);
      }}
      options={options.data}
      getOptionLabel={(option) => option.username}
      loading={options.loading}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Users"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {options.loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  )
}

export default UserAutoComplete;
