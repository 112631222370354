import React, { useCallback, useEffect, useState } from "react";
import {
  Box,
  IconButton,
  Paper,
  Tooltip,
  Typography,
  Grid,
  Button,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import api from "../../conf/api";
import Scrollbars from "react-custom-scrollbars-2";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import ChannelPopupBox from "./components/ChannelPopupBox.js";
import CreateIcon from "@mui/icons-material/Create";
import DeleteIcon from "@mui/icons-material/Delete";
import ConfirmDeleteChannelBox from "./components/ConfirmDeleteChannelBox.js";
import { connect } from "react-redux"
import DateInput from "../../Components/Fields/DatePicker.js";
import { isValidDateRange } from "../../utils.js";
import { toast } from "react-toastify";
import UserAutoComplete from "../../Components/AutoCompletes/UserAutoComplete.js"
import { useSearchParams } from "react-router-dom";

function PromoChannels(props) {
  const [searchParams, setSearchParams] = useSearchParams();
  const initialFilters = {
    date_from: searchParams.get("date_from") || null,
    date_to: searchParams.get("date_to") || null,
    user: searchParams.get("user") || null
  };
  const [filters, setFilters] = useState(initialFilters);
  const [params, setParams] = useState(null);
  const [data, setData] = useState([]);
  const [channelBox, setChannelBox] = useState(false);
  const [loading, setLoading] = useState(false);
  const [rowCountState, setRowCountState] = useState(0);
  const [selectedRow, setSelectedRow] = useState({});
  const [is_update, setIsUpdate] = useState(false);
  const [confirmBox, setConfirmBox] = useState(false);
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 3,
    page: 0,
  });

  const columns = [
    props.is_admin && {
      field: "user",
      headerName: "User",
    },
    {
      field: "channel_id",
      headerName: "Channel",
      minWidth: 250,
    },
    {
      field: "name",
      headerName: "Channel Name",
      minWidth: 180,
    },
    {
      field: "is_approved",
      headerName: "Status",
      type: "boolean",
      minWidth: 120,
    },
    { field: "url", headerName: "URL", sortable: true, flex: 1 },
    {
      field: "action",
      headerName: "Action",
      align: "center",
      headerAlign: "center",
      width: 200,
      renderCell: (params) => (
        <Box display="flex" gap={1}>
          {!params.row?.is_approved &&
            <Tooltip title="Edit User">
              <IconButton onClick={handleUpdateChannel(params.row?.name)}>
                <CreateIcon color="primary" />
              </IconButton>
            </Tooltip>
          }
          {!params.row?.is_approved &&
            <Tooltip title="Delete User">
              <IconButton onClick={handleDeleteChannel(params.row?.name)}>
                <DeleteIcon sx={{ color: "error.dark" }} />
              </IconButton>
            </Tooltip>}
        </Box >
      ),
    },
  ];

  const handleFilterChange = (name, value) => {
    if (value !== null) {
      setFilters((pre) => ({
        ...pre, [name]: value,
      }));
      const queryParams = new URLSearchParams(searchParams);
      queryParams.set(name, value);
      setSearchParams(queryParams);
    }
  }

  const handleFilterClear = () => {
    const queryParams = new URLSearchParams(searchParams);
    const _filters = { ...filters };
    for (let key in _filters) {
      _filters[key] = "";
      queryParams.delete(key);
    }
    setFilters(_filters);
    setParams((pre) => ({
      ...pre,
      ..._filters
    }));
    setSearchParams(queryParams);
  }

  const handleFilterApply = (event) => {
    event.preventDefault();
    if (!isValidDateRange(filters.date_from, filters.date_to)) {
      toast.error("Invalid Date Range.");
      return;
    }
    setParams((pre) => ({
      ...pre,
      page: paginationModel.page + 1,
      page_size: paginationModel.pageSize,
      ...filters
    }));
  }

  async function fetchData(
    page = paginationModel.page,
    pageSize = paginationModel.pageSize
  ) {
    setLoading(true);
    await api
      .get("/promo/channels", {
        params: {
          ...params,
          page: page + 1,
          page_size: pageSize
        },
      })
      .then((res) => {
        // Assign unique ids to each row
        const formattedData = res.data?.results.map((row, index) => ({
          ...row,
          id: index + 1,
        }));
        setData(formattedData);
        setRowCountState(res.data?.count);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  async function fetchDetail(_id) {
    await api
      .get(`/promo/channels/${_id}`)
      .then((res) => {
        if (res.status === 200) {
          setSelectedRow(res.data);
          setChannelBox(true);
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  useEffect(
    () => {
      fetchData();
    },
    // eslint-disable-next-line
    [paginationModel.page, paginationModel.pageSize, params]
  )

  const handleUpdateChannel = (_id) => async () => {
    setIsUpdate(true);
    fetchDetail(_id);
  };

  const handleDeleteChannel = (_id) => async () => {
    setSelectedRow(_id);
    setConfirmBox(true);
  };

  const ChannelDialog = useCallback(
    () => (
      <ChannelPopupBox
        open={channelBox}
        selectedRow={selectedRow}
        is_update={is_update}
        is_admin={props.is_admin}
        onClose={() => {
          setIsUpdate(false);
          setSelectedRow({});
          setLoading(false);
          setChannelBox(false);
        }}
        refreshFunc={fetchData}
      />
    ),
    // eslint-disable-next-line
    [channelBox, is_update, selectedRow]
  );

  const DeleteChannelDialog = useCallback(
    () => (
      <ConfirmDeleteChannelBox
        selectedRow={selectedRow}
        open={confirmBox}
        setOpen={setConfirmBox}
        refreshFunc={fetchData}
      />
    ),
    // eslint-disable-next-line
    [confirmBox, selectedRow]
  );

  return (
    <Box>
      <Box
        mt={2}
        display="flex"
        alignItems="center"
        justifyContent="space-between"
      >
        <Typography variant="h4" sx={{ color: "grey.600" }} fontWeight={"bold"}>
          Channels
        </Typography>
      </Box>
      <Box>
        <form onSubmit={handleFilterApply}>
          <Grid container spacing={2} alignItems="center" padding={2}>
            {props.is_admin &&
              <Grid item xs={12} md={6} lg={4}>
                <UserAutoComplete
                  value={filters.user}
                  user={filters.user}
                  onChange={(user) => handleFilterChange("user", user)}
                />
              </Grid>
            }
            <Grid item xs={12} md={6} lg={4}>
              <DateInput
                label="Date From"
                value={filters.date_from}
                onChange={(date) => handleFilterChange('date_from', date)}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <DateInput
                label="Date To"
                value={filters.date_to}
                onChange={(date) => handleFilterChange('date_to', date)}
              />
            </Grid>
          </Grid>
          <Box
            display="flex"
            justifyContent="right"
            padding="0 30px"
          >
            <Button onClick={handleFilterClear} sx={{ marginRight: "10px" }}>Clear</Button>
            <Button variant="contained" type="submit" color="success">Apply Filters</Button>
          </Box>
        </form>
      </Box>
      <Box mt={4} component={Paper} borderRadius={4}>
        <Box
          border="1px solid"
          borderColor="grey.400"
          borderRadius={"10px 10px 0px 0px"}
          p={1}
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography color="grey" fontWeight={600}>
            All Channels
          </Typography>
          <Tooltip title="Add New Channel">
            <IconButton
              size="small"
              onClick={() => {
                setChannelBox(true);
              }}
            >
              <AddCircleIcon color="success" />
            </IconButton>
          </Tooltip>
        </Box>
        <Scrollbars style={{ height: 500, width: "100%" }}>
          <DataGrid
            rows={data}
            columns={columns}
            loading={loading}
            paginationMode="server"
            rowCount={rowCountState}
            paginationModel={paginationModel}
            onPaginationModelChange={setPaginationModel}
            rowSelection={false}
            disableColumnSelector
            disableDensitySelector
            disableSelectionOnClick
          />
        </Scrollbars>
      </Box>
      {/* Add new channel */}
      <ChannelDialog />
      {/* Confirm Box  */}
      <DeleteChannelDialog />
    </Box>
  );
}

const mapStatesToProps = (state) => {
  return {
    is_admin: state.user?.is_staff
  };
};
export default connect(mapStatesToProps, null)(PromoChannels);