import React, { useEffect, useState } from 'react';
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Button,
    MenuItem,
    Select,
    InputLabel,
    FormControl,
    CircularProgress,
    TextField,
    Grid,
    Typography,
    Box,
    Autocomplete,
    Chip,
    Tooltip,
} from '@mui/material';
import api from '../../../../conf/api';
import { toast } from 'react-toastify';
import Scrollbars from 'react-custom-scrollbars-2';


const YoutubeUpdateVideoStatusDialog = ({ open, handleClose, row, reFreshFunc }) => {

    const [loading, setLoading] = useState(false);
    const initial_fields = {
        title: "",
        description: "",
        tags: "",
        publishedAt: "",
        privacy_status: "",
        thumbnails: null
    }
    const [videoData, setVideoData] = useState(initial_fields);

    const handleStatusChange = (event) => {
        setVideoData(pre => ({ ...pre, privacy_status: event.target.value }));
    };
    const handleTagsAdd = (_e, newTags) => {
        setVideoData(pre => ({ ...pre, tags: newTags }));
    }
    const handleFieldChange = (e) => {
        setVideoData(pre => ({ ...pre, [e.target.name]: e.target.value }));
    }

    const handleSubmit = async () => {
        setLoading(true);
        let payload = videoData;
        delete payload["publishedAt"]
        api.post("/youtube/update-video-status/", { video_id: row.youtube_video_id, ...payload })
            .then(res => {
                if (res.status === 200) {
                    toast.success(res.data?.message);
                    reFreshFunc();
                    handleClose();
                }
            })
            .catch(error => {
                if (error.response?.data?.error) {
                    toast.error(error.response?.data?.error);
                } else {
                    toast.error("Something went wrong");
                }
            })
            .finally(() => {
                setLoading(false);
            });
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true)
                const response = await api.get(`/youtube/fetch-video-data/${row.youtube_video_id}/`);
                if (response.status === 200) {

                    const data = response.data;
                    const snippet = data.snippet;
                    const privacyStatus = data.status?.privacyStatus;
                    setVideoData({ title: snippet.title, description: snippet.description, tags: snippet.tags, privacy_status: privacyStatus, publishedAt: snippet.publishedAt, thumbnails: snippet.thumbnails });
                } else {
                    toast.error("Failed to fetch video data");
                }
            } catch (error) {
                if (error.response?.data?.error) {
                    toast.info(error.response?.data?.error);
                } else {
                    toast.error("Something went wrong");
                }
            }
            finally {
                setLoading(false)
            }
        };

        if (open) {
            fetchData();
        }
    },
        // eslint-disable-next-line
        [open]
    );

    return (
        <Dialog maxWidth="md" fullWidth open={open} onClose={handleClose}>
            {!loading && videoData.title ? (
                <>
                    <DialogTitle>Update Video Details</DialogTitle>
                    <Scrollbars style={{ height: "90vh", width: "100%" }}>
                        <DialogContent>
                            <Grid container spacing={2} direction="column">

                                <Grid item>
                                    <Typography variant="h6">{videoData.title}</Typography>
                                </Grid>

                                <Grid item container spacing={0.5} alignItems="center" justifyContent="space-between" >
                                    <Grid item xs>
                                        <Typography variant="caption">{new Date(videoData.publishedAt).toUTCString()}</Typography>
                                    </Grid>
                                    <Grid item xs={5} md={3}>
                                        <FormControl size='small' fullWidth sx={{ height: 30 }}>
                                            <InputLabel id="status-label" sx={{ top: "-4px" }}>Privacy Status</InputLabel>
                                            <Select
                                                size='small'
                                                labelId="status-label"
                                                fullWidth
                                                value={videoData.privacy_status}
                                                onChange={handleStatusChange}
                                                sx={{ height: 30 }}
                                            >
                                                <MenuItem value="public">Public</MenuItem>
                                                <MenuItem value="private">Private</MenuItem>
                                                <MenuItem value="unlisted">Unlisted</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} >
                                        <Box
                                            component="img"
                                            borderRadius={1.25}
                                            height={videoData.thumbnails?.high.height}
                                            loading="lazy"
                                            src={videoData.thumbnails?.high.url}
                                            alt="Video Thumbnail"
                                        />
                                    </Grid>
                                </Grid>

                                <Grid item xs={12}>
                                    <TextField
                                        label="Title"
                                        size='small'
                                        name="title"
                                        value={videoData.title}
                                        onChange={handleFieldChange}
                                        variant="outlined"
                                        fullWidth

                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        label="Description"
                                        size='small'
                                        name="description"
                                        value={videoData.description}
                                        onChange={handleFieldChange}
                                        multiline
                                        rows={6}
                                        variant="outlined"
                                        fullWidth

                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Tooltip title="Press enter to add">
                                        <Autocomplete
                                            multiple
                                            options={[]}
                                            freeSolo
                                            size='small'
                                            value={videoData.tags}
                                            onChange={handleTagsAdd}
                                            renderTags={(value, getTagProps) =>
                                                value.map((option, index) => {
                                                    const { key, ...tagProps } = getTagProps({ index });
                                                    return (
                                                        <Chip variant="outlined" label={option} size='small' key={key} {...tagProps} />
                                                    );
                                                })
                                            }
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    variant="filled"
                                                    label="Tags"
                                                    placeholder="Enter tags"
                                                />
                                            )}
                                        />
                                    </Tooltip>
                                </Grid>


                            </Grid>
                        </DialogContent>
                    </Scrollbars>
                    <DialogActions>
                        <Button onClick={handleClose}>Cancel</Button>
                        <Button disabled={loading} startIcon={loading && <CircularProgress size={20} />} onClick={handleSubmit} color="primary">Update</Button>
                    </DialogActions>
                </>
            ) : (
                <DialogContent sx={{ textAlign: "center" }}>
                    <CircularProgress />
                </DialogContent>
            )}
        </Dialog>
    );
};

export default YoutubeUpdateVideoStatusDialog;
