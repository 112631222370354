import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Grid } from "@mui/material";
import { toast } from "react-toastify";
import TextInput from "../../../../Components/Fields/TextInput.js"
import api from "../../../../conf/api";
import PasswordField from "../../../../Components/Fields/PasswordField.js";
import { isValidIPv4, ValidUrl } from "../../../../utils.js";

export default function ProxyBox({
  open,
  is_update,
  selectedRow,
  refreshFunc,
  onClose
}) {
  let initialFormData = {
    ip_address: "",
    port: "",
    username: "",
    password: ""
  };

  const [formData, setFormData] = React.useState(initialFormData);

  const handleChange = (event) => {
    const { name, value } = event.target;
    const _name = name.replace(/^proxy_/, '');
    console.log(name !== _name, event.nativeEvent.data, name, _name)
    if (name !== _name) {
      if (event.nativeEvent.data !== undefined) {
        setFormData({
          ...formData,
          [_name]: value,
        });
      }
    }
    else {
      setFormData({
        ...formData,
        [_name]: value,
      });
    }
  };

  const handleClose = () => {
    onClose();
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const ip = formData.ip_address;
    if (!ValidUrl(ip) && !isValidIPv4(ip)) {
      toast.warning("Enter a valid URL or IP!");
      return;
    }
    if (is_update) {
      const updateProxy = async () => {
        await api
          .patch(`/proxies/${selectedRow}/`, formData)
          .then((res) => {
            if (res.status === 200) {
              toast.success("Proxy updated successfully.");
              refreshFunc();
              setFormData(formData);
              onClose();
            }
          })
          .catch((error) => {
            const errorObject = error.response?.data;
            try {
              for (const field in errorObject) {
                if (errorObject?.hasOwnProperty(field)) {
                  const errors = errorObject[field];
                  const formattedFieldErrors = errors.map(
                    (errorMessage) => `${field}:${errorMessage}`
                  );
                  formattedFieldErrors?.map((msg) => toast.error(msg));
                }
              }
            } catch {
              try {
                toast.error(JSON.stringify(errorObject));
              } catch {
                toast.error("Something went wrong");
              }
            }
          });
      };
      updateProxy();
    }
    else {
      formData.updated_date = new Date();
      const data = formData;
      await api
        .post("/proxies/", data)
        .then((response) => {
          if (response.status === 201) {
            toast.success(response.data?.message);
            refreshFunc();
            onClose();
          }
        })
        .catch((error) => {
          const errorObject = error.response?.data;
          try {
            for (const field in errorObject) {
              if (errorObject?.hasOwnProperty(field)) {
                const errors = errorObject[field];
                const formattedFieldErrors = errors.map(
                  (errorMessage) => `${field}:${errorMessage}`
                );
                formattedFieldErrors?.map((msg) => toast.error(msg));
              }
            }
          } catch {
            try {
              toast.error(JSON.stringify(errorObject));
            } catch {
              toast.error("Something went wrong");
            }
          }
        });
    }
  };

  React.useEffect(() => {
    async function fetchData() {
      await api
        .get("/proxies/" + selectedRow)
        .then((res) => {
          const _data = res.data;
          setFormData(_data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
    if (is_update) {
      fetchData();
    }
    // eslint-disable-next-line
  }, []);

  return (
    <Dialog maxWidth="md" open={open} onClose={handleClose}>
      <DialogTitle>{is_update ? "Update" : "Create"} Proxy</DialogTitle>
      <DialogContent>
        <DialogContentText mb={2}>
          Please fill in the required information to{" "}
          {is_update ? "update" : "create"} a new proxy.
        </DialogContentText>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2} alignItems="center" mb={2}>
            <Grid item xs={12} md={6}>
              <TextInput
                label="IP Address"
                id="ip_address"
                type="text"
                fullWidth
                required
                name="ip_address"
                value={formData.ip_address}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextInput
                label="Port No."
                id="port"
                type="text"
                fullWidth
                required
                name="port"
                value={formData.port}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextInput
                label="Username"
                id="proxy_username"
                name="proxy_username"
                type="text"
                fullWidth
                value={formData.username}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={6}>
              <PasswordField
                label="Password"
                id="proxy_password"
                name="proxy_password"
                fullWidth
                value={formData.password}
                onChange={handleChange}
              />
            </Grid>
          </Grid>
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button variant="contained" type="submit" color="primary">
              {is_update ? "Update" : "Create"} Proxy
            </Button>
          </DialogActions>
        </form>
      </DialogContent>
    </Dialog>
  );
}
