import React, { useCallback, useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {
  Button,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Paper,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { Scrollbars } from "react-custom-scrollbars-2";
import FilePresentIcon from "@mui/icons-material/FilePresent";
import GenericTable from "../../Components/Table/GenericTable";
import readXlsxFile from "read-excel-file";
import DubblicationDialogBox from "./components/DubblicationDialogBox";

const RevenueUpload = () => {
  const [files, setFiles] = useState([]);
  const [data, setData] = useState([]);
  const [dublicationDialog, openDubblication] = useState(false);

  const onDrop = async (acceptedFiles) => {
    const file = acceptedFiles[0];
    const isXLSX = file.name.toLowerCase().endsWith(".xlsx");
    if (isXLSX) {
      await readXlsxFile(file).then((res) => {
        const columns = res[0];
        const result = res
          .slice(1)
          .map((row) =>
            Object.fromEntries(
              columns.map((column, index) => [column, row[index]])
            )
          );

        setFiles(acceptedFiles);
        setData(result);
      });
    }
  };

  function DublicatedRowsValidate(rows) {
    const _columns = [
      "Asset ID",
      "Asset Label",
      "Asset Title",
      "Partner Revenue",
    ];
    for (const row of rows) {
      const entries_count = rows.filter(
        (r) =>
          r[_columns[0]] === row[_columns[0]] &&
          r[_columns[1]] === row[_columns[1]] &&
          r[_columns[2]] === row[_columns[2]] &&
          r[_columns[3]] === row[_columns[3]]
      );
      if (entries_count.length > 1) {
        row.dublicated = true;
      }
    }
    setData(rows);
  }
  useEffect(() => {
    window.scrollTo({
      top: document.documentElement.scrollHeight,
      behavior: "smooth",
    });
    if (data.length > 1) {
      DublicatedRowsValidate(data);
    }
    // eslint-disable-next-line
  }, [data]);

  const removeItem = (index, event) => {
    event.stopPropagation();
    const updatedfiles = [...files];
    const removedVideo = updatedfiles[index];

    // Stop the ongoing progress simulation (if any)
    if (removedVideo.loading) {
      // Clear the progress simulation timeout
      removedVideo.loading = false;
      clearTimeout(removedVideo.progressTimeout);
    }

    updatedfiles.splice(index, 1);
    setFiles(updatedfiles);
  };

  const handleRemoveRow = (row) => {
    const newArray = data.slice();
    const indexToRemove = newArray.findIndex(
      (r) => row["Asset ID"] === r["Asset ID"]
    );
    if (indexToRemove !== -1) {
      newArray.splice(indexToRemove, 1);
      setData(newArray);
    }
  };

  const DisplayTable = useCallback(
    () => (
      <GenericTable
        data={data}
        columns={[
          {
            id: "Asset Label",
            label: "Asset Label",
            sortable: false,
            sx: { flexGrow: 1 },
          },
          {
            id: "Asset ID",
            label: "Asset ID",
            sortable: false,
          },
          {
            id: "Asset Title",
            label: "Asset Title",
            sortable: false,
            sx: { flexGrow: 1 },
          },

          {
            id: "Partner Revenue",
            label: "Partner Revenue Label",
            sortable: false,
          },
        ]}
        pagination={false}
        defaultOrderBy="Asset Title"
        defaultOrder="asc"
        defaultRowsPerPage={10}
        fetchData={() => {}}
        loading={false}
        onRowRemove={handleRemoveRow}
      />
    ),
    // eslint-disable-next-line
    [data]
  );
  const DublicationDialog = useCallback(() => {
    return (
      <DubblicationDialogBox
        open={dublicationDialog}
        setOpen={openDubblication}
        clearData={setData}
        setFiles={setFiles}
        rows={data.map((r) => {
          const { dublicated, ...rowWithoutDuplicated } = r;
          return rowWithoutDuplicated;
        })}
      />
    );
    // eslint-disable-next-line
  }, [dublicationDialog]);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: ".xlsx",
    multiple: false,
  });

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      gap={0.5}
    >
      {/* Title */}
      <Typography sx={{ width: "100%" }} variant="h4">
        Add Creator Revenues
      </Typography>

      <Typography variant="h5">Upload Sheet (.csv) to add revenue</Typography>
      {/* Upload Box */}
      <Box
        {...getRootProps()}
        sx={{
          width: { xs: "90%", md: "80%", xl: "50%" },
          border: "2px dashed",
          borderColor: "primary.dark",
          borderRadius: 4,
          padding: 2.5,
          // minHeight: 300,
          textAlign: "center",
          cursor: "pointer",
          "&:hover": {
            backgroundColor: "grey.100",
          },
        }}
      >
        <input {...getInputProps()} />
        <Typography variant="body1" fontWeight="bold" mt={2}>
          Drag sheet files here, or{" "}
          <Typography component="span" color="primary" fontWeight="bold">
            click
          </Typography>{" "}
          to select files
        </Typography>

        <Box sx={{ mt: 2 }}>
          {files.length > 0 && (
            <Typography variant="h6">Selected files:</Typography>
          )}
          <Box>
            {files.length <= 0 && (
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
              >
                <CloudUploadIcon
                  sx={{
                    fontSize: { md: 150, xs: 100 },
                    color: "grey",
                    mt: 2,
                  }}
                />
                <Typography variant="h5">Upload Revenue</Typography>
              </Box>
            )}
          </Box>

          <List
            disablePadding
            sx={{
              zIndex: 2,
              width: "100%",
              display: files.length > 0 ? "flex" : "none",
            }}
          >
            <Scrollbars style={{ height: 250 }}>
              {files.map((file, index) => (
                <div key={index}>
                  <ListItem
                    secondaryAction={
                      <IconButton
                        aria-label="comment"
                        disabled={file.loading}
                        onClick={(event) => {
                          removeItem(index, event);
                          setData([]);
                        }}
                      >
                        <CloseIcon />
                      </IconButton>
                    }
                  >
                    <ListItemAvatar>
                      <FilePresentIcon fontSize="large" color="success" />
                    </ListItemAvatar>

                    <ListItemText primary={file.name} />
                  </ListItem>
                </div>
              ))}
            </Scrollbars>
          </List>
        </Box>
      </Box>
      <Box
        width="100%"
        display="flex"
        alignItems="center"
        justifyContent="space-between"
      >
        <Typography textAlign="left" variant="h5" paragraph>
          Revenues
        </Typography>
        <Button
          onClick={() => {
            setData([]);
          }}
          sx={{ minWidth: "120px", height: 28 }}
          size="small"
          color="error"
          variant="contained"
        >
          Reset
        </Button>
      </Box>
      <Paper sx={{ borderRadius: 4, width: "100%" }}>
        <DisplayTable />
      </Paper>
      <Button
        sx={{ mt: 1 }}
        startIcon={<CloudUploadIcon />}
        fullWidth
        disabled={data.length < 1}
        onClick={() => openDubblication(true)}
        // onClick={uploadRevenue}
        variant="contained"
        color="primary"
      >
        Upload Data
      </Button>
      {/* Dubblication Dialog */}
      <DublicationDialog />
    </Box>
  );
};

export default RevenueUpload;
