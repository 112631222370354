import * as React from "react";
import { useState, useCallback } from "react"
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Autocomplete, CircularProgress, Grid, TextField, Tooltip } from "@mui/material";
import { toast } from "react-toastify";
import api from "../../../conf/api";
import TextInput from "../../../Components/Fields/TextInput.js";
import TimeDurationPicker from "../../../Components/Fields/TimeDurationPicker.js";
import dayjs from "dayjs";

const debounce = (func, delay) => {
  let timeoutId;
  return function (...args) {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => {
      func.apply(this, args);
    }, delay);
  };
}
function SearchFromCloud({ onChange, selectedRow }) {
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [query, setQuery] = useState(null);
  const [inputValue, setInputValue] = useState('');

  const fetchData = async (_inputValue, setFirst = true) => {
    setLoading(true);
    try {
      const response = await api(`/videos/colud/search/`, { params: { query: _inputValue } });
      const data = await response.data;
      setOptions(data.results);
      if (setFirst && data.results?.length > 0) {
        setQuery(data.results[0])
        const encodedPath = encodeURIComponent(data.results[0]?.path);
        const dropboxLink = `https://www.dropbox.com/home${encodedPath}`;
        onChange(data.results[0]?.name, dropboxLink);
      }
      else if (setFirst) {
        toast.warning("Sorry no dropbox link availble.")
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  };

  const debouncedFetchData = useCallback(
		debounce(
			(inputValue) => fetchData(inputValue),
			500
		),
		// eslint-disable-next-line
		[]
	);

  const handleInputChange = (event, newInputValue) => {
    setInputValue(newInputValue);
    debouncedFetchData(newInputValue);
  };
  React.useEffect(
    () => {
      if (selectedRow?.video_key && !selectedRow.dropbox_link) {
        fetchData(selectedRow.video_key, true)
      }
    },
    // eslint-disable-next-line
    [selectedRow?.video_key]
  )

  return (
    <Tooltip title="Press enter to search">
      <Autocomplete
        size="small"
        open={open}
        onOpen={() => {
          setOpen(true);
        }}
        onClose={() => {
          setOpen(false);
        }}
        // isOptionEqualToValue={(option, value) => option.name === value.name}
        options={options}
        loading={loading}
        getOptionLabel={(option) => option.name}
        value={query}
        onChange={(event, value) => {
          setQuery(value);
          const encodedPath = encodeURIComponent(value?.path);
          const dropboxLink = `https://www.dropbox.com/home${encodedPath}`;
          onChange(value?.name, dropboxLink);
        }}
        inputValue={inputValue}
        onInputChange={handleInputChange} // Pass handleInputChange here
        renderInput={(params) => (
          <TextField
            {...params}
            label="Search video by title"
            variant="outlined"
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {loading ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
          />
        )}
      />
    </Tooltip>
  );
}

export default function PromoVideoBox({
  open,
  is_update,
  selectedRow,
  refreshFunc,
  onClose,
}) {
  const convertStringToDuration = React.useCallback((_for = "from") => {
    if (selectedRow.duration) {
      const [from, to] = selectedRow.duration.split("-");
      const [min, sec] = _for === "from" ? from.split(":") : to.split(":");
      const duration = dayjs().set('minute', min).set('second', sec);
      return duration;
    }
  }, [selectedRow.duration]);
  const initialVideoState = {
    compilation: selectedRow.compilation_obj || null,
    video_key: "",
    duration_from: convertStringToDuration(),
    duration_to: convertStringToDuration("to"),
    video_title: selectedRow.video_title || "",
    dropbox_link: selectedRow.dropbox_link || "",
  };
  const [formData, setFormData] = React.useState(initialVideoState);
  const [compilation, setCompilation] = React.useState({
    options: [],
    loading: false,
  });
  const [uniqueKeys, setUniqueKeys] = React.useState({
    options: [],
    loading: false,
  });

  async function FecthCompilation() {
    setCompilation((pre) => ({ ...pre, loading: true }));
    await api
      .get("/promo/compilations", { params: { page: 1, page_size: 10000 } })
      .then((res) => {
        setCompilation((pre) => ({ ...pre, options: res.data?.results }));
      })
      .finally(() => {
        setCompilation((pre) => ({ ...pre, loading: false }));
      });
  }
  async function fetchUniqueKeys(query, setFirst = false) {
    if (query.length > 3) {
      setUniqueKeys((prev) => ({ ...prev, loading: true }));
      await api
        .get("/promo/unique_keys", {
          params: { unique_key: query },
          timeout: 100 * 1000,
        })
        .then((res) => {
          setUniqueKeys((prev) => ({ ...prev, options: res.data }));
          if (setFirst && res.data.length > 0) {
            setFormData((pre) => ({
              ...pre,
              video_key: res.data[0],
            }));
          }
        })
        .finally(() => {
          setUniqueKeys((prev) => ({ ...prev, loading: false }));
        });
    }
  }

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  const handleDuration = (field_name) => (_value) => {
    setFormData(pre => ({ ...pre, [field_name]: _value }))
  }
  const handleClose = () => {
    onClose();
  };
  const TimeRangeFormater = (_time) => {
    const new_duration = new Date(_time);
    const minutes = new_duration.getMinutes() < 10 ? '0' + new_duration.getMinutes() : new_duration.getMinutes();
    const seconds = new_duration.getSeconds() < 10 ? '0' + new_duration.getSeconds() : new_duration.getSeconds();
    return `${minutes}:${seconds}`;
  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (formData.duration_from >= formData.duration_to
      || formData.duration_to - formData.duration_from < 1000
      || formData.duration_to - formData.duration_from > 25000) {
      toast.error("Time duration not valid, with a difference of 1 to 25 seconds.");
      return;
    }
    if (is_update) {
      const updateChannel = async () => {
        let sendData = {
          ...formData,
          video_key: formData.unique_key,
          compilation:
            typeof formData.compilation === "object"
              ? formData.compilation.id
              : formData.compilation,
        };
        sendData["duration"] = `${TimeRangeFormater(sendData["duration_from"])}-${TimeRangeFormater(sendData["duration_to"])}`
        delete sendData["duration_from"]
        delete sendData["duration_to"]
        await api
          .patch(`/promo/compilation_videos/${selectedRow.id}/`, sendData)
          .then((res) => {
            if (res.status === 200) {
              toast.success("Channel updated successfully.");
              refreshFunc();

              onClose();
            }
          })
          .catch((error) => {
            const errorObject = error.response?.data;
            try {
              for (const field in errorObject) {
                if (errorObject?.hasOwnProperty(field)) {
                  const errors = errorObject[field];
                  const formattedFieldErrors = errors.map(
                    (errorMessage) => `${field}:${errorMessage}`
                  );
                  formattedFieldErrors?.map((msg) => toast.error(msg));
                }
              }
            } catch {
              try {
                toast.error(JSON.stringify(errorObject));
              } catch {
                toast.error("Something went wrong");
              }
            }
          });
      };
      updateChannel();
    } else {

      let sendData = {
        ...formData,
        video_key:
          typeof formData.video_key === "object"
            ? formData.video_key.unique_key
            : formData.video_key,
        compilation:
          typeof formData.compilation === "object"
            ? formData.compilation.id
            : formData.compilation,
      };
      sendData["compilation"] = formData.compilation?.id
      sendData["duration"] = `${TimeRangeFormater(sendData["duration_from"])}-${TimeRangeFormater(sendData["duration_to"])}`
      delete sendData["duration_from"]
      delete sendData["duration_to"]

      await api
        .post("/promo/compilation_videos/", sendData)
        .then((response) => {
          if (response.status === 201) {
            toast.success(response.data?.message);
            refreshFunc();
            onClose();
          }
        })
        .catch((error) => {
          const errorObject = error.response?.data;
          try {
            for (const field in errorObject) {
              if (errorObject?.hasOwnProperty(field)) {
                const errors = errorObject[field];
                const formattedFieldErrors = errors.map(
                  (errorMessage) => `${field}:${errorMessage}`
                );
                formattedFieldErrors?.map((msg) => toast.error(msg));
              }
            }
          } catch {
            try {
              toast.error(JSON.stringify(errorObject));
            } catch {
              toast.error("Something went wrong");
            }
          }
        });
    }
  };
  const handleDropboxlink = (title, link) => {
    setFormData(pre => ({ ...pre, video_title: title, dropbox_link: link }))
  }
  React.useEffect(() => {
    if (selectedRow.video_key) {
      fetchUniqueKeys(selectedRow.video_key, true);
    }
  },
    // eslint-disable-next-line 
    []
  );

  return (
    <Dialog maxWidth="md" open={open} onClose={handleClose}>
      <DialogTitle>
        {is_update ? "Update" : "Create"} Compilation Video
      </DialogTitle>
      <DialogContent>
        <DialogContentText mb={2}>
          Please fill in the required information to{" "}
          {is_update ? "update" : "add"} a new video in compilation.
        </DialogContentText>
        <form onSubmit={handleSubmit}>
          <Grid container sx={{ mb: 3 }} spacing={2} alignItems="center">
            <Grid item xs={12}>
              <Autocomplete
                disablePortal
                loading={compilation.loading}
                value={formData.compilation}
                onOpen={FecthCompilation}
                getOptionLabel={(op) => op.video_title}
                size="small"
                fullWidth
                options={compilation.options}
                placeholder="Select Compilation"
                onChange={(event, newValue) => {
                  setFormData({ ...formData, compilation: newValue });
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    required
                    label="Compilation"
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {compilation.loading ? (
                            <CircularProgress color="inherit" size={20} />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      ),
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={6} md={6}>
              <Autocomplete
                disablePortal
                size="small"
                loading={uniqueKeys.loading}
                onInputChange={(event, newInputValue) => {
                  fetchUniqueKeys(newInputValue);
                }}
                options={uniqueKeys.options}
                getOptionLabel={(option) => option?.unique_key || []}
                onChange={(event, newValue) => {
                  setFormData({
                    ...formData,
                    video_key: newValue,
                    video_title: newValue && newValue.video_title,
                    dropbox_link: null
                  });
                }}
                value={formData.video_key}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    required
                    label="WGID (video Key)"
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {uniqueKeys.loading ? (
                            <CircularProgress color="inherit" size={20} />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      ),
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item container xs={6} spacing={2}>
              <Grid item xs={6}>
                <TimeDurationPicker required label="From" value={formData.duration_from} onChange={handleDuration("duration_from")} />
              </Grid>
              <Grid item xs={6}>
                <TimeDurationPicker required label="To" value={formData.duration_to} onChange={handleDuration("duration_to")} />
              </Grid>
            </Grid>
            <Grid item xs={12}>
              {/* <TextInput
                label="Video Title (optional)"
                onChange={handleChange}
                fullWidth
                value={formData.video_title}
                name="video_title"
              /> */}
              <SearchFromCloud onChange={handleDropboxlink} selectedRow={selectedRow} />
            </Grid>
            <Grid item xs={12}>
              <TextInput
                label="Dropbox link"
                onChange={handleChange}
                fullWidth
                required
                value={formData.dropbox_link}
                name="dropbox_link"
                type="url"
              />
            </Grid>
          </Grid>
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button variant="contained" type="submit" color="primary">
              {is_update ? "Update" : "Add"} Video
            </Button>
          </DialogActions>
        </form>
      </DialogContent >
    </Dialog >
  );
}
