import React, { useEffect, useState } from "react";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from "dayjs";

export default function DateInput({
  label,
  value,
  onChange: onFilterChange
}) {
  const [date, setDate] = useState(value ? dayjs(value) : null);

  const handleDateChange = (_date) => {
    if (_date) {
      _date = dayjs(_date.$d).isValid() ? new Date(_date.$d.getTime() + 86400000).toISOString().split('T')[0] : "";
    }
    onFilterChange(_date);
    setDate(_date);
  }

  useEffect(() => {
    if (value !== null) {
      setDate(dayjs(value));
    }
  }, [value]);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
        sx={{
          width: "100%",
          "& input": {
            padding: "8.5px 14px"
          },
          "& .MuiInputLabel-root[data-shrink='false']": {
            lineHeight: "0.7",
          },
        }}
        label={label}
        value={date}
        onChange={handleDateChange}
        format="DD/MM/YYYY"
      />
    </LocalizationProvider>
  );
}
