import React, { useEffect, useState } from 'react';
import { Box, Button, Chip, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Paper, TextField, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import SendIcon from '@mui/icons-material/Send';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import GPTICON from "../../static/icons/chat-gpt.png"
import api from '../../conf/api';
import { toast } from 'react-toastify';
import { Scrollbars } from "react-custom-scrollbars-2";

export default function ChatDialog({ open, handleClose }) {
    const [loading, setLoading] = useState(false);
    const [messages, setMessages] = useState("");
    const [input, setInput] = useState('');
    const [history, setHistory] = useState('');


    const AskFromCHATGPT = async () => {
        if (input) {
            setLoading(true);
            setMessages("");
            setHistory(input);
            setInput("");
            try {
                const res = await api.post("/youtube/generate-response", { input_text: input }, { timeout: 120000 });
                setMessages(res.data);
            } catch (error) {
                console.log(error);
            } finally {
                setLoading(false);
            }
        }
    };

    const handleCopy = () => {
        if (messages) {
            navigator.clipboard.writeText(messages)
                .then(() => {
                    toast.info("Text copied to clipboard");
                })
                .catch((error) => {
                    console.error("Failed to copy text: ", error);
                });
        }
    };

    return (
        <Dialog open={open} maxWidth="sm" fullWidth >
            <DialogTitle>
                <Box display="flex" alignItems="center" gap={2}>
                    <Box component="img" alt="gpt" src={GPTICON} width={34} />
                    ChatGPT
                </Box>
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent dividers >
                <Box display="flex" flexDirection="column" gap={1} >
                    <Box sx={{ overflow: "hidden", bgcolor: "grey.300" }} component={Paper} elevation={3} p={1} >
                        <Scrollbars style={{ height: 150 }}>
                            <Typography align="justify" paragraph>
                                {history}
                            </Typography>
                        </Scrollbars>
                    </Box>
                    {messages.length < 1 ?
                        <Box textAlign="center" display="flex" justifyContent="center">
                            <Box component="img" alt="gpt" src={GPTICON} width={30} />
                        </Box> :
                        <Scrollbars style={{ height: 300 }}>
                            <Box display="flex" justifyContent="space-between" alignItems="end" gap={1} position="relative">
                                <Typography
                                    variant="body1"
                                    component="div"

                                    sx={{
                                        p: 1.5,
                                        borderRadius: 1,
                                    }}
                                >
                                    {messages}
                                </Typography>
                                <IconButton onClick={handleCopy} sx={{ position: "sticky", bottom: 0 }} >
                                    <FileCopyIcon />
                                </IconButton>
                            </Box>

                        </Scrollbars>
                    }
                </Box>
            </DialogContent>
            <DialogActions>
                <TextField
                    variant="outlined"
                    fullWidth
                    multiline
                    size='small'
                    margin='dense'
                    disabled={loading}
                    value={input}
                    onChange={(e) => setInput(e.target.value)}
                    placeholder="Type your message..."
                    onKeyDown={(e) => {
                        if (e.key === 'Enter' && !e.shiftKey) {
                            // If only Enter is pressed without Shift key, send message
                            e.preventDefault(); // Prevents the default behavior of Enter key (submitting the form)
                            AskFromCHATGPT();
                        }
                    }}
                />
                <IconButton color="primary" disabled={loading} onClick={AskFromCHATGPT}>
                    {loading ? <CircularProgress size={30} /> :
                        <SendIcon />
                    }
                </IconButton>
            </DialogActions>
        </Dialog>
    );
}
