import React, { useRef, useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Paper,
  Typography,
  LinearProgress,
  Grid,
  InputAdornment,
  IconButton,
  Divider,
  Autocomplete,
  Chip,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";
import SigninImage from "../../../static/images/signup.jpg";
import { connect } from "react-redux";
import { authSignup } from "../../../store/actions/actions.js";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import PhoneSelector from "../../../Components/Selectors/PhoneSelector";
import CountrySelect from "../../../Components/Selectors/CountrySelector.js";
import SignaturePad from "react-signature-pad-wrapper";
import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";
import HowToRegIcon from "@mui/icons-material/HowToReg";
import PasswordField from "../../../Components/Fields/PasswordField.js";
import TextInput from "../../../Components/Fields/TextInput.js";
import TermsDialog from "../Terms/TermsDialog.js";
import PromoTermsDialog from "../Terms/PromoTermsDialog.js";
import YouTubeIcon from '@mui/icons-material/YouTube';

function Signup(props) {
  const initialFields = {
    first_name: "",
    last_name: "",
    username: "",
    email: "",
    paypal_email: "",
    phone_number: "",
    country: undefined,
    city: "",
    state: "",
    zipcode: "",
    address: "",
    password: "",
    password2: "",
    instagram: "",
    tiktok: "",
    youtube: "",
    promo_youtube_links: "",
    is_promouser: props.is_promouser,
  };
  const [fields, setFields] = useState(initialFields);
  const [penColor, setPenColor] = useState("#000");
  const [showTerms, openTerms] = useState(false);
  const signatureRef = useRef(null);
  const [termsAgreed, setTermsAgrred] = useState({
    terms_of_service: false,
    monetization: false,
    accurate_info: false
  });
  const nvigation = useNavigate();

  const handleFieldChange = (e) => {
    setFields((pre) => ({ ...pre, [e.target.name]: e.target.value }));
  };
  const validateFields = (password) => {
    const minLength = 8;
    const hasUppercase = /[A-Z]/.test(password);
    const hasLowercase = /[a-z]/.test(password);
    const hasDigit = /\d/.test(password);
    // eslint-disable-next-line
    const hasSpecialChar = /[!@#$%^&*()_+{}\[\]:;<>,.?~\\-]/.test(password);
    if (signatureRef.current.isEmpty()) {
      toast.error("Signature is required.");
      return false;
    }
    if (password.length < minLength) {
      toast.error("Password should be at least 8 characters long.");
      return false;
    }
    if (!hasUppercase) {
      toast.error("Password should contain at least one uppercase letter.");
      return false;
    }
    if (!hasLowercase) {
      toast.error("Password should contain at least one lowercase letter.");
      return false;
    }
    if (!hasDigit) {
      toast.error("Password should contain at least one digit.");
      return false;
    }
    if (!hasSpecialChar) {
      toast.error("Password should contain at least one special character.");
      return false;
    }

    return true;
  };

  const ValidateFields = () => {
    if (!validateFields(fields.password)) {
      return false;
    } else if (fields.password !== fields.password2 && fields.password) {
      toast.error("Repeat password not matches.");
      return false;
    }
    return true;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (ValidateFields()) {
      const afterSuccess = () => {
        nvigation("/");
      };
      const formData = new FormData();
      formData.append("signature", signatureRef.current.toDataURL("image/png"));
      for (const _f in fields) {
        if (_f === "promo_youtube_links") {
          formData.append("promo_youtube_links", JSON.stringify(JSON.stringify(fields.promo_youtube_links)));
        }
        else if (_f === "country") {
          formData.append(_f, fields[_f].label);

        } else {
          formData.append(_f, fields[_f]);
        }
      }
      if (!props.is_promouser) {
        formData.delete("promo_youtube_links")
      }

      props.authSignup(formData, afterSuccess);
    }
  };
  const ChangePenColor = (color) => {
    setPenColor(color);
    signatureRef.current.clear();
    signatureRef.current.signaturePad.penColor = color;
  };
  const handleYoutube = (e, value) => {
    if (!value) {
      setFields(pre => ({ ...pre, promo_youtube_links: [] }));
      return
    }
    const pattern = /^(?:https?:\/\/)?(?:www\.)?youtube\.com\/@([a-zA-Z0-9_.-]+)$/;
    const urls = []
    for (const url of value) {
      if (pattern.test(url)) {
        urls.push(url)
      }
      else {
        toast.dismiss()
        toast.error("Youtube links are not in the correct format; they must be like `https://www.youtube.com/@username`");
      }
    }
    setFields(pre => ({ ...pre, promo_youtube_links: urls }));
  }

  const YoutubeUrlsAutoComplete = React.useCallback(() => {
    return <Autocomplete
      multiple
      size="large"
      options={[]}
      defaultValue={fields.promo_youtube_links}
      freeSolo
      onChange={handleYoutube}
      renderTags={(value, getTagProps) =>
        fields.promo_youtube_links?.map((link, index) => (
          <Chip variant="outlined" label={link} {...getTagProps({ index })} />
        ))
      }
      renderInput={(params) => (
        <TextField
          {...params}
          InputProps={{ ...params.InputProps, endAdornment: <InputAdornment position="end"><YouTubeIcon sx={{ color: "error.dark" }} /></InputAdornment> }}
          label="Youtube Channels"
          placeholder="https://www.youtube.com/@username"
        />
      )}
    />
  }, [fields.promo_youtube_links,])

  return (
    <Box display="flex" alignItems="center" justifyContent="center">
      <Box
        p={{ xs: "20px 10px", md: "40px  100px" }}
        component={Paper}
        elevation={2}
        borderRadius={4.5}
        display="flex"
        gap={2.5}
        flexDirection="column"
        alignItems="center"
      >
        <Grid container alignItems="center" spacing={2}>
          <Grid item xs={12} md={6}>
            <Grid
              container
              alignItems="center"
              spacing={1.5}
              maxWidth={600}
              component="form"
              onSubmit={handleSubmit}
            >
              <Grid item xs={12}>
                <Typography variant="h4" fontWeight="bold">
                  Sign up
                </Typography>
              </Grid>
              {/*   Personal detais */}
              <Grid item xs={12} mb={1}>
                <Typography variant="subtitle1" color="grey">
                  Personal details
                </Typography>
                <Divider flexItem variant="middle" />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextInput
                  fullWidth
                  value={fields.first_name}
                  onChange={handleFieldChange}
                  name="first_name"
                  required
                  placeholder="First Name"
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextInput
                  fullWidth
                  value={fields.last_name}
                  onChange={handleFieldChange}
                  name="last_name"
                  required
                  placeholder="Last Name"
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12}>
                <TextInput
                  fullWidth
                  value={fields.username}
                  onChange={handleFieldChange}
                  name="username"
                  required
                  placeholder="Username"
                  variant="outlined"
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <TextInput
                  fullWidth
                  value={fields.email}
                  onChange={handleFieldChange}
                  name="email"
                  required
                  placeholder="Email"
                  variant="outlined"
                  type="email"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextInput
                  fullWidth
                  value={fields.paypal_email}
                  onChange={handleFieldChange}
                  name="paypal_email"
                  placeholder="Paypal Email (optional)"
                  variant="outlined"
                  type="email"
                />
              </Grid>
              {/* Pasword */}
              <Grid item xs={12} md={6}>
                <PasswordField
                  fullWidth
                  value={fields.password}
                  onChange={handleFieldChange}
                  name="password"
                  placeholder="Password"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <PasswordField
                  fullWidth
                  value={fields.password2}
                  onChange={handleFieldChange}
                  name="password2"
                  placeholder="Repeat Password"
                />
              </Grid>
              {/* Address */}
              <Grid item xs={12} mb={1}>
                <Typography variant="subtitle1" color="grey">
                  Address
                </Typography>
                <Divider flexItem variant="middle" />
              </Grid>
              <Grid item xs={12}>
                <CountrySelect
                  required
                  value={fields.country}
                  onChange={(_value) => {
                    setFields((pre) => ({ ...pre, country: _value }));
                  }}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextInput
                  fullWidth
                  value={fields.city}
                  onChange={handleFieldChange}
                  name="city"
                  required
                  placeholder="City"
                  variant="outlined"
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <PhoneSelector
                  phone={fields.phone_number}
                  country_code={fields.country?.code?.toLowerCase()}
                  setPhone={(value) => {
                    console.log();
                    setFields((pre) => ({ ...pre, phone_number: value }));
                  }}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextInput
                  fullWidth
                  required
                  value={fields.zipcode}
                  onChange={handleFieldChange}
                  name="zipcode"
                  placeholder="Zip Code"
                  variant="outlined"
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextInput
                  fullWidth
                  required
                  value={fields.state}
                  onChange={handleFieldChange}
                  name="state"
                  placeholder="State or Province"
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12}>
                <TextInput
                  fullWidth
                  value={fields.address}
                  onChange={handleFieldChange}
                  name="address"
                  required
                  multiline
                  rows={4}
                  placeholder="Address"
                  variant="outlined"
                />
              </Grid>
              {/* Socail Media */}
              <Grid item xs={12} mb={1}>
                <Typography variant="subtitle1" color="grey">
                  Socail Media Accounts
                </Typography>
                <Divider flexItem variant="middle" />
              </Grid>
              {props.is_promouser &&
                <Grid item xs={12}>
                  <YoutubeUrlsAutoComplete />
                </Grid>
              }
              {!props.is_promouser &&
                <Grid item xs={12}>
                  <TextInput
                    fullWidth
                    value={fields.youtube}
                    onChange={handleFieldChange}
                    name={"youtube"}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Box
                            component="img"
                            width={20}
                            height={20}
                            src="https://cdn3.iconfinder.com/data/icons/social-network-30/512/social-06-512.png"
                          />
                        </InputAdornment>
                      ),
                    }}
                    placeholder="Youtube link"
                    type={"url"}
                    variant="outlined"
                  />
                </Grid>}
              {!props.is_promouser &&
                <Grid item xs={12}>
                  <TextInput
                    fullWidth
                    required={!props.is_promouser}
                    value={fields.instagram}
                    onChange={handleFieldChange}
                    name="instagram"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Box
                            component="img"
                            width={20}
                            height={20}
                            src="https://upload.wikimedia.org/wikipedia/commons/thumb/a/a5/Instagram_icon.png/600px-Instagram_icon.png"
                          />
                        </InputAdornment>
                      ),
                    }}
                    placeholder="Instagram link"
                    type="url"
                    variant="outlined"
                  />
                </Grid>}
              {!props.is_promouser &&
                <Grid item xs={12}>
                  <TextInput
                    fullWidth
                    required
                    value={fields.tiktok}
                    onChange={handleFieldChange}
                    name="tiktok"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Box
                            component="img"
                            width={20}
                            height={20}
                            src="https://w7.pngwing.com/pngs/814/840/png-transparent-tiktok-tiktok-logo-tiktok-icon-thumbnail.png"
                          />
                        </InputAdornment>
                      ),
                    }}
                    placeholder="Tiktok link"
                    type="url"
                    variant="outlined"
                  />
                </Grid>}
              {!props.is_promouser &&
                <Grid item xs={12}>
                  <TextInput
                    fullWidth
                    value={fields.gdrive}
                    onChange={handleFieldChange}
                    name="gdrive"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Box
                            component="img"
                            width={20}
                            height={20}
                            src="https://upload.wikimedia.org/wikipedia/commons/d/da/Google_Drive_logo.png"
                          />
                        </InputAdornment>
                      ),
                    }}
                    placeholder="Google Drive link"
                    type="url"
                    variant="outlined"
                  />
                </Grid>}
              {!props.is_promouser &&
                <Grid item xs={12}>
                  <TextInput
                    fullWidth
                    value={fields.snapchat}
                    onChange={handleFieldChange}
                    name="snapchat"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Box
                            component="img"
                            width={20}
                            height={20}
                            src="https://static.vecteezy.com/system/resources/previews/023/986/535/non_2x/snapchat-logo-snapchat-logo-transparent-snapchat-icon-transparent-free-free-png.png"
                          />
                        </InputAdornment>
                      ),
                    }}
                    placeholder="Snapchat link"
                    type="url"
                    variant="outlined"
                  />
                </Grid>}
              {/* Signature */}
              <Grid item xs={12}>
                <Box
                  p={"30px 20px"}
                  border="1px solid"
                  borderColor="grey.400"
                  borderRadius={"10px"}
                >
                  <Box mb={3.75}>
                    <Typography variant="subtitle1" paragraph>
                      Draw your signature here:*
                    </Typography>
                  </Box>
                  <Box
                    position={"relative"}
                    sx={{
                      height: "242px",
                    }}
                  >
                    <Box
                      display="flex"
                      justifyContent={"space-between"}
                      mb={-5}
                      mx={1.875}
                      alignItems={"center"}
                    >
                      <Box display="flex" gap="10px">
                        <IconButton
                          sx={{
                            width: { xs: 8.34, sm: 16.17, md: 24 },
                            height: { xs: 8.34, sm: 16.17, md: 24 },
                            background: "#000",
                          }}
                          onClick={() => {
                            ChangePenColor("#000");
                          }}
                        >
                          {penColor === "#000" ? (
                            <CheckIcon
                              sx={{
                                fontSize: { xs: 8, sm: 14, md: 18 },
                                color: "#fff",
                              }}
                            />
                          ) : undefined}
                        </IconButton>
                        <IconButton
                          sx={{
                            width: { xs: 8.34, sm: 16.17, md: 24 },
                            height: { xs: 8.34, sm: 16.17, md: 24 },
                            background: "#1A54C2;",
                          }}
                          onClick={() => {
                            ChangePenColor("#1A54C2");
                          }}
                        >
                          {penColor === "#1A54C2" ? (
                            <CheckIcon
                              style={{
                                fontSize: { xs: 8, sm: 14, md: 18 },
                                color: "#fff",
                              }}
                            />
                          ) : undefined}
                        </IconButton>
                        <IconButton
                          sx={{
                            width: { xs: 8.34, sm: 16.17, md: 24 },
                            height: { xs: 8.34, sm: 16.17, md: 24 },
                            background: "#C12B2B",
                          }}
                          onClick={() => {
                            ChangePenColor("#C12B2B");
                          }}
                        >
                          {penColor === "#C12B2B" ? (
                            <CheckIcon
                              sx={{
                                fontSize: { xs: 8, sm: 14, md: 18 },
                                color: "#fff",
                              }}
                            />
                          ) : undefined}
                        </IconButton>
                      </Box>
                      <IconButton
                        sx={{
                          p: 0,
                        }}
                        onClick={() => {
                          signatureRef.current.clear();
                        }}
                      >
                        <ClearIcon />
                      </IconButton>
                    </Box>
                    <SignaturePad
                      ref={signatureRef}
                      canvasProps={{
                        style: {
                          background: "#F5F5F5",
                          border: "1px solid #D6D6D6",
                          borderRadius: "10px",
                        },
                      }}
                    />
                  </Box>
                </Box>
              </Grid>


              <Box display="flex" flexDirection="column" gap={2} mt={3}>
                {/* 1 */}
                <Box display="flex" flexDirection="column">
                  <Typography variant="subtitle2" sx={{ color: "grey.500" }}>
                    Terms of Services *
                  </Typography>
                  <FormControlLabel
                    control={<Checkbox size="small" />}
                    disableTypography
                    value={termsAgreed.terms_of_service}
                    onChange={(e, checked) => {
                      setTermsAgrred((pre) => ({ ...pre, terms_of_service: checked }));
                    }}
                    labelPlacement="end"
                    label={
                      <Typography variant="subtitle1">
                        I agree all statements in{" "}
                        {/* <Link to="/terms" style={{ textDecoration: "none" }}> */}
                        <Box component="span" onClick={() => {
                          openTerms(true)
                        }} sx={{ color: "primary.dark", textDecoration: "underline" }}>

                          Terms of service
                        </Box>
                        {/* </Link> */}
                      </Typography>
                    }
                  />
                </Box>
                {/* 2 */}
                {!props.is_promouser &&
                  <Box display="flex" flexDirection="column">
                    <Typography variant="subtitle2" sx={{ color: "grey.500" }}>
                      I will not use the Youtube Copyright Match Tool to "takedown" re-uploads *
                    </Typography>
                    <FormControlLabel control={<Checkbox size="small" />}
                      disableTypography
                      value={termsAgreed.monetization}
                      onChange={(e, checked) => {
                        setTermsAgrred((pre) => ({ ...pre, monetization: checked }));
                      }}
                      labelPlacement="end"
                      label={
                        <Typography variant="subtitle1">
                          I agree to allow CreatorVault to "monetize" all re-uploads.
                        </Typography>
                      }
                    />

                  </Box>
                }
                {/* 3 */}
                <Box display="flex" flexDirection="column">
                  <Typography variant="subtitle2" sx={{ color: "grey.500" }}>
                    Accurate Information *
                  </Typography>
                  <FormControlLabel
                    control={<Checkbox size="small" />}
                    disableTypography
                    value={termsAgreed.accurate_info}
                    onChange={(e, checked) => {
                      setTermsAgrred((pre) => ({ ...pre, accurate_info: checked }));
                    }}
                    labelPlacement="end"
                    label={
                      <Typography variant="subtitle1">
                        I agree that all of my information is accurate and true.
                      </Typography>
                    }
                  />
                </Box>

              </Box>
              <Grid item xs={6}>
                {props.loading ? (
                  <LinearProgress color="success" />
                ) : (
                  <Button
                    fullWidth
                    startIcon={<HowToRegIcon />}
                    disabled={
                      !termsAgreed?.terms_of_service
                      || (!props.is_promouser && !termsAgreed?.monetization)
                      || !termsAgreed?.accurate_info
                      || props.loading
                    }
                    type="submit"
                    size="large"
                    variant="contained"
                    color="success"
                  >
                    Register
                  </Button>
                )}
              </Grid>
            </Grid>
          </Grid>
          <Grid
            container
            display={{ xs: "none", md: "flex" }}
            direction={"column"}
            justifyContent="center"
            alignItems="center"
            item
            xs={12}
            md={6}
          >
            <Grid item>
              <Box component="img" src={SigninImage} height="400px" />
            </Grid>
            <Grid item>
              <Typography
                component={Link}
                to="/"
                sx={{ textDecoration: "none" }}
              >
                I am already member
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <Dialog
        keepMounted
        open={showTerms}
        onClose={() => {
          openTerms(false)
        }}

        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title">Terms of Services</DialogTitle>
        <DialogContent >

          <DialogContentText
            id="scroll-dialog-description"
            tabIndex={-1}
          >
            {
              !props.is_promouser ? (
                <TermsDialog />
              ) : (
                <PromoTermsDialog />
              )
            }
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => {
            openTerms(false);
          }}>Close</Button>
          <Button onClick={!props.is_promouser ? (
            () => { nvigation("/terms") }
          ) : (
            () => { nvigation("/promo_terms") }
          )}>Detail</Button>
        </DialogActions>
      </Dialog>
    </Box >
  );
}
const mapStatesToProps = (state) => {
  return {
    loading: state.loading,
    is_promouser: window.location.pathname === "/partner_signup169",
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    authSignup: (fields, afterSuccess) =>
      dispatch(authSignup(fields, afterSuccess)),
  };
};
export default connect(mapStatesToProps, mapDispatchToProps)(Signup);
