import React, { useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import { Box, Typography } from "@mui/material";
import PaidIcon from "@mui/icons-material/Paid";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import api from "../../../conf/api";
import { toast } from "react-toastify";

const EditEarningDialog = ({ open, onClose, refrashFunc, row, is_promo = false }) => {
  const [editedData, setEditedData] = useState({});
  const [loading, setLoading] = useState(false);

  const handleInputChange = (fieldName, value) => {
    setEditedData({
      ...editedData,
      [fieldName]: value,
    });
  };
  useEffect(() => {
    const FetchData = async () => {
      await api.get(is_promo ? `/earning/promo/${row.id}` : `/earning/${row.id}`).then((res) => {
        setEditedData(res.data);
      });
    };
    if (row.id) {
      FetchData();
    }
    // eslint-disable-next-line
  }, [row]);

  const handleUpdate = async () => {
    try {
      if (editedData.amount < 0) {
        console.error("Amount cannot be negative");
        return;
      }

      setLoading(true);

      await api.put(is_promo ? `/earning/promo/${row.id}/` : `/earning/${row.id}/`, editedData);
      toast.success("Data updated successfully");
      refrashFunc()
    } catch (error) {
      console.log(error.response?.data?.error);
      toast.error("Error updating data:", error);
    } finally {
      setLoading(false);
      onClose();
    }
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>
        <Typography variant="h6">
          <strong>Asset ID:</strong> AASDADA
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Grid2 alignItems="center" container spacing={2}>
          <Grid2 xs={12}>
            <Typography fontWeight={600} variant="body1">
              User: {editedData.username}
            </Typography>
          </Grid2>
          <Grid2 xs={6}>
            <Typography variant="body1">Created Date:</Typography>
            <TextField
              variant="outlined"
              size="small"
              disabled
              value={editedData.created_date}
              fullWidth
            />
          </Grid2>

          <Grid2 xs={12}>
            <Typography variant="body1">Amount:</Typography>
            <TextField
              size="small"
              variant="outlined"
              value={editedData.amount}
              fullWidth
              onChange={(e) => handleInputChange("amount", e.target.value)}
            />
          </Grid2>
          <Grid2 xs={6}>
            <Typography variant="body1">Wooglobe Revenue:</Typography>
            <TextField
              size="small"
              variant="outlined"
              value={editedData.wooglobe_revenue}
              fullWidth
              onChange={(e) => handleInputChange("wooglobe_revenue", e.target.value)}
            />
          </Grid2>
          <Grid2 xs={6}>
            <Typography variant="body1">Partner Revenue:</Typography>
            <TextField
              size="small"
              variant="outlined"
              value={editedData.partner_revenue}
              fullWidth
              onChange={(e) => handleInputChange("partner_revenue", e.target.value)}
            />
          </Grid2>
          <Grid2 xs={3}>
            <Box display="flex" alignItems="center">
              {editedData.approval ? (
                <CheckBoxIcon color="success" />
              ) : (
                <CheckBoxOutlineBlankIcon />
              )}
              <Typography variant="caption" color="gray">
                Approved
              </Typography>
            </Box>
          </Grid2>
          <Grid2 xs={3}>
            <Box display="flex" alignItems="center">
              <PaidIcon color={editedData.paid ? "success" : "grey"} />
              <Typography variant="caption" color="gray">
                Paid
              </Typography>
            </Box>
          </Grid2>
          <Grid2 xs={12}>
            <Typography variant="body1">Note:</Typography>
            <TextField
              multiline
              rows={3}
              variant="outlined"
              size="small"
              value={editedData.description}
              fullWidth
              onChange={(e) => handleInputChange("description", e.target.value)}
            />
          </Grid2>
        </Grid2>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} disabled={loading}>Close</Button>
        <Button variant="contained" onClick={handleUpdate} color="primary" disabled={loading}>
          Update
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditEarningDialog;
