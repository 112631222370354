import React, { useState } from 'react';
import {
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Button,
	CircularProgress,
	Typography,
} from '@mui/material';
import api from '../../../../conf/api';
import { toast } from 'react-toastify';
import RestartAltIcon from '@mui/icons-material/RestartAlt';


const YoutubePublishingRetryDialog = ({ open, handleClose, row, reFreshFunc }) => {
	const [loading, setLoading] = useState(false);

	const handleConfirm = async () => {
		setLoading(true);
		const payload = {
			queue_status: encodeURI(["Pending"])
		};
		api.post(`/youtube/update-status/${row.status[0]?.id}/`, payload)
			.then(res => {
				if (res.status === 200) {
					toast.success(res.data?.message);
					reFreshFunc();
					handleClose();
				}
			})
			.catch(error => {
				if (error.response?.data?.error) {
					toast.error(error.response?.data?.error);
				} else {
					toast.error("Something went wrong");
				}
			})
			.finally(() => {
				setLoading(false);
			});
	};

	return (
		<Dialog open={open} onClose={handleClose}>
			<DialogTitle sx={{ display: "flex", alignItems: "center", gap: 1 }}><RestartAltIcon color='warning' fontSize='large' />Confirm Resubmission</DialogTitle>
			<DialogContent>
				<Typography>
					{`Are you sure you want to resubmit the video (${row.status?.length > 0 && row.status[0].id})?`}
				</Typography>
			</DialogContent>
			<DialogActions>
				<Button onClick={handleClose} >
					Cancel
				</Button>
				<Button disabled={loading} startIcon={loading ? <CircularProgress size={20} /> : <RestartAltIcon />} onClick={handleConfirm} color="warning">
					Resubmit
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default YoutubePublishingRetryDialog;