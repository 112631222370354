import React, { useCallback, useEffect, useState } from "react";
import {
	IconButton,
	Paper,
	Tooltip
} from '@mui/material';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Scrollbars from 'react-custom-scrollbars-2';
import { DataGrid } from '@mui/x-data-grid';
import AddCircleIcon from "@mui/icons-material/AddCircle";
import CreateIcon from "@mui/icons-material/Create";
import DeleteIcon from "@mui/icons-material/Delete";
import api from "../../../../conf/api";
import LanguageBox from "../components/languageBox";
import ConfirmDeleteLanguageBox from "../components/confirmDeleteLanguageBox";

export default function Languages() {
	const [loading, setLoading] = useState(false);
	const [data, setData] = useState([]);
	const [rowCount, setRowCount] = useState(0);
	const [selectedRow, setSelectedRow] = useState(0);
	const [isUpdate, setIsUpdate] = useState(false);
	const [languageBox, setLanguageBox] = useState(false);
	const [deleteLanguageBox, setDeleteLanguageBox] = useState(false);

	const styles = {
		"& .deleted-row": {
			backgroundColor: "rgba(255, 0, 0, 0.1)",
		},
	};

	const columns = [
		{
			field: "id",
			headerName: "ID",
			sortable: true,
			width: 90
		},
		{
			field: "name",
			headerName: "Language Name",
			sortable: true,
			minWidth: 200,
			flex: 1,
		},
		{
			field: "action",
			headerName: "Action",
			align: "center",
			headerAlign: "center",
			sortable: true,
			width: 220,
			renderCell: (params) => (
				<Box display="flex" gap={1}>
					<Tooltip title="Edit Category">
						<IconButton size="small" onClick={handleUpdateLanguage(params.row.id)}>
							<CreateIcon fontSize="small" color="primary" />
						</IconButton>
					</Tooltip>
					<Tooltip title="Delete Category">
						<IconButton size="small" onClick={handleDeleteLanguage(params.row.id)}>
							<DeleteIcon fontSize="small" sx={{ color: "error.dark" }} />
						</IconButton>
					</Tooltip>
				</Box>
			),
		},
	];

	async function fetchData() {
		await api
			.get("/youtube/languages")
			.then((res) => {
				setData(res.data);
				setRowCount(res.data.length);
			})
			.catch((err) => {
				console.log(err);
			})
			.finally(() => {
				setLoading(false);
			});
	}

	useEffect(
		() => {
			setLoading(true);
			fetchData();
		},
		// eslint-disable-next-line
		[]
	);

	const handleUpdateLanguage = (id) => () => {
		setSelectedRow(id);
		setIsUpdate(true);
		setLanguageBox(true);
	};
	const handleDeleteLanguage = (id) => () => {
		setSelectedRow(id);
		setDeleteLanguageBox(true);
	};

	const LanguageDialog = useCallback(
		() => (
			<LanguageBox
				open={languageBox}
				selectedRow={selectedRow}
				is_update={isUpdate}
				onClose={() => {
					setIsUpdate(false);
					setSelectedRow({});
					setLoading(false);
					setLanguageBox(false);
				}}
				refreshFunc={fetchData}
			/>
		),
		// eslint-disable-next-line
		[languageBox, isUpdate, selectedRow]
	);
	const DeleteLanguageDialog = useCallback(
		() => (
			<ConfirmDeleteLanguageBox
				open={deleteLanguageBox}
				selectedRow={selectedRow}
				setOpen={setDeleteLanguageBox}
				refreshFunc={fetchData}
			/>
		),
		// eslint-disable-next-line
		[deleteLanguageBox, selectedRow]
	);

	return (
		<Box mt={4} component={Paper} borderRadius={4}>
			<Box
				border="1px solid"
				borderColor="grey.400"
				borderRadius={"10px 10px 0px 0px"}
				p={1}
				display="flex"
				alignItems="center"
				justifyContent="space-between"
			>
				<Typography color="grey" fontWeight={600}>
					All Languages
				</Typography>
				<Tooltip title="Add New Language">
					<IconButton
						size="small"
						onClick={() => {
							setIsUpdate(false);
							setLanguageBox(true);
						}}
					>
						<AddCircleIcon color="success" />
					</IconButton>
				</Tooltip>
			</Box>
			<Scrollbars style={{ height: 500, width: "100%" }}>
				<DataGrid
					rows={data}
					columns={columns}
					loading={loading}
					sx={styles}
					pageSize={10}
					rowCount={rowCount}
					rowSelection={false}
					disableColumnSelector
					disableDensitySelector
					disableSelectionOnClick
					getRowClassName={(params) => {
						if (params.row?.deleted) {
							return "deleted-row";
						}
						return "";
					}}
				/>
			</Scrollbars>
			{/* Add New Language */}
			<LanguageDialog />
			{/* Confirm Delete Box  */}
			<DeleteLanguageDialog />
		</Box>
	);
}
