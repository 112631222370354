import React, { useCallback, useEffect, useState } from "react";
import {
    IconButton,
    Paper,
    Tooltip
} from '@mui/material';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Scrollbars from 'react-custom-scrollbars-2';
import { DataGrid } from '@mui/x-data-grid';
import AddCircleIcon from "@mui/icons-material/AddCircle";
import CreateIcon from "@mui/icons-material/Create";
import DeleteIcon from "@mui/icons-material/Delete";
import api from "../../../../conf/api";
import AccountDeleteDialog from "../components/AccountDeleteDialog";
import AccountForm from "../components/AccountForm";

export default function Accounts() {
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const [rowCount, setRowCount] = useState(0);
    const [selectedRow, setSelectedRow] = useState(0);
    const [isUpdate, setIsUpdate] = useState(false);
    const [formBox, setFormeBox] = useState(false);
    const [deleteLanguageBox, setDeleteLanguageBox] = useState(false);

    const styles = {
        "& .deleted-row": {
            backgroundColor: "rgba(255, 0, 0, 0.1)",
        },
    };

    const columns = [
        {
            field: "id",
            headerName: "ID",
            sortable: true,
            width: 90
        },
        {
            field: "account_name",
            headerName: "Account Name",
            sortable: true,
            minWidth: 200,
            flex: 1,
        },
        {
            field: "email",
            headerName: "Email",
            sortable: true,
            minWidth: 250,
        },
        {
            field: "disabled",
            headerName: "Disabled",
            sortable: true,
            type: "boolean",
        },
        {
            field: "disabling_note",
            headerName: "Reason",
            sortable: false,
            minWidth: 200,
        },
        {
            field: "action",
            headerName: "Action",
            align: "center",
            headerAlign: "center",
            sortable: true,
            width: 220,
            renderCell: (params) => (
                <Box display="flex" gap={1}>
                    <Tooltip title="Edit Category">
                        <IconButton size="small" onClick={handleUpdateAccount(params.row)}>
                            <CreateIcon fontSize="small" color="primary" />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Delete Category">
                        <IconButton size="small" onClick={handleDeleteLanguage(params.row)}>
                            <DeleteIcon fontSize="small" sx={{ color: "error.dark" }} />
                        </IconButton>
                    </Tooltip>
                </Box>
            ),
        },
    ];

    const getRowClassName = (params) => {
        return params.row.disabled ? 'disabled-row' : '';
    };

    async function fetchData() {
        await api
            .get("/youtube/accounts")
            .then((res) => {
                const data = res.data?.map((d, i) => ({ id: i, ...d }))
                setData(data);
                setRowCount(res.data.length);
            })
            .catch((err) => {
                console.log(err);
            })
            .finally(() => {
                setLoading(false);
            });
    }

    useEffect(
        () => {
            setLoading(true);
            fetchData();
        },
        // eslint-disable-next-line
        []
    );

    const handleUpdateAccount = (row) => () => {
        setSelectedRow(row);
        setIsUpdate(true);
        setFormeBox(true);
    };
    const handleDeleteLanguage = (row) => () => {
        setSelectedRow(row);
        setDeleteLanguageBox(true);
    };

    const AccountFormDialog = useCallback(
        () => (
            <AccountForm
                open={formBox}
                selectedRow={selectedRow}
                is_update={isUpdate}
                onClose={() => {
                    setIsUpdate(false);
                    setSelectedRow({});
                    setLoading(false);
                    setFormeBox(false);
                }}
                refreshFunc={fetchData}
            />
        ),
        // eslint-disable-next-line
        [formBox, isUpdate, selectedRow]
    );
    const DeleteAccountFormDialog = useCallback(
        () => (
            <AccountDeleteDialog
                open={deleteLanguageBox}
                selectedRow={selectedRow}
                setOpen={setDeleteLanguageBox}
                refreshFunc={fetchData}

            />
        ),
        // eslint-disable-next-line
        [deleteLanguageBox, selectedRow]
    );

    return (
        <Box mt={4} component={Paper} borderRadius={4}>
            <Box
                border="1px solid"
                borderColor="grey.400"
                borderRadius={"10px 10px 0px 0px"}
                p={1}
                display="flex"
                alignItems="center"
                justifyContent="space-between"
            >
                <Typography color="grey" fontWeight={600}>
                    All Accounts
                </Typography>
                <Tooltip title="Add new users">
                    <IconButton
                        size="small"
                        onClick={() => {
                            setIsUpdate(false);
                            setFormeBox(true);
                        }}
                    >
                        <AddCircleIcon color="success" />
                    </IconButton>
                </Tooltip>
            </Box>
            <Scrollbars style={{ height: 500, width: "100%" }}>
                <DataGrid
                    rows={data}
                    columns={columns}
                    loading={loading}
                    hideFooter
                    hideFooterPagination
                    sx={styles}
                    pageSize={10}
                    rowCount={rowCount}
                    rowSelection={false}
                    disableColumnSelector
                    disableDensitySelector
                    disableSelectionOnClick
                    getRowClassName={(params) => {
                        if ( params.row?.disabled) {
                            return "disabled-row";
                        }
                        return "";
                    }}
                />
            </Scrollbars>
            {/* Add New Language */}
            <AccountFormDialog />
            {/* Confirm Delete Box  */}
            <DeleteAccountFormDialog />
        </Box>
    );
}
