import React, { useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import PlayCircleIcon from "@mui/icons-material/PlayCircle";
import {
  Alert,
  Button,
  Collapse,
  Divider,
  Hidden,
  IconButton,
  LinearProgress,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  TextField,
  Tooltip,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { Scrollbars } from "react-custom-scrollbars-2";
import CircularSpinner from "../../../Components/Progress/CircularSpinner.js";
import { toast } from "react-toastify";
import api from "../../../conf/api.js";
import Cookies from "js-cookie";
import InfoIcon from "@mui/icons-material/Info";

const useBeforeUnload = ({ when, message }) => {
  useEffect(() => {
    const handleBeforeUnload = (event) => {
      event.preventDefault();
      event.returnValue = message;
      return message;
    };

    if (when) {
      window.addEventListener("beforeunload", handleBeforeUnload);
    }

    return () => window.removeEventListener("beforeunload", handleBeforeUnload);
  }, [when, message]);
};
const videoFormats = [
  "mp4",
  "webm",
  "mkv",
  "mov",
  "flv",
  "wmv",
  "mpeg",
  "mpg",
  "ogv",
];

const VideoUploader = () => {
  const [inProcess, setProcess] = useState(false);
  const [videos, setVideos] = useState([]);
  const [errors, setErrors] = useState([]);
  const [uploading, setUploading] = useState({ uploaded: false })

  const onDrop = async (acceptedFiles) => {
    for (const file of acceptedFiles) {
      const formate = file.path.split(".").splice(-1)[0];

      if (!videoFormats.includes(formate)) {
        setErrors([
          {
            show: true,
            severity: "warning",
            message: `${file.name} not spported.`,
          },
        ]);
      } else if (file.type.startsWith("video/")) {
        // Load the video to get its duration
        const video = document.createElement("video");
        video.src = URL.createObjectURL(file);
        video.onload = () => {
          console.log(video);
        };
        video.onloadedmetadata = async () => {
          const duration = video.duration;
          if (duration >= 20) {
            const newVideo = {
              loading: false,
              title: file.name.replace(/\.[^/.]+$/, ""),
              file: file,
              extension: file.name.split(".").pop(),
              duration: duration,
            };
            // if duration and title match the we consider it exist already
            if (
              videos.find(
                (f) =>
                  f.title ===
                  file.name.replace(/\.[^/.]+$/, "" && duration === f.duration)
              )
            ) {
              setErrors([
                {
                  show: true,
                  severity: "warning",
                  message: `Video ${file.name} already exist with same duration ${duration}.`,
                },
              ]);
              return;
            }
            setVideos((prevVideos) => [...prevVideos, newVideo]);
          } else {
            // Notify the user that the video is too short
            setErrors([
              {
                show: true,
                severity: "warning",
                message: `Video ${file.name} is too short. It should be at least 20 seconds.`,
              },
            ]);
          }
          // Clean up the video element
          URL.revokeObjectURL(video.src);
        };
      } else {
        setErrors([
          {
            show: true,
            severity: "warning",
            message: `${file.name}-(${file.type}) not allowed `,
          },
        ]);
      }
    }
  };

  const removeVideo = (index, event) => {
    event.stopPropagation();
    const updatedVideos = [...videos];
    const removedVideo = updatedVideos[index];

    // Stop the ongoing progress simulation (if any)
    if (removedVideo.loading) {
      // Clear the progress simulation timeout
      removedVideo.loading = false;
      clearTimeout(removedVideo.progressTimeout);
    }

    updatedVideos.splice(index, 1);
    setVideos(updatedVideos);
  };
  const handleVideoTitle = (index) => (e) => {
    if (e.target.value.length <= 15) {
      let _videos = videos;
      _videos[index].title = e.target.value;
      setVideos(_videos);
    } else {
      document.getElementById(e.target.id).value = e.target.value.slice(0, 15);
    }
  };
  const uploadVideos = async () => {
    let videoUploaded = [];
    setUploading(pre => ({ ...pre, uploaded: true }))
    setProcess(true);
    setErrors([]);
    for (const video of videos) {
      const { title, file, duration } = video;

      // Video is in a loading state, so upload it
      const formData = new FormData();
      formData.append("file", file);
      formData.append("title", title);
      formData.append("duration", duration);

      await api
        .post("/videos/upload/", formData, {
          headers: {
            Authorization: `Bearer ${Cookies.get("access_token")}`,
          },
          timeout: 60 * 1000 * 15,
          onUploadProgress: (progressEvent) => {
            const progress = (progressEvent.loaded / progressEvent.total) * 100;
            video.loading = true;
            video.file.uploadedPercentage = progress;
            setVideos([...videos]);
          },
        })
        .then((response) => {
          if (response.status === 201) {
            videoUploaded.push(video);

            setErrors((prev) => [
              ...prev,
              {
                show: true,
                severity: "success",
                message: response.data.message,
              },
            ]);
          }
        })
        .catch((error) => {
          // Handle the error

          console.log(error);
          const errorData = error.response?.data;
          if (error.response.status === 409) {
            videoUploaded.push(video);
          } else {
            toast.error("Error while uploading the video");
          }

          Object.keys(errorData).map((userKey) =>
            errorData[userKey].map((errorMessage, index) =>
              setErrors((prev) => [
                ...prev,
                {
                  show: true,
                  severity: "error",
                  message: `
              ${userKey}; ${errorMessage}`,
                },
              ])
            )
          );
          setUploading(pre => ({ ...pre, uploaded: false }))
        })
      // Remove the video by file (or another unique identifier) if an error occurs
      // const updatedVideos = videos.filter((v) => v.file !== file);
      // setVideos(updatedVideos);

      const updatedVideos = videos.filter(
        (video) => !videoUploaded.includes(video)
      );
      setVideos(updatedVideos);
      setProcess(false);
    }
  };

  const handleCloseAlert = (error) => (event) => {
    event.stopPropagation();
    setErrors((prev) => prev.filter((e) => e.message !== error.message));
  };
  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: "video/*",
    multiple: true,
    disabled: uploading.uploaded || inProcess
  });

  useBeforeUnload({
    when: inProcess,
    message: "Are you sure you want to cancel it.",
  });

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      gap={2}
    >
      {/* Title */}
      <Typography variant="h4" paragraph>
        Upload Videos
      </Typography>
      {/* Upload Box */}
      <Box
        {...getRootProps()}
        sx={{
          width: { xs: "90%", md: "80%", xl: "50%" },
          border: "2px dashed",
          borderColor: "primary.dark",
          borderRadius: 4,
          padding: 2.5,
          // minHeight: 300,
          textAlign: "center",
          cursor: "pointer",
          "&:hover": {
            backgroundColor: "grey.100",
          },
        }}
      >
        <input {...getInputProps()} />
        <Typography variant="body1" fontWeight="bold" mt={2}>
          Drag video files here, or{" "}
          <Typography component="span" color="primary" fontWeight="bold">
            click
          </Typography>{" "}
          to select files
        </Typography>
        <Typography variant="body1">
          Allowed{" "}
          <Tooltip title={videoFormats.map((fmt) => fmt + ", ")}>
            <Box
              component="strong"
              display="inline-flex"
              alignItems="center"
              sx={{ textDecoration: "underline", color: "primary.main" }}
            >
              formats <InfoIcon fontSize="small" />
            </Box>
          </Tooltip>
        </Typography>

        <Hidden mdDown>
          {errors.map((e, i) => (
            <Collapse in={e.show}>
              <Alert
                onClose={handleCloseAlert(e)}
                sx={{ mt: 1 }}
                severity={e.severity}
              >
                {e.message}
              </Alert>
            </Collapse>
          ))}
        </Hidden>
        <Box sx={{ mt: 2 }}>
          {videos.length > 0 && (
            <Typography variant="h6">Selected Videos:</Typography>
          )}
          <Box>
            {videos.length <= 0 && (
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
              >
                <CloudUploadIcon
                  sx={{
                    fontSize: { md: 150, xs: 100 },
                    color: "grey",
                    mt: 2,
                  }}
                />
                <Typography variant="h5">Upload Videos</Typography>
              </Box>
            )}
          </Box>

          <List
            disablePadding
            sx={{
              zIndex: 2,
              width: "100%",
              display: videos.length > 0 ? "flex" : "none",
            }}
          >
            <Scrollbars style={{ height: 250 }}>
              {videos.map((file, index) => (
                <div key={index}>
                  <ListItem
                    secondaryAction={
                      <IconButton
                        aria-label="comment"
                        disabled={file.loading}
                        onClick={(event) => removeVideo(index, event)}
                      >
                        <CloseIcon />
                      </IconButton>
                    }
                  >
                    <ListItemAvatar>
                      {file.loading ? (
                        <CircularSpinner
                          progress={file.file.uploadedPercentage}
                        />
                      ) : (
                        <Tooltip title={"." + file.extension}>
                          <PlayCircleIcon fontSize="large" color="success" />
                        </Tooltip>
                      )}
                    </ListItemAvatar>

                    <ListItemText
                      primary={
                        <TextField
                          size="small"
                          variant="standard"
                          disabled={file.loading}
                          defaultValue={file.title}
                          id={`videofield-${index}`}
                          onClick={(e) => {
                            e.stopPropagation();
                          }}
                          onChange={handleVideoTitle(index)}
                        />
                      }
                      secondary={`${(file.file.size / 1024).toFixed(2)} KB`}
                    />
                  </ListItem>
                  <Divider component="li" variant="inset" />
                </div>
              ))}
            </Scrollbars>
          </List>
        </Box>
        {inProcess && (
          <Box width="100%" mt={1}>
            <LinearProgress color="success" />
          </Box>
        )}
      </Box>

      {/* Start Progress Button */}
      <Button
        startIcon={<CloudUploadIcon />}
        disabled={videos.length < 1 || inProcess || uploading.uploaded}
        onClick={uploadVideos}
        variant="contained"
        color="primary"
      >
        {uploading.uploaded ? !inProcess ? "Video Uploaded Successfully" : "Uploading please wait..." : "Proceed to upload"}
      </Button>
      {/* Alert on xs */}
      <Hidden mdUp>
        {errors.map((e, i) => (
          <Collapse in={e.show}>
            <Alert
              onClose={handleCloseAlert(e)}
              sx={{ mt: 1 }}
              severity={e.severity}
            >
              {e.message}
            </Alert>
          </Collapse>
        ))}
      </Hidden>
    </Box>
  );
};

export default VideoUploader;
