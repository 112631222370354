import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import api from "../../../conf/api";
import GenericTable from "../../../Components/Table/GenericTable";
import { Paper } from "@mui/material";
import LinearWithValueLabel from "../../../Components/Progress/LinearProgress";
import { toast } from "react-toastify";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function DubblicationDialogBox({
  open,
  setOpen,
  rows,
  setFiles,
  clearData,
  is_promo = false
}) {
  const [data, setData] = React.useState([]);
  const [progress, setProgress] = React.useState(0);
  const [inProcess, setProcess] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  function DublicatedRowsValidate(rows_indexes) {
    // const _columns = [
    //   "Asset ID",
    //   "Asset Label",
    //   "Asset Title",
    //   "Partner Revenue",
    // ];
    const rows_cp = rows;
    for (let index of rows_indexes) {
      rows_cp[index].dublicated = true;
    }
    setData(rows_cp);
  }

  React.useEffect(() => {
    const DublicationFinder = async () => {
      await api
        .post(is_promo ? "/earning/promo/dubblication" : "/earning/dubblication", rows)
        .then((res) => {
          DublicatedRowsValidate(res.data);
        })
        .catch((resp) => {
          console.log(resp);
        });
    };
    if (open && rows.length > 0) {
      DublicationFinder();
    }
    // eslint-disable-next-line
  }, [rows, open]);
  const handleRemoveRow = (row) => {
    const newArray = data.slice();
    const indexToRemove = newArray.findIndex(
      (r) => row["Asset ID"] === r["Asset ID"]
    );
    if (indexToRemove !== -1) {
      newArray.splice(indexToRemove, 1);
      setData(newArray);
    }
  };
  const DisplayTable = React.useCallback(
    () => (
      <GenericTable
        data={data}
        columns={[
          {
            id: "Asset Label",
            label: "Asset Label",
            sortable: false,
            sx: { flexGrow: 1 },
          },
          {
            id: "Asset ID",
            label: "Asset ID",
            sortable: false,
          },
          {
            id: "Asset Title",
            label: "Asset Title",
            sortable: false,
            sx: { flexGrow: 1 },
          },

          {
            id: "Partner Revenue",
            label: "Partner Revenue Label",
            sortable: false,
          },
          is_promo && {
            id: "Channel ID",
            label: "Channel ID",
            sortable: false,
          },
        ]}
        pagination={false}
        defaultOrderBy="Asset Title"
        defaultOrder="asc"
        defaultRowsPerPage={10}
        fetchData={() => { }}
        loading={false}
        onRowRemove={handleRemoveRow}
      />
    ),
    // eslint-disable-next-line
    [data]
  );
  const uploadRevenue = async () => {
    setProcess(true);
    try {
      const response = await api.post(is_promo ? "/earning/promo/upload/" : "/earning/upload/", data, {
        timeout: 50000,
        onUploadProgress: (progressEvent) => {
          const progress = (progressEvent.loaded / progressEvent.total) * 100;
          setProgress(progress);
        },
      });
      if (response.status === 201) {
        toast.success("File uploaded successfuly");
        setOpen(false);
        setData([]);
        setFiles([]);
        clearData([]);
      }
    } catch (error) {
      console.log(error);
      toast.error("Error while uploading the video");

      const errorData = error.response;
      console.log(errorData);
    } finally {
      setProcess(false);
      setProgress(0);
    }
  };
  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      maxWidth="xl"
      fullWidth
      onClose={handleClose}
    >
      <DialogTitle>{"Dublications"}</DialogTitle>
      <DialogContent>
        <DisplayTable />
      </DialogContent>
      {inProcess ? (
        <DialogActions>
          <Paper sx={{ borderRadius: 4, width: "100%" }}>
            {progress !== 0 && <LinearWithValueLabel progress={progress} />}
          </Paper>
        </DialogActions>
      ) : (
        <DialogActions>
          <Button onClick={handleClose}>Disagree</Button>
          <Button onClick={uploadRevenue} variant="contained">
            Upload
          </Button>
        </DialogActions>
      )}
    </Dialog>
  );
}
