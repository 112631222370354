import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { Box, Divider, TextField, Typography } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import api from "../../../conf/api";
import { toast } from "react-toastify";
import HowToRegIcon from "@mui/icons-material/HowToReg";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function PaymentDialog({
  open,
  setOpen,
  selectedRows,
  refreshFunc,
  setSelection,
  is_promo = false
}) {
  const handleClose = () => {
    setOpen(false);
    setFields({});
  };
  const initialFields = {
    description: "",
    transaction_id: "",
    ids: [],
  };
  const [fields, setFields] = React.useState({});

  async function AprovePayment() {
    const url = is_promo ? "/earning/promo/payPayment" : "/earning/payPayment";
    await api
      .post(url, fields)
      .then((res) => {
        if (res.status === 202) {
          toast.success("Updated successfuly");
          setFields(initialFields);
          setSelection([]);
          refreshFunc();
          setOpen(false);
        }
        if (res.status === 304) {
          toast.warning(res.data);
        }
      })
      .catch((res) => {
        toast.error(res.response.data);
      });
  }
  React.useEffect(() => {
    const groupedData = selectedRows.reduce((acc, item) => {
      const username = item.username;
      if (!acc[username]) {
        acc[username] = [];
      }
      acc[username].push(item);
      return acc;
      // eslint-disable-next-line
    }, {});
    let fields_object = {};
    Object.entries(groupedData).map(([key, value]) => {
      fields_object[key] = { ...initialFields, ids: value.map((v) => v.id) };
      return "";
    });

    setFields(fields_object);
    // eslint-disable-next-line
  }, [selectedRows]);

  const handleField = (key) => (e) => {
    setFields((pre) => ({
      ...pre,
      [key]: { ...pre[key], [e.target.name]: [e.target.value] },
    }));
  };
  let count = 0;
  selectedRows.map((r) => (count += r.amount));
  return (
    <Dialog open={open} TransitionComponent={Transition} onClose={handleClose}>
      <DialogTitle>{"Payments Approval"}</DialogTitle>
      <DialogContent>
        <DialogContentText sx={{ overflow: "hidden" }}>
          You are going to pay for the following user
          <Typography fontWeight={600}>
            Total Revenue: ($<strong>{count}</strong>)
          </Typography>
        </DialogContentText>
        <Divider sx={{ m: 1 }} />
        {Object.entries(fields).map(([key, value], index) => {
          return (
            <Grid2
              sx={{ minWidth: 500 }}
              key={key}
              direction="column"
              component="form"
              container
              spacing={2}
              mt={1}
            >
              <Grid2>
                <Box display="flex" alignItems="center" gap={2}>
                  <HowToRegIcon />
                  <Typography variant="h6" fontWeight="bold">
                    {key}
                  </Typography>
                </Box>
              </Grid2>
              <Grid2>
                <TextField
                  size="small"
                  onChange={handleField(key)}
                  name="transaction_id"
                  value={value.transaction_id}
                  fullWidth
                  placeholder="Enter transaction id"
                />
              </Grid2>
              <Grid2>
                <TextField
                  multiline
                  onChange={handleField(key)}
                  name="description"
                  value={value.description}
                  fullWidth
                  rows={4}
                  size="small"
                  placeholder="Enter note"
                />
              </Grid2>
            </Grid2>
          );
        })}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Disagree</Button>
        <Button onClick={AprovePayment} variant="contained" color="primary">
          Aprove
        </Button>
      </DialogActions>
    </Dialog>
  );
}
