import {
  Box,
  ButtonBase,
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  InputBase,
  Paper,
  Typography,
} from "@mui/material";
import React, { useRef, useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import api from "../../../conf/api";
import ClearIcon from '@mui/icons-material/Clear';
import VideoCard from "../components/VideoCard";
import SearchSharpIcon from '@mui/icons-material/SearchSharp';
import InfoCard from "../components/InfoCard";


function VideoSelection({ selectedVideos = [], handleAddVideo, RemoveVideo, channels }) {
  const [videos, setVideos] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedChannel, setSelectedChannel] = useState(channels[0].title);

  const searchRef = useRef(null);

  const fetchVideo = async (text) => {
    try {
      setLoading(true);
      const response = await api.get("/youtube/video/finder", {
        params: { search: text },
        timeout: 500000,
      });
      setVideos(response.data);
      searchRef.current.value = "";
    } catch (error) {
      console.error("Error fetching videos:", error);
    } finally {
      setLoading(false);
    }

  };

  const handleSearch = () => {
    const searchText = searchRef.current.value;
    fetchVideo(searchText);
  };
  const handleAdd = (obj) => () => {
    handleAddVideo(obj, selectedChannel);
    setVideos((pre) => pre.filter((v) => v.unique_key !== obj.unique_key));
  };
  const handleChannelChange = (_ch) => () => {
    setSelectedChannel(_ch.title)
  }

  return (
    <Box>
      <Grid container spacing={2} justifyContent="space-between" marginBottom={5}>
        <Grid item xs={12}>
          <Box display="flex">
            <Typography variant="h6">Selected Channels</Typography>
          </Box>
        </Grid>
        <Grid container spacing={2} item xs={12}>
          {channels.map(ch => (
            <Grid item component={ButtonBase} onClick={handleChannelChange(ch)} key={ch.title}>
              <InfoCard data={ch} disbaleSelection selected={selectedChannel === ch.title}>
                <Divider flexItem variant="inset" sx={{ mt: 1 }} />
                <Typography variant="subtitle2" mt={1}>
                  Channel ID: <strong>{ch.channels}</strong>
                </Typography>
                <Box display="flex" gap={2}>
                  <Typography variant="subtitle2">Category: <strong>{ch.category?.name}</strong></Typography>
                  <Typography variant="subtitle2">Languages: <strong>{ch.language.name}</strong></Typography>
                </Box>
              </InfoCard>
            </Grid>
          ))}
        </Grid>
      </Grid>
      <Paper
        component={InputBase}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            handleSearch();
          }
        }}
        inputRef={searchRef}
        endAdornment={
          <InputAdornment position="end">
            <IconButton onClick={() => { searchRef.current.value = "" }}>
              <ClearIcon fontSize="small" />
            </IconButton>
            <IconButton onClick={handleSearch}>
              <SearchIcon />
            </IconButton>
          </InputAdornment>
        }
        placeholder="Search Video"
        sx={{ minWidth: 600, height: 50, padding: 2, borderRadius: 4, bgcolor: "transparent" }}
      />
      <Grid container spacing={2} mt={3}>
        <Grid xs={12}>
          <Box display="flex" gap={1} sx={{ width: "fit-content" }}>
            <SearchSharpIcon fontSize="large" />
            <Typography variant="h5" sx={{ borderBottom: "1px solid grey", }}>Find Videos</Typography>
          </Box>
        </Grid>
        {selectedVideos[selectedChannel] && selectedVideos[selectedChannel].length > 0 && (
          selectedVideos[selectedChannel].map((v) => (
            <Grid item xs={6} md={4} key={v.unique_key}>
              <VideoCard video={v} _component={Paper} onDelete={() => RemoveVideo(v, selectedChannel)} sx={{ bgcolor: "white", height: "100%" }} />
            </Grid>
          ))
        )}
        {loading ? (
          <Grid item xs={12}>
            <CircularProgress size="large" />
          </Grid>
        ) : (
          videos.map((video) => (
            <Grid item xs={6} md={4} key={video.unique_key}>
              <VideoCard video={video} handleAdd={handleAdd} sx={{ height: "100%" }} />
            </Grid>
          ))
        )}
        {selectedVideos[selectedChannel] && selectedVideos[selectedChannel].length < 1 && !loading && videos.length < 1 && (
          <Grid item xs={12} textAlign="center">
            <Box
              component="img"
              src="https://www.indiarefurb.com/assets/img/no-result-found.png"
              alt="img"
            />
          </Grid>
        )}
        <img alt="" src="" />
      </Grid>
    </Box>
  );
}

export default VideoSelection;
