import RevenueByAssets from "./Pages/Earnings/RevenueByAssets";
import RevenueUpload from "./Pages/Earnings/RevenueUpload";
import PromoRevenueUpload from "./Pages/Earnings/PromoRevenueUpload";
import StaffList from "./Pages/StaffManagement/StaffList";
import InformationPannel from "./Pages/User Pannel/Dash Board/InformationPannel";
import AssetTable from "./Pages/User Pannel/Ingested Assets/AssetSheet";
import VideoUploader from "./Pages/VideoManagement/Upload Videos/UploadVideo";
import WhiteListing from "./Pages/User Pannel/White Listing/WhiteListing";
import VideoPreview from "./Pages/VideoManagement/VideoPreview";
import UsersEarnings from "./Pages/Earnings/UsersEarnings.js";
import Index from "./Pages/YoutubeManagement/Index";
import VideosPreview from "./Pages/YoutubeManagement/VideosPreview";
import PromoChannels from "./Pages/Promo/PromoChannels";
import PromoCompilations from "./Pages/Promo/PromoCompilations";
import CompilationVideos from "./Pages/Promo/CompilationAssets.js";
import TermsofServices from "./Pages/User Pannel/Terms/TermsofServices.js";
import PromoTermsofServices from "./Pages/User Pannel/Terms/PromoTermsofServices.js";
import Dashboard from "./Pages/Promo/Dashboard.js";
import PromoEarnings from "./Pages/Earnings/PromoEarnings.js";
import RevenueByChannel from "./Pages/Earnings/RevenueByChannel.js";
import Library from "./Pages/VideoManagement/Video Library/Library.js";
import LabTabs from "./Pages/YoutubeManagement/mediaScope/mediaScope.js";
import ChannelsPreview from "./Pages/YoutubeManagement/ChannelsPreview.js";


export const admin_urls_pattrens = [
  { url: "/", exact: false, element: <InformationPannel /> },
  { url: "/videos", exact: false, element: <VideoPreview /> },
  { url: "/uploads", exact: false, element: <VideoUploader /> },
  { url: "/video/library", exact: false, element: <Library /> },
  { url: "/whitelisting", exact: false, element: <WhiteListing /> },
  { url: "/assets", exact: false, element: <AssetTable /> },
  { url: "/staff", exact: false, element: <StaffList /> },
  { url: "/revenue", exact: false, element: <RevenueUpload /> },
  { url: "/promo_revenue", exact: false, element: <PromoRevenueUpload /> },
  { url: "/earning/assets", exact: false, element: <UsersEarnings /> },
  { url: "/earning/assets/:id", exact: false, element: <RevenueByAssets /> },
  { url: "/promo_earning/assets", exact: false, element: <PromoEarnings /> },
  { url: "/earning/promo/:id", exact: false, element: <RevenueByChannel /> },
  { url: "/youtube", exact: false, element: <Index /> },
  { url: "/youtube/channels", exact: false, element: <ChannelsPreview /> },
  { url: "/promo/channels", exact: false, element: <PromoChannels /> },
  { url: "/promo/compilations", exact: false, element: <PromoCompilations /> },
  { url: "/promo/videos/:id", exact: false, element: <CompilationVideos /> },
  { url: "/youtube/list", exact: false, element: <VideosPreview /> },
  { url: "/terms", exact: false, element: <TermsofServices /> },
  { url: "/promo_terms", exact: false, element: <PromoTermsofServices /> },
  { url: "/mediaScope", exact: false, element: <LabTabs /> }
];
export const creator_urls_pattrens = [
  { url: "/", exact: false, element: <InformationPannel /> },
  { url: "/videos", exact: false, element: <VideoPreview /> },
  { url: "/uploads", exact: false, element: <VideoUploader /> },
  { url: "/whitelisting", exact: false, element: <WhiteListing /> },
  { url: "/assets", exact: false, element: <AssetTable /> },
  { url: "/earning/assets", exact: false, element: <UsersEarnings /> },
  { url: "/earning/assets/:id", exact: false, element: <RevenueByAssets /> },
  { url: "/promo_earning/assets", exact: false, element: <PromoEarnings /> },
  { url: "/earning/promo/:id", exact: false, element: <RevenueByChannel /> },
  { url: "/terms", exact: false, element: <TermsofServices /> },
  { url: "/promo_terms", exact: false, element: <PromoTermsofServices /> },
  { url: "/youtube", exact: false, element: <Index /> },
  { url: "/youtube/channels", exact: false, element: <ChannelsPreview /> },
  { url: "/youtube/list", exact: false, element: <VideosPreview /> },
];
export const promo_urls_pattrens = [
  { url: "/", exact: false, element: <Dashboard /> },
  { url: "/video/library", exact: false, element: <Library /> },
  { url: "/promo/dashboard", exact: false, element: <Dashboard /> },
  { url: "/promo/channels", exact: false, element: <PromoChannels /> },
  { url: "/promo/compilations", exact: false, element: <PromoCompilations /> },
  { url: "/promo/videos", exact: false, element: <CompilationVideos /> },
];
