// ConfirmDeleteDialog.js
import React, { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import WarningIcon from '@mui/icons-material/Warning';
import DeleteForever from '@mui/icons-material/DeleteForever';
import { CircularProgress } from '@mui/material';
import api from '../../../../conf/api';
import { toast } from 'react-toastify';

const ConfirmDeleteYoutubeVideoDialog = ({ open, handleClose, row, reFreshFunc }) => {
    const [loading, setLoading] = useState(false);
    const handleConfirm = async () => {
        setLoading(true);
        api.post("/youtube/delete-youtube-video/", { video_id: row.youtube_video_id }, { timeout: (1000 * 60) * 2 }).then(res => {
            if (res.status === 200) {
                toast.success(res.data?.message);
                reFreshFunc();
                handleClose();
            }
        }).catch(error => {
            if (error.response?.data?.error) {
                toast.info(error.response?.data?.error)
            }
            else {
                toast.error("Something went's wrong");
            }
        }).finally(res => {
            setLoading(false)
        })
    }
    return (
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle sx={{ display: "flex", alignItems: "center", gap: 1 }}><WarningIcon color='warning' fontSize='large' />Confirm Deletion</DialogTitle>
            <DialogContent>
                <Typography>
                    {`Are you sure you want to delete the (${row.youtube_video_id}) video permanently from YouTube?`}
                </Typography>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} >
                    Cancel
                </Button>
                <Button disabled={loading} startIcon={loading ? <CircularProgress size={20} /> : <DeleteForever />} onClick={handleConfirm} color="error">
                    Delete
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ConfirmDeleteYoutubeVideoDialog;
