import React, { useCallback, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Autocomplete,
	Box,
	CircularProgress,
	Grid,
	TextField,
	Typography
} from "@mui/material";
import { toast } from "react-toastify";
import api from "../../../conf/api";
import TextInput from "../../../Components/Fields/TextInput.js";
import TimeDurationPicker from "../../../Components/Fields/TimeDurationPicker.js";
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import VideoCallIcon from '@mui/icons-material/VideoCall';

const debounce = (func, delay) => {
	let timeoutId;
	return function (...args) {
		clearTimeout(timeoutId);
		timeoutId = setTimeout(() => {
			func.apply(this, args);
		}, delay);
	};
}

function ControlledAccordions({
	index,
	videos,
	setVideos,
	expanded,
	handlePanelChange
}) {
	const [loading, setLoading] = useState(false);
	const [uniqueKeys, setUniqueKeys] = React.useState({
		options: [],
		loading: false,
	});

	async function fetchUniqueKeys(query, setFirst = false) {
		if (query.length > 3) {
			setUniqueKeys((prev) => ({ ...prev, loading: true }));
			await api
				.get("/promo/unique_keys", {
					params: { unique_key: query },
					timeout: 100 * 1000,
				})
				.then((res) => {
					setUniqueKeys((prev) => ({ ...prev, options: res.data }));
					if (setFirst && res.data.length > 0) {
						const _videos = [...videos];
						_videos[index].video_key = res.data[0];
						setVideos(_videos);
					}
				})
				.finally(() => {
					setUniqueKeys((prev) => ({ ...prev, loading: false }));
				});
		}
	}
	const fetchData = async (_inputValue, setFirst = true) => {
		setLoading(true);
		try {
			const response = await api(`/videos/colud/search/`, { params: { query: _inputValue } });
			const data = await response.data;
			if (setFirst && data.results?.length > 0) {
				const encodedPath = encodeURIComponent(data.results[0]?.path);
				const dropboxLink = `https://www.dropbox.com/home${encodedPath}`;
				handleDropboxlink(data.results[0]?.name, dropboxLink);
			}
			else if (setFirst) {
				const _videos = [...videos];
				_videos[index].dropbox_link = "";
				setVideos(_videos);
				toast.warning("Sorry no dropbox link availble.")
			}
		} catch (error) {
			console.error('Error fetching data:', error);
		} finally {
			setLoading(false);
		}
	};
	
  const debouncedFetchData = useCallback(
		debounce(
			(inputValue) => fetchData(inputValue),
			500
		),
		// eslint-disable-next-line
		[]
	);

	const handleDuration = (field_name) => (_value) => {
		setVideos(pre => {
			const _videos = [...pre];
			_videos[index] = { ..._videos[index], [field_name]: _value };
			return _videos;
		});
	}
	const handleDropboxlink = (title, link) => {
		setVideos(pre => {
			const _videos = [...pre];
			_videos[index] = { ..._videos[index], video_title: title, dropbox_link: link };
			return _videos;
		})
	}

	return (
		<Accordion
			expanded={expanded === index}
			onChange={(event, isExpanded) =>
				handlePanelChange(index, isExpanded)
			}
		>
			<AccordionSummary
				expandIcon={<ExpandMoreIcon />}
			>
				<Typography sx={{ width: '33%', flexShrink: 0 }}>
					Video {index + 1}
				</Typography>
				{/* <Typography sx={{ color: 'text.secondary' }}>I am an accordion</Typography> */}
			</AccordionSummary>
			<AccordionDetails>
				<Grid container sx={{ mb: 3 }} spacing={2} alignItems="center">
					<Grid item xs={6} md={6}>
						<Autocomplete
							disablePortal
							size="small"
							loading={loading}
							onInputChange={(event, newInputValue) => {
								fetchUniqueKeys(newInputValue);
							}}
							options={uniqueKeys.options}
							getOptionLabel={(option) => option?.unique_key || []}
							onChange={(event, newValue) => {
								newValue && debouncedFetchData(newValue.video_title);
								const _videos = [...videos];
								_videos[index].video_key = newValue;
								_videos[index].video_title = newValue ? newValue.video_title : "";
								_videos[index].dropbox_link = newValue ? _videos[index].dropbox_link : "";
								setVideos(_videos);
							}}
							value={videos[index].video_key}
							renderInput={(params) => (
								<TextField
									{...params}
									required
									label="WGID (video Key)"
									InputProps={{
										...params.InputProps,
										endAdornment: (
											<React.Fragment>
												{uniqueKeys.loading ? (
													<CircularProgress color="inherit" size={20} />
												) : null}
												{params.InputProps.endAdornment}
											</React.Fragment>
										),
									}}
								/>
							)}
						/>
					</Grid>
					<Grid item container xs={6} spacing={2}>
						<Grid item xs={6}>
							<TimeDurationPicker required label="From" value={videos[index].duration_from} onChange={handleDuration("duration_from")} />
						</Grid>
						<Grid item xs={6}>
							<TimeDurationPicker required label="To" value={videos[index].duration_to} onChange={handleDuration("duration_to")} />
						</Grid>
					</Grid>
					<Grid item xs={12}>
						<TextInput
							label="Video Title"
							fullWidth
							value={videos[index].video_title}
							name="video_title"
						/>
					</Grid>
					<Grid item xs={6}
						title={videos[index].dropbox_link}
						sx={{ cursor: "pointer" }}
					>
						<Box display="flex">
							{videos[index].dropbox_link ? (
								<CheckCircleIcon color="success" />
							) : (
								<CancelIcon color="error" sx={{ title: "Sameer" }} />
							)}
							<Typography marginLeft={1}> {videos[index].dropbox_link ? "Dropbox Link Exists" : "Dropbox Link Does Not Exist"} </Typography>
						</Box>
					</Grid>
				</Grid>
			</AccordionDetails>
		</Accordion>
	);
}

export default function AddCompilationAssetsBox({
	open,
	refreshFunc,
	selectedCompilation,
	onClose,
}) {
	const initialVideoState = {
		video_key: "",
		duration_from: "",
		duration_to: "",
		video_title: "",
		dropbox_link: "",
	};

	const [compilation, setCompilation] = useState({
		compilation: selectedCompilation || null,
		options: [],
		loading: false,
	});
	const [expandedPanel, setExpandedPanel] = useState(0);
	const [videos, setVideos] = useState([
		initialVideoState
	]);

	async function FecthCompilation() {
		setCompilation((pre) => ({ ...pre, loading: true }));
		await api
			.get("/promo/compilations", { params: { page: 1, page_size: 10000 } })
			.then((res) => {
				setCompilation((pre) => ({ ...pre, options: res.data?.results }));
			})
			.finally(() => {
				setCompilation((pre) => ({ ...pre, loading: false }));
			});
	}

	const TimeRangeFormater = (_time) => {
		const new_duration = new Date(_time);
		const minutes = new_duration.getMinutes() < 10 ? '0' + new_duration.getMinutes() : new_duration.getMinutes();
		const seconds = new_duration.getSeconds() < 10 ? '0' + new_duration.getSeconds() : new_duration.getSeconds();
		return `${minutes}:${seconds}`;
	}

	const handleSubmit = async (event) => {
		event.preventDefault();

		const _compilation = compilation.compilation;
		for (const video of videos) {
			if (video.duration_from >= video.duration_to
				|| video.duration_to - video.duration_from < 1000
				|| video.duration_to - video.duration_from > 25000) {
				toast.error("Time duration not valid, with a difference of 1 to 25 seconds.");
				return;
			}
			let sendData = {
				...video,
				video_key:
					typeof video.video_key === "object"
						? video.video_key.unique_key
						: video.video_key,
				compilation:
					typeof _compilation === "object"
						? _compilation.id
						: _compilation,
			};
			sendData["duration"] = `${TimeRangeFormater(sendData["duration_from"])}-${TimeRangeFormater(sendData["duration_to"])}`
			delete sendData["duration_from"]
			delete sendData["duration_to"]

			await api
				.post("/promo/compilation_videos/", sendData)
				.then((response) => {
					if (response.status === 201) {
						toast.success(response.data?.message);
						refreshFunc();
						onClose();
					}
				})
				.catch((error) => {
					const errorObject = error.response?.data;
					try {
						for (const field in errorObject) {
							if (errorObject?.hasOwnProperty(field)) {
								const errors = errorObject[field];
								const formattedFieldErrors = errors.map(
									(errorMessage) => `${field}:${errorMessage}`
								);
								formattedFieldErrors?.map((msg) => toast.error(msg));
							}
						}
					} catch {
						try {
							toast.error(JSON.stringify(errorObject));
						} catch {
							toast.error("Something went wrong");
						}
					}
				});
		}
	};
	const handleClose = () => {
		onClose();
	};

	return (
		<Dialog maxWidth="md" open={open} onClose={handleClose}>
			<DialogTitle>
				Create Compilation Video
			</DialogTitle>
			<DialogContent>
				<DialogContentText mb={2}>
					Please fill in the required information to add a new video in compilation.
				</DialogContentText>
				<form onSubmit={handleSubmit}>
					<Grid container sx={{ mb: 3 }} spacing={2} alignItems="center">
						<Grid item xs={12}>
							<Autocomplete
								disablePortal
								loading={compilation.loading}
								value={compilation.compilation}
								onOpen={FecthCompilation}
								getOptionLabel={(op) => op.video_title}
								size="small"
								fullWidth
								options={compilation.options}
								placeholder="Select Compilation"
								onChange={(event, newValue) => {
									setCompilation({ ...compilation, compilation: newValue });
								}}
								renderInput={(params) => (
									<TextField
										{...params}
										required
										label="Compilation"
										InputProps={{
											...params.InputProps,
											endAdornment: (
												<React.Fragment>
													{compilation.loading ? (
														<CircularProgress color="inherit" size={20} />
													) : null}
													{params.InputProps.endAdornment}
												</React.Fragment>
											),
										}}
									/>
								)}
							/>
						</Grid>
						<Grid item xs={12}>
							{videos.map((video, index) => (
								<ControlledAccordions
									index={index}
									videos={videos}
									setVideos={setVideos}
									expanded={expandedPanel}
									handlePanelChange={(panel, isExpanded) => {
										setExpandedPanel(isExpanded ? panel : false);
									}}
								/>
							))}
						</Grid>
						<Grid item xs={12}>
							<Button
								fullWidth
								variant="outlined"
								startIcon={<VideoCallIcon />}
								onClick={() => (
									setVideos([...videos, initialVideoState])
								)}
							>
								Add Compilation Asset
							</Button>
						</Grid>
					</Grid>
					<DialogActions>
						<Button onClick={handleClose}>Cancel</Button>
						<Button variant="contained" type="submit" color="primary">
							Add Video
						</Button>
					</DialogActions>
				</form>
			</DialogContent >
		</Dialog >
	);
}
