// ChannelsPreview.js

import React, { useState, useEffect, useCallback } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import api from '../../conf/api';
import { Box, Card, CardContent, Chip, CircularProgress, Grid, IconButton, Skeleton, Stack, Tooltip, Typography } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import DeleteConfirmationDialog from './components/DialogBoxes/DeleteConfirmationDialog';
import { toast } from 'react-toastify';
import ChannelFormDialog from './components/DialogBoxes/ChannelFormDialog';
import ChannelSchedulerBox from './components/DialogBoxes/ChannelSchedulerBox';
import ConfirmDeleteChannelSchedulerBox from './components/DialogBoxes/ConfirmDeleteChannelSchedulerBox';
import SettingsIcon from '@mui/icons-material/Settings';
import "./ChannelPreview.css"
import YouTubeIcon from '@mui/icons-material/YouTube';
import KeyIcon from '@mui/icons-material/Key';
import AuthDialog from './components/DialogBoxes/YoutubeAuthenticationDialog';
import { connect } from 'react-redux';
import Scrollbars from 'react-custom-scrollbars-2';
import SyncIcon from '@mui/icons-material/Sync';

const ChannelsPreview = (props) => {
    const [showAuthDialoge, setShowAuthDialog] = useState(false)
    const [channels, setChannels] = useState([]);
    const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
    const [showForm, setShowForm] = useState(false);
    const [selectedRow, setSelectedRow] = useState({});
    const [rowCount, setRowCount] = useState(0);
    const [isUpdate, setIsUpdate] = useState(false);
    const [channelSchedulerBox, setChannelSchedulerBox] = useState(false);
    const [deleteChannelSchedulerBox, setDeleteChannelSchedulerBox] = useState(false);
    const [channelStatus, setChannelsStatus] = useState({ loading: true, data: [] });
    const [paginationModel, setPaginationModel] = useState({
        pageSize: 10,
        page: 0,
    });

    const capitalizeFirstLetter = str => str.replace(/^\w/, c => c.toUpperCase());

    const columns = [
        {
            field: "action",
            headerName: "",
            width: 200,
            renderCell: (params) => {
                return (
                    <Box display="flex" justifyContent="space-between">
                        {!params.row.credentials && props.is_staff &&
                            <Tooltip title="Authenticate Youtube">
                                <IconButton size='small' onClick={() => { setSelectedRow(params.row); setShowAuthDialog(true); }}>
                                    <Box position="relative" >
                                        <YouTubeIcon sx={{ color: "error.light" }} />
                                        <KeyIcon sx={{ fontSize: 13, position: "absolute", top: 3, left: 2.5 }} />
                                    </Box>
                                </IconButton>
                            </Tooltip>
                        }
                        <Tooltip title="Edit Channel">
                            <IconButton onClick={handleEditRow(params.row)}>
                                <EditIcon color="primary" />
                            </IconButton>
                        </Tooltip>

                        <Tooltip title="Delete Channel">
                            <IconButton onClick={handleDeleteClick(params.row)} >
                                <DeleteIcon color="error" />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Scheduler">
                            <IconButton onClick={handleSchedulerBox(params.row)} >
                                <SettingsIcon color="success" />
                            </IconButton>
                        </Tooltip>
                    </Box>
                );
            },
        },
        {
            field: 'disabled',
            headerName: 'Disabled',
            type: 'boolean',
            renderCell: (params) => (
                params.value ?
                    <Tooltip title={params.row?.disabling_note}>
                        <span>✔️</span>
                    </Tooltip>
                    :
                    <span>❌</span>
            ),
        },
        { field: 'channel_name', headerName: 'Name', width: 200 },
        { field: 'channel_id', headerName: 'ID', width: 150 },
        { field: 'verified', headerName: 'Verified', type: "boolean" },
        {
            field: 'account', headerName: 'Account',
            width: 220,
            renderCell: (params) => {
                return <Tooltip title={params.value.email}>{params.value.account_name}</Tooltip>
            }
        },
        {
            field: 'category', headerName: 'Category', width: 150, renderCell: (params) => {
                return params.value.name
            }
        },
        {
            field: 'language', headerName: 'Language', width: 120, renderCell: (params) => {
                return params.value.name
            }
        },
        {
            field: 'videos_limit', headerName: 'Daily Limit', align: "center", renderCell: (params) => {
                return `${params.row?.remaining_videos_limit} / ${params.value}`
            }
        },
        { field: 'credentials', headerName: 'Credentials', width: 150, type: "boolean" },

        // Add other fields as needed
    ];

    const FechRows = async (page = paginationModel.page, pageSize = paginationModel.pageSize) => {
        api.get('/youtube/channels/view', {
            params: {
                page: page === 0 ? 1 : page,
                page_size: pageSize
            }
        })
            .then(response => {
                if (response.status === 200) {
                    const formattedChannels = response.data.results.map((channel, index) => ({
                        ...channel,
                        id: index + 1, // Assign a unique ID, you can customize this logic
                    }));
                    setRowCount(response.data?.count);
                    setChannels(formattedChannels);
                }
            })
            .catch(error => console.error('Error fetching channels:', error));
    }
    const FechChannelStatus = async (page = paginationModel.page, pageSize = paginationModel.pageSize) => {
        setChannelsStatus(pre => ({ ...pre, loading: true }));
        api.get('/youtube/channels/threadstatus/')
            .then(response => {
                if (response.status === 200) {
                    console.log(response.data)
                    setChannelsStatus(pre => ({ ...pre, data: response.data }));

                }
            })
            .catch(error => console.error('Error fetching channels:', error)).finally(e => {
                setChannelsStatus(pre => ({ ...pre, loading: false }));
            });
    };

    const ChannelStatusBox = () => {
        // Define colors for each status type
        const statusColors = {
            pending: 'warning.light',
            inqueue: 'primary.light',
            running: 'success.light',
            completed: 'secondary.light',
            failed: 'error.light',
        };

        return (
            <Grid container spacing={2} mb={2}>
                {channelStatus.loading && channelStatus.data?.length < 1 &&
                    [0, 1, 2, 3, 4, 5, 6, 7].map((index) => (
                        <Grid item key={index}>
                            <Skeleton variant="rounded" width={"100%"} height={157} />
                        </Grid>
                    ))
                }
                {channelStatus.data.map((status, index) => (
                    <Grid item xs={12} sm={6} md={4} xl={3} key={index}>
                        <Card sx={{ boxShadow: 1, borderRadius: 2.5 }}>
                            <CardContent>
                                <Typography align='center' variant="subtitle1" fontWeight="bold" component="div">
                                    {status.channel_name}
                                </Typography>
                                <Stack direction="row" gap={0.5} justifyContent="space-between" mt={.5}>
                                    {/* Left Column */}
                                    <Stack direction="column" gap={1}>
                                        {["pending_count", "inqueue_count", "running_count"].map(count => (
                                            <Box display="flex" gap={.5} justifyContent="space-between" key={count}>
                                                {/* Apply color conditionally based on the status */}
                                                <Typography
                                                    fontWeight={600}
                                                    variant='body1'
                                                    sx={{ color: statusColors[count.replace("_count", "")] }}
                                                >
                                                    {capitalizeFirstLetter(count.replace("_count", ""))}
                                                </Typography>
                                                {channelStatus.loading ? (
                                                    <CircularProgress size="1rem" />
                                                ) : (
                                                    `(${status[count]})`
                                                )}
                                            </Box>
                                        ))}
                                    </Stack>

                                    {/* Right Column */}
                                    <Stack direction="column" gap={1}>
                                        {["completed_count", "failed_count"].map(count => (
                                            <Box display="flex" gap={.5} justifyContent="space-between" key={count}>
                                                {/* Apply color conditionally based on the status */}
                                                <Typography
                                                    fontWeight={600}
                                                    variant='body1'
                                                    sx={{ color: statusColors[count.replace("_count", "")] }}
                                                >
                                                    {capitalizeFirstLetter(count.replace("_count", ""))}
                                                </Typography>
                                                {channelStatus.loading ? (
                                                    <CircularProgress size="1rem" />
                                                ) : (
                                                    `(${status[count]})`
                                                )}
                                            </Box>
                                        ))}
                                    </Stack>
                                </Stack>
                            </CardContent>
                        </Card>
                    </Grid>
                ))}
            </Grid>
        );
    };


    useEffect(() => {
        FechRows();
        // eslint-disable-next-line
    }, [paginationModel.page, paginationModel.pageSize]);

    useEffect(() => {
        FechChannelStatus();
    }, [])

    const ChannelFormDialogBox = useCallback(
        () => (
            <ChannelFormDialog
                open={showForm}
                refrashFunc={() => { FechRows(0) }}
                handleClose={() => setShowForm(false)}
                rowSelected={selectedRow}
                is_update={Object.keys(selectedRow).length > 0}
            />
        ),
        // eslint-disable-next-line
        [selectedRow, showForm]
    );
    const ChannelSchedulerDialog = useCallback(
        () => (
            <ChannelSchedulerBox
                open={channelSchedulerBox}
                selectedRow={selectedRow}
                is_update={isUpdate}
                onClose={() => {
                    setIsUpdate(false);
                    setSelectedRow({});
                    setChannelSchedulerBox(false);
                }}
            />
        ),
        // eslint-disable-next-line
        [channelSchedulerBox, isUpdate, selectedRow]
    );
    const DeleteChannelSchedulerDialog = useCallback(
        () => (
            <ConfirmDeleteChannelSchedulerBox
                open={deleteChannelSchedulerBox}
                setOpen={setDeleteChannelSchedulerBox}
                selectedRow={selectedRow}
                refreshFunc={() => { FechRows(1) }}
            />
        ),
        // eslint-disable-next-line
        [deleteChannelSchedulerBox, selectedRow]
    );

    const handleDeleteConfirmed = (_row) => {
        api.delete(`/youtube/channels/view/${selectedRow.channel_name}/`)
            .then(response => {
                if (response.status === 204) {
                    toast.info(`Channel with ID ${selectedRow.channel_name} deleted`);
                    setSelectedRow({});
                    setDeleteConfirmationOpen(false);
                    FechRows();
                }
            })
            .catch(error => {
                console.error('Error deleting channel:', error);
                // Handle error, display message to user, etc.
            });
    };
    const handleSchedulerBox = (_row) => () => {
        setSelectedRow(_row);
        setChannelSchedulerBox(true);
    }
    const handleEditRow = (_row) => () => {
        setSelectedRow(_row);
        setShowForm(true);
    }
    const handleDeleteClick = (_row) => () => {
        setDeleteConfirmationOpen(true);
        setSelectedRow(_row);
    };
    const handleDeleteConfirmationClose = () => {
        setDeleteConfirmationOpen(false);
    };
    const handleEditClick = () => {
        setSelectedRow({});
        setShowForm(true);
    }
    const getRowClassName = (params) => {
        return params.row.disabled ? 'disabled-row' : '';
    };
    const AuthenticationDialoge = useCallback(() => {
        return <AuthDialog selectedRow={selectedRow} open={showAuthDialoge} handleClose={() => {
            setShowAuthDialog(false);
        }} />
    }, [selectedRow, showAuthDialoge]);


    return (
        <Box>
            <Box display="flex" alignItems={"center"} justifyContent="space-between" gap={1} mb={3}>
                <Box display="flex" alignItems={"center"} gap={1} >
                    <Typography variant='h6'>Channels</Typography>
                    <IconButton onClick={handleEditClick}>
                        <AddCircleIcon color="success" />
                    </IconButton>
                </Box>
                <Tooltip title="Refresh">
                    <IconButton onClick={() => { FechRows(); FechChannelStatus(); }}>
                        <SyncIcon color="info" />
                    </IconButton>
                </Tooltip>
            </Box>
            <ChannelStatusBox />
            <Box sx={{ width: '100%' }}>
                <Scrollbars style={{ height: "50vh", width: "100%" }}>
                    <DataGrid
                        rowCount={rowCount}
                        rows={channels}
                        columns={columns}
                        rowSelection={false}
                        pageSize={5}
                        pageSizeOptions={[10, 30, 50]}
                        disableSelectionOnClick
                        rowHeight={38}
                        paginationModel={paginationModel}
                        onPaginationModelChange={setPaginationModel}
                        getRowClassName={getRowClassName}
                    />
                </Scrollbars>
                <DeleteConfirmationDialog
                    open={deleteConfirmationOpen}
                    handleClose={handleDeleteConfirmationClose}
                    handleDeleteConfirmed={handleDeleteConfirmed}
                />
                <ChannelFormDialogBox />
            </Box>
            {/* Add New Channel Scheduler */}
            < ChannelSchedulerDialog />
            {/* Confirm Delete Channel Scheduler */}
            < DeleteChannelSchedulerDialog />
            {/* Yt Auth Dialoge box */}
            <AuthenticationDialoge />
        </Box >
    );
};
const mapStatesToProps = (state) => {
    console.log(state)
    return { is_staff: state.user?.is_staff }
}
export default connect(mapStatesToProps, null)(ChannelsPreview);
