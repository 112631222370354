import React, { useCallback, useEffect, useState } from "react";
import { Box, IconButton, Tooltip, Typography } from "@mui/material";
import dayjs from "dayjs";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import DataTable from "./components/DataTable.js";
import api from "../../conf/api.js";
import PaidIcon from "@mui/icons-material/Paid";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import QuickSelector from "./components/QuickSelector.js";
import PaymentDialog from "./components/PaymentDialog.js";
import ApprovalDialogBox from "./components/ApprovalDialogBox.js";
import EditIcon from "@mui/icons-material/Edit";
import EditEarningDialog from "./components/EditEarningDialog.js";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";

function RevenueByAssets(props) {
  const { id } = useParams();
  const initialFilterField = {
    from_date: dayjs().subtract(7, "days"),
    to_date: dayjs(),
    paid_only: "",
    approval: "",
    user: "",
  };
  const [filters, setFilters] = React.useState(initialFilterField);
  // const [users, setUsers] = useState([]);
  const [selectedObject, setSelectedObj] = React.useState({});
  const [selectedRows, setSelectedRow] = React.useState([]);
  const [paymentDialog, openPaymentDialog] = React.useState(false);
  const [approveDialog, openApproveDialog] = React.useState(false);
  const [earningDialog, openEarningDialog] = React.useState(false);
  const [data, setData] = useState([]);
  // const navigation = useNavigate();

  const handleDateFilter = (_fields) => (newValue) => {
    setFilters((pre) => ({ ...pre, [_fields]: newValue }));
  };
  const handleAprroveVideos = (rows) => {
    setSelectedRow(rows);
    openApproveDialog(true);
  };
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const fetchData = async () => {
    try {
      const formattedStartDate = filters.from_date.format("YYYY-MM-DD");
      const formattedEndDate = filters.to_date.format("YYYY-MM-DD");
      const params = {
        page: page + 1,
        page_size: rowsPerPage,
        start_date: formattedStartDate,
        end_date: formattedEndDate,
      };
      const paid_only = filters.paid_only;
      if (paid_only !== "") {
        params["paid_only"] = paid_only;
      }
      const approval = filters.approval;
      if (approval !== "") {
        params["approval"] = approval;
      }

      params["user"] = id;

      const response = await api(`/earning/list`, { params: params });
      const result = response;
      if (response.status === 200) {
        setData(result.data);
        console.log(result.data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const EarningDialogBox = useCallback(
    () => (
      <EditEarningDialog
        open={earningDialog && selectedObject}
        setOpen={EditEarningDialog}
        row={selectedObject}
        refrashFunc={fetchData}
        onClose={() => openEarningDialog(false)}
      />
    ),
    // eslint-disable-next-line
    [earningDialog, selectedObject]
  );
  const columns = [
    props.is_admin && { id: "username", label: "User", disablePadding: false },
    { id: "asset_id", label: "Asset ID", disablePadding: false },
    {
      id: "description",
      label: "Note",
      disablePadding: false,
      sx: { maxWidth: 200 },
    },
    {
      id: "created_date",
      label: "Created at",
      disablePadding: false,
      valueFormate: (params) =>
        dayjs(params.value).format("YYYY-MM-DD hh:mm A"),
    },
    {
      id: "paid",
      label: "Status",
      align: "center",
      valueFormate: (value) => {
        return value ? (
          <Tooltip title="Paid">
            <PaidIcon color="success" />
          </Tooltip>
        ) : (
          <Tooltip title="Unpaid">
            <PaidIcon color="disabled" />
          </Tooltip>
        );
      },
    },
    props.is_admin && {
      id: "approval",
      label: "Approved",
      align: "center",
      valueFormate: (value) => {
        return value ? (
          <Tooltip title="Approved">
            <CheckBoxIcon color="success" />
          </Tooltip>
        ) : (
          <Tooltip title="Unapproved">
            <CheckBoxOutlineBlankIcon color="disabled" />
          </Tooltip>
        );
      },
    },
    { id: "amount", label: "Revenue", disablePadding: true, numeric: true },
    {
      id: "wooglobe_revenue",
      label: "Wooglobe Revenue",
      disablePadding: true,
      numeric: true,
    },
    {
      id: "partner_revenue",
      label: "User Revenue",
      disablePadding: true,
      numeric: true,
    },
  ];

  useEffect(() => {
    if (filters.from_date && filters.to_date) {
      fetchData();
    }
    // eslint-disable-next-line
  }, [
    filters.to_date,
    filters.from_date,
    filters.paid_only,
    filters.approval,
    filters.user,
    page,
    rowsPerPage,
  ]);

  // useEffect(() => {
  //   const fetchUsers = async () => {
  //     try {
  //       const response = await api("/users/list", {
  //         params: { page: 1, page_size: 50000 },
  //       }); // Adjust the API endpoint accordingly
  //       if (response.status === 200) {
  //         setUsers(response.data.results);
  //       }
  //     } catch (error) {
  //       console.error("Error fetching users:", error);
  //     }
  //   };
  //   if (!id) {
  //     navigation(-1);
  //   }
  //   fetchUsers();
  // }, []);

  const DisplayTable = useCallback(
    () => (
      <DataTable
        title={"Earnings"}
        is_admin={props.is_Admin}
        headCells={columns}
        tableRows={data}
        page={page}
        setPage={setPage}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        handleAprroveVideos={handleAprroveVideos}
        handlePayClick={handlePayClick}
        ActionRow={({ row }) => {
          if (props.is_admin) {
            return (
              <Tooltip title={row.approval ? "Approved can not edit" : "Edit"}>
                <IconButton
                  disabled={row.paid}
                  size="small"
                  onClick={() => {
                    setSelectedObj(row);
                    openEarningDialog(true);
                  }}
                >
                  <EditIcon
                    color={row.paid ? "grey" : "primary"}
                    fontSize="small"
                  />
                </IconButton>
              </Tooltip>
            );
          }
        }}
      />
    ),
    // eslint-disable-next-line
    [data, selectedRows]
  );

  const ApprovalDialog = useCallback(() => {
    return (
      <ApprovalDialogBox
        open={approveDialog}
        setOpen={openApproveDialog}
        rows={selectedRows}
        refreshFunc={fetchData}
      />
    );
    // eslint-disable-next-line
  }, [approveDialog, selectedRows]);

  const handlePayClick = (rows) => {
    openPaymentDialog(true);
    setSelectedRow(rows);
  };
  return (
    <Box display="flex" flexDirection="column" gap={2}>
      <Typography variant="h3">Earning By Assets</Typography>

      <Box
        display={"flex"}
        gap={2}
        alignItems={"center"}
        justifyContent={"space-between"}
      >
        <Box
          display="flex"
          gap={2}
          sx={{
            "& input": {
              height: 10,
            },
          }}
        >
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label="Controlled picker"
              value={filters.from_date}
              onChange={handleDateFilter("from_date")}
            />
          </LocalizationProvider>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label="Controlled picker"
              value={filters.to_date}
              onChange={handleDateFilter("to_date")}
            />
          </LocalizationProvider>
        </Box>
        <Box mt={-2.5} display="flex" gap={1}>
          {props.is_admin && (
            <Box>
              <Typography component="div" variant="caption" color="grey">
                Approved / Unapproved
              </Typography>
              <QuickSelector
                value={filters.approval}
                setValue={(value) => {
                  setFilters((pre) => ({ ...pre, approval: value }));
                }}
                options={[
                  {
                    label: "Approved",
                    value: true,
                    Icon: <CheckBoxIcon sx={{ mr: 1 }} color="success" />,
                  },
                  {
                    label: "Unapproved",
                    value: false,
                    Icon: (
                      <CheckBoxOutlineBlankIcon
                        sx={{ mr: 1 }}
                        color="disabled"
                      />
                    ),
                  },
                ]}
              />
            </Box>
          )}

          <Box>
            <Typography component="div" variant="caption" color="grey">
              Paid / Unpaid
            </Typography>
            <QuickSelector
              value={filters.paid_only}
              setValue={(value) => {
                setFilters((pre) => ({ ...pre, paid_only: value }));
              }}
              options={[
                {
                  label: "Paid",
                  value: true,
                  Icon: <PaidIcon sx={{ mr: 1 }} color="success" />,
                },
                {
                  label: "UnPaid",
                  value: false,
                  Icon: <PaidIcon sx={{ mr: 1 }} color="disabled" />,
                },
              ]}
            />
          </Box>
        </Box>
      </Box>
      <DisplayTable />
      {/* Payment Dilaog */}
      <PaymentDialog
        selectedRows={selectedRows}
        open={paymentDialog}
        setOpen={openPaymentDialog}
        refreshFunc={fetchData}
        setSelection={setSelectedRow}
      />
      <EarningDialogBox />
      <ApprovalDialog />
    </Box>
  );
}
const mapStatesToProps = (state) => {
  return {
    is_admin: state.user?.is_staff,
  };
};
export default connect(mapStatesToProps, null)(RevenueByAssets);
