import React, { useEffect, useState } from 'react';
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Autocomplete, Checkbox, CircularProgress, FormControlLabel, FormGroup, FormLabel, Grid } from "@mui/material";
import { toast } from "react-toastify";
import TextInput from "../../../../Components/Fields/TextInput.js"
import api from "../../../../conf/api";

export default function AccountForm({
    open,
    is_update,
    selectedRow,
    refreshFunc,
    onClose
}) {
    let initialFormData = {
        account_name: "",
        email: "",
        disabled: false,
        disabling_note: "",
        proxy: selectedRow?.proxy || ""
    };

    const [proxies, setProxies] = useState({ loading: false, proxy: null, options: [] });
    const [formData, setFormData] = useState(initialFormData);

    async function fetchProxies() {
        setProxies((pre) => ({ ...pre, loading: true }));
        await api
            .get("/proxies/")
            .then((res) => {
                setProxies((pre) => ({ ...pre, options: res.data }));
            })
            .finally(() => {
                setProxies((pre) => ({ ...pre, loading: false }));
            });
    }

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleClose = () => {
        onClose();
    };

    const handleSubmit = async (event) => {
        console.log(formData);
        event.preventDefault();
        if (is_update) {
            const updateAccount = async () => {
                await api
                    .patch(`/youtube/accounts/${selectedRow.account_name}/`, formData)
                    .then((res) => {
                        if (res.status === 200) {
                            toast.success("Language updated successfully.");
                            refreshFunc();
                            setFormData(formData);
                            onClose();
                        }
                    })
                    .catch((error) => {
                        const errorObject = error.response?.data;
                        try {
                            for (const field in errorObject) {
                                if (errorObject?.hasOwnProperty(field)) {
                                    const errors = errorObject[field];
                                    const formattedFieldErrors = errors.map(
                                        (errorMessage) => `${field}:${errorMessage}`
                                    );
                                    formattedFieldErrors?.map((msg) => toast.error(msg));
                                }
                            }
                        } catch {
                            try {
                                toast.error(JSON.stringify(errorObject));
                            } catch {
                                toast.error("Something went wrong");
                            }
                        }
                    });
            };
            updateAccount();
        }
        else {
            const data = formData;
            await api
                .post("/youtube/accounts/", data)
                .then((response) => {
                    if (response.status === 201) {
                        toast.success(response.data?.message);
                        refreshFunc();
                        onClose();
                    }
                })
                .catch((error) => {
                    const errorObject = error.response?.data;
                    try {
                        for (const field in errorObject) {
                            if (errorObject?.hasOwnProperty(field)) {
                                const errors = errorObject[field];
                                const formattedFieldErrors = errors.map(
                                    (errorMessage) => `${field}:${errorMessage}`
                                );
                                formattedFieldErrors?.map((msg) => toast.error(msg));
                            }
                        }
                    } catch {
                        try {
                            toast.error(JSON.stringify(errorObject));
                        } catch {
                            toast.error("Something went wrong");
                        }
                    }
                });
        }
    };

    useEffect(() => {
        async function fetchData() {
            await api
                .get("/youtube/accounts/" + selectedRow.account_name)
                .then((res) => {
                    const _data = res.data;
                    setFormData(_data);
                })
                .catch((err) => {
                    console.log(err);
                });
        }
        async function fetchProxy() {
            await api
                .get("/proxies/" + selectedRow.proxy)
                .then((res) => {
                    const _data = res.data;
                    setProxies((pre) => ({ ...pre, proxy: _data }));
                })
                .catch((err) => {
                    console.log(err);
                });
        }
        if (is_update) {
            fetchData();
            if (selectedRow.proxy) {
                fetchProxy();
            }
        }
        // eslint-disable-next-line
    }, []);

    return (
        <Dialog maxWidth="md" open={open} onClose={handleClose}>
            <DialogTitle>{is_update ? "Update" : "Add"} Account</DialogTitle>
            <DialogContent>
                <DialogContentText mb={2}>
                    Please fill in the required information to{" "}
                    {is_update ? "update" : "add"} a new account.
                </DialogContentText>
                <form onSubmit={handleSubmit}>
                    <Grid container marginBottom={3} spacing={3} alignItems="center">
                        <Grid item xs={12}>
                            <TextInput
                                label="Title"
                                type="text"
                                fullWidth
                                required
                                name="account_name"
                                value={formData.account_name || ""}
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextInput
                                label="Email"
                                type="email"
                                fullWidth
                                required
                                name="email"
                                value={formData.email || ""}
                                onChange={handleChange}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <Autocomplete
                                loading={proxies.loading}
                                onOpen={fetchProxies}
                                getOptionLabel={(op) => op.ip_address + ":" + op.port}
                                size="small"
                                fullWidth
                                options={proxies.options}
                                placeholder="Select Proxy"
                                value={proxies.proxy}
                                onChange={(e, newValue) => {
                                    setProxies((pre) => ({ ...pre, proxy: newValue }))
                                    setFormData((pre) => ({ ...pre, proxy: newValue?.id }));
                                }}
                                renderInput={(params) => (
                                    <TextInput
                                        {...params}
                                        label="Proxies"
                                        InputProps={{
                                            ...params.InputProps,
                                            endAdornment: (
                                                <React.Fragment>
                                                    {proxies.loading ? (
                                                        <CircularProgress color="inherit" size={20} />
                                                    ) : null}
                                                    {params.InputProps.endAdornment}
                                                </React.Fragment>
                                            ),
                                        }}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={formData.disabled} />} onChange={(e) => {
                                    setFormData({
                                        ...formData,
                                        disabled: e.target.checked,
                                        disabling_note: "",
                                    });
                                }} label="Required" />
                            </FormGroup>
                        </Grid>
                        <Grid item xs={12}>
                            <TextInput
                                label="Disabling note"
                                fullWidth
                                required={formData.disabled}
                                name="disabling_note"
                                value={formData.disabling_note || ""}
                                onChange={handleChange}
                            />
                        </Grid>
                    </Grid>
                    <DialogActions>
                        <Button onClick={handleClose}>Cancel</Button>
                        <Button variant="contained" type="submit" color="primary">
                            {is_update ? "Update" : "Add"} Account
                        </Button>
                    </DialogActions>
                </form>
            </DialogContent>
        </Dialog>
    );
}
