import React, { useState } from 'react';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button, CircularProgress, Typography, Box } from '@mui/material';
import { useDropzone } from 'react-dropzone';
import DoNotDisturbOnIcon from '@mui/icons-material/DoNotDisturbOn';
import api from '../../../../conf/api';
import { toast } from 'react-toastify';

const AuthDialog = ({ open, handleClose, selectedRow }) => {
    const [file, setFile] = useState(null);
    const [loading, setLoading] = useState(false);

    const onDrop = (acceptedFiles) => {
        setFile(acceptedFiles[0]);
    };

    const { getRootProps, getInputProps } = useDropzone({
        onDrop,
        accept: {
            'text/html': ['.json'],
        }
    });

    const handleSubmit = async () => {
        if (!file) return;

        setLoading(true);
        const formData = new FormData();
        formData.append('file', file);
        formData.append('channel_name', selectedRow.channel_name);
        formData.append('page_redirect', window.location.href);

        try {
            const response = await api.post('/auth/get-redirect-uri/', formData);
            const redirectUri = response.data.redirect_uri;
            window.location.href = redirectUri;
        } catch (error) {
            if (error.response && error.response.data) {
                const errorMessage = error.response.data;
                toast.error(errorMessage, {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 5000,
                });
            } else {
                toast.error('An unexpected error occurred', {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 5000,
                });
            }
            setLoading(false);
        }
    };

    return (
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle>Authenticate with YouTube</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Please upload your JSON file to authenticate with YouTube.
                </DialogContentText>
                <Box display="flex" alignItems="center" justifyContent="space-between">
                    <Typography variant='h6' sx={{ textDecoration: "underline" }}>{selectedRow.channel_name}</Typography>
                    <Button
                        onClick={() => {
                            setFile(null);
                        }}
                        startIcon={<DoNotDisturbOnIcon />}
                        color='error'
                        disabled={!file?.name}
                    >
                        Close
                    </Button>
                </Box>
                <div {...getRootProps()} style={{ border: '2px dashed', borderColor: file?.name ? "red" : "#ccc", padding: '20px', textAlign: 'center', marginTop: '10px', cursor: "cell" }}>
                    <input {...getInputProps()} />
                    {file ? <p style={{ color: "green" }}>{file.name}</p> : <p>Drag 'n' drop a file here, or click to select one</p>}
                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    Cancel
                </Button>
                <Button onClick={handleSubmit} color="primary" disabled={!file?.name || loading}>
                    {loading ? <CircularProgress size={24} /> : 'Submit'}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default AuthDialog;
