import React from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { Box, Typography } from '@mui/material';


const LinearGraph = ({ data = [], xDatakey, yDatakey, stroke = "#8884d8" }) => {
  return (
    <Box sx={{
      width: '100%',
      height: 300,
    }}>
      <ResponsiveContainer>
        {data.length < 1 ?
          <Box display="flex" justifyContent="center" alignItems="center" p={2} flexDirection="column">
            <Box borderRadius={2} component="img" width={80} src="https://creazilla-store.fra1.digitaloceanspaces.com/emojis/46430/exclamation-mark-emoji-clipart-md.png" />
            <Typography variant='h5'>No Data</Typography>
          </Box> :
          <LineChart
            data={data}
            margin={{
              top: 5,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey={xDatakey} />
            <YAxis />
            <Tooltip />
            <Legend />
            <Line type="monotone" dataKey={yDatakey} stroke={stroke} activeDot={{ r: 8 }} />
          </LineChart>}
      </ResponsiveContainer>
    </Box>
  );
};

export default LinearGraph;
