import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../utils";
import Cookies from "js-cookie";
import jwt_decode from "jwt-decode";

const initialStates = {
  loading: false,
  error: null,
  user: null,
  expiry: null,
};

export const authStart = (state) => {
  return updateObject(state, {
    loading: true,
    error: null,
  });
};

export const authSuccess = (state, action) => {
  Cookies.set("access_token", action.access_token, {
    expires: 1,
    secure: true,
  });

  Cookies.set("refresh_token", action.refresh_token, {
    expires: 7,
    secure: true,
  });

  const user = jwt_decode(action.access_token) ?? null;

  return updateObject(state, {
    loading: false,
    user: user,
  });
};

export const authFail = (state, action) => {
  return updateObject(state, {
    user: null,
    error: action.error,
    loading: false,
  });
};

export const authLogout = (state, action) => {
  return updateObject(state, {
    loading: false,
    error: null,
    user: null,
    expiry: null,
  });
};

const reducer = (state = initialStates, action) => {
  switch (action.type) {
    case actionTypes.AUTH_START:
      return authStart(state, action);
    case actionTypes.AUTH_SUCCESS:
      return authSuccess(state, action);
    case actionTypes.AUTH_FAIL:
      return authFail(state, action);
    case actionTypes.AUTH_LOGOUT:
      return authLogout(state, action);
    default:
      return initialStates;
  }
};

export default reducer;
