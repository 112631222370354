import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Paper from "@mui/material/Paper";
import Draggable from "react-draggable";
import { Box, IconButton, Tooltip } from "@mui/material";
import DownloadingIcon from "@mui/icons-material/Downloading";

function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

export default function VideoPreviewBox({
  open,
  onClose,
  video,
  downloadVideo,
}) {
  const handleClose = () => {
    onClose();
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      PaperComponent={PaperComponent}
      aria-labelledby="draggable-dialog-title"
    >
      <DialogTitle
        style={{ cursor: "move" }}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        id="draggable-dialog-title"
      >
        {video.title}
        <Tooltip title="Download video">
          <IconButton
            onClick={() => {
              downloadVideo(video.file);
            }}
          >
            <DownloadingIcon fontSize="large" color="primary" />
          </IconButton>
        </Tooltip>
      </DialogTitle>
      <DialogContent>
        <Box
          component="video"
          controls
          src={video.file}
          width={"auto"}
          height={300}
        />
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleClose}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}
