// ChannelFormDialog.js

import React, { useEffect, useState } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, Button, Autocomplete, TextField, Grid, FormControlLabel, Checkbox, FormGroup } from '@mui/material';
import TextInputField from '../../../../Components/Fields/TextInput';
import api from '../../../../conf/api';

const ChannelFormDialog = ({ open, rowSelected, refrashFunc, is_update, handleClose }) => {
    const [categories, setCategories] = useState([]);
    const [languages, setLanguages] = useState([]);
    const [accounts, setAccounts] = useState([]);
    const [selectedAcount, setSelectedAcount] = useState(Object.keys(rowSelected).length > 0 ? rowSelected.account : null);
    const [selectedCategory, setSelectedCategory] = useState(Object.keys(rowSelected).length > 0 ? rowSelected.category : null);
    const [selectedLanguage, setSelectedLanguage] = useState(Object.keys(rowSelected).length > 0 ? rowSelected.language : null);

    useEffect(() => {

        async function fetchAccounts() {
            await api
                .get("/youtube/accounts")
                .then((res) => {
                    const _data = res.data;
                    setAccounts(_data)
                })
                .catch((err) => {
                    console.log(err);
                });
        }
        async function fetchCategories() {
            await api
                .get("/youtube/categories")
                .then((res) => {
                    const _data = res.data;
                    setCategories(_data);
                })
                .catch((err) => {
                    console.log(err);
                });
        }
        async function fetchLanguages() {
            await api
                .get("/youtube/languages")
                .then((res) => {
                    setLanguages(res.data);
                })
                .catch((err) => {
                    console.log(err);
                })
        }
        if (!is_update) {
            fetchAccounts();
            fetchCategories();
            fetchLanguages();
        }

    }, [is_update])

    const handleSubmit = async () => {
        const channel_name = document.getElementById("channel_name").value;
        const channel_id = document.getElementById("channel_id").value;
        const videos_limit = document.getElementById("videos_limit").value;
        const disabled = document.getElementById("disabled").checked;
        const verified = document.getElementById("verified").checked;
        const post_description = document.getElementById("post_description").value;

        if (!is_update) {
            api.post("/youtube/channels/view/",
                {
                    account: selectedAcount.account_name,
                    channel_id: channel_id,
                    channel_name: channel_name,
                    category: selectedCategory?.id,
                    language: selectedLanguage?.id,
                    videos_limit: videos_limit,
                    verified: verified,
                    disabled: disabled,
                    post_description: post_description

                }).then(res => {
                    if (res.status === 201) {
                        refrashFunc();
                        handleClose();
                    }
                }).catch(response => {
                    console.log(response.response.data)
                })
        }
        else {
            // disabling_not reset if enables
            let _payload = {
                account: selectedAcount.account_name,
                channel_id: channel_id,
                channel_name: channel_name,
                category: selectedCategory?.id,
                language: selectedLanguage?.id,
                videos_limit: videos_limit,
                verified: verified,
                disabled: disabled,
                post_description: post_description
            }
            // coming true editing false
            if (rowSelected.disabled && !disabled) {
                _payload["disabling_note"] = "";
            }
            api.put(`/youtube/channels/view/${channel_name}/`,_payload
            ).then(res => {
                if (res.status === 200) {
                    refrashFunc();
                    handleClose();
                }
            }).catch(response => {
                console.log(response.response.data)
            })
        }

    }
    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="form-dialog-title"
            maxWidth="md"
        >
            <DialogTitle id="form-dialog-title">{(!is_update ? "Create" : "Edit") + " Channel"}</DialogTitle>
            <DialogContent >
                <Grid container spacing={2} >
                    <Grid item xs={6}>
                        <TextInputField
                            autoFocus
                            margin="dense"
                            label="Name"
                            type="text"
                            fullWidth
                            id="channel_name"
                            defaultValue={is_update ? rowSelected.channel_name : ""}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextInputField
                            margin="dense"
                            label="Channel Id"
                            type="text"
                            fullWidth
                            id="channel_id"
                            defaultValue={is_update ? rowSelected.channel_id : ""}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Autocomplete
                            options={accounts}
                            size='small'
                            getOptionLabel={(option) => option.account_name}
                            value={selectedAcount}
                            onChange={(event, newValue) => {
                                setSelectedAcount(newValue);
                            }}
                            renderInput={(params) => <TextField {...params} label="Account" />}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <Autocomplete
                            options={languages}
                            size='small'
                            getOptionLabel={(option) => option.name}
                            value={selectedLanguage}
                            onChange={(event, newValue) => {
                                setSelectedLanguage(newValue);
                            }}
                            renderInput={(params) => <TextField {...params} label="Language" />}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <Autocomplete
                            options={categories}
                            size='small'
                            getOptionLabel={(option) => option.name}
                            value={selectedCategory}
                            onChange={(event, newValue) => {
                                setSelectedCategory(newValue);
                            }}
                            renderInput={(params) => <TextField {...params} label="Category" />}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextInputField
                            margin="dense"
                            label="Daily uploading limit"
                            type="text"
                            fullWidth
                            id="videos_limit"
                            defaultValue={is_update ? rowSelected.videos_limit : ""}
                        />
                    </Grid>

                    {is_update &&
                        <Grid item xs={6}>
                            <TextInputField
                                margin="dense"
                                label="Remaing limit"
                                type="text"
                                readOnly
                                fullWidth
                                InputProps={{ readOnly: true }}
                                id="remaining_videos_limit"
                                defaultValue={is_update ? rowSelected.remaining_videos_limit : ""}
                            />
                        </Grid>
                    }
                    <Grid item xs={12}>
                        <TextInputField
                            margin="dense"
                            label="Description Footer"
                            multiline
                            minRows={3}
                            fullWidth
                            id="post_description"
                            defaultValue={is_update ? rowSelected.post_description : ""}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <FormGroup row>
                            <FormControlLabel control={<Checkbox id="disabled" defaultChecked={is_update ? rowSelected.disabled : false} />} label="Disabled" />
                            <FormControlLabel control={<Checkbox id="verified" defaultChecked={is_update ? rowSelected.verified : false} />} label="Verified" />
                        </FormGroup>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="inherit">
                    Close
                </Button>
                <Button onClick={handleSubmit} color="primary">
                    {!is_update ? "Create" : "Update"}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ChannelFormDialog;
