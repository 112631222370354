import { Box, Divider, IconButton, Paper, Typography } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import React, { useEffect, useState } from "react";
import SlowMotionVideoIcon from "@mui/icons-material/SlowMotionVideo";
import CloudDoneIcon from "@mui/icons-material/CloudDone";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import LineBaseChart from "../../../Components/Charts/LineBaseChart";
import Selector from "../../../Components/Selectors/Selector";
import api from "../../../conf/api";
import PeopleIcon from "@mui/icons-material/People";
import MotionPhotosAutoIcon from "@mui/icons-material/MotionPhotosAuto";
import HourglassBottomIcon from "@mui/icons-material/HourglassBottom";
import { connect } from "react-redux";
import { toast } from "react-toastify";

const InfoBox = (props) => {
  const { title, count, Icon, bgcolor } = props;

  return (
    <Box
      component={Paper}
      display="flex"
      borderRadius={4}
      gap={{ xs: 1, md: 2 }}
      padding={"25px 15px"}
      maxHeight={"160px"}
      minHeight={"100%"}
      width="100%"
      flexDirection={{ xs: "column", md: "row" }}
      sx={{ background: bgcolor }}
      elevation={3}
    >
      <IconButton
        sx={{
          width: { xs: 40, md: 80 },
          height: { xs: 40, md: 80 },
          bgcolor: "rgba(0, 0, 0, 0.2)",
        }}
      >
        <Icon sx={{ fontSize: { xs: 20, md: 50 }, color: "white" }} />
      </IconButton>
      <Box display="flex" flexDirection="column" flexGrow={1}>
        <Typography
          color="white"
          fontWeight={300}
          noWrap
          sx={{
            variant: { xs: "h6", md: "h5" },
            paragraph: { xs: false, md: true },
          }}
        >
          {title}
        </Typography>
        <Divider sx={{ bgcolor: "#fff", mt: 1 }} />
        <Typography variant="h5" color="white">
          {count}
        </Typography>
      </Box>
    </Box>
  );
};
function InformationPannel(props) {
  const initialMetaFields = {
    uploaded: 0,
    videos: 0,
    users: 0,
    creators: 0,
    nonactive_creators: 0,
  };
  const [meta, setMeta] = useState(initialMetaFields);
  const initialChartField = {
    videoHits: { loading: false, date: { start: null, end: null }, data: [] },
    earningHits: { loading: false, date: { start: null, end: null }, data: [] },
    upcomingPayments: { loading: false, date: { start: null, end: null }, data: [] },
  };
  const [chartData, setChartData] = useState(initialChartField);

  async function getVideoCounts(startDate = null, endDate = null) {
    try {
      setChartData((prevData) => ({
        ...prevData,
        videoHits: { ...prevData.videoHits, loading: true, data: [] },
      }));
      let _params;
      if (endDate) {
        _params = { start: startDate, end: endDate }
      }

      const video_res = await api.get("/videos/videohits/", { params: _params });
      const video_data = video_res.data;
      setChartData((prevData) => ({
        ...prevData,
        videoHits: { ...prevData.videoHits, loading: false, data: video_data },
      }));
    } catch {

    }
  }
  async function getLifeTimeEarning(startDate = null, endDate = null) {
    try {


      setChartData((prevData) => ({
        ...prevData,
        earningHits: { ...prevData.earningHits, loading: true, data: [] },
      }));
      let _params;
      if (endDate) {
        _params = { start: startDate, end: endDate }
      }

      const earning_res = await api.get("/earning/hits/", { params: _params });
      const earning_data = earning_res.data?.lifetimeEarning;

      setChartData((prevData) => ({
        ...prevData,
        earningHits: { ...prevData.earningHits, loading: true, data: earning_data },
      }));
    }
    catch { }
  }
  async function getUpcomingPayments(startDate = null, endDate = null) {
    try {


      setChartData((prevData) => ({
        ...prevData,
        videoHits: { ...prevData.upcomingPayments, loading: true, data: [] },
      }));
      let _params;

      if (endDate) {
        _params = { start: startDate, end: endDate }
      }

      const payment_res = await api.get("/earning/hits/", { params: _params });
      const payment_data = payment_res.data?.upcomingPayments;

      setChartData((prevData) => ({
        ...prevData,
        upcomingPayments: { ...prevData.upcomingPayments, loading: false, data: payment_data },
      }));
    }
    catch { }
  }


  useEffect(() => {
    async function GetMeta() {
      await api
        .get("/videos/getcounts")
        .then((res) => {
          setMeta(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
      if (props.user?.is_staff) {
        await api
          .get("/users/count/")
          .then((res) => {
            if (res.status === 200) {
              setMeta((pre) => ({ ...pre, ...res.data }));
            }
          })
          .catch((resp) => {
            const errorObject = resp.response?.data;
            try {
              for (const field in errorObject) {
                if (errorObject?.hasOwnProperty(field)) {
                  const errors = errorObject[field];
                  const formattedFieldErrors = errors.map(
                    (errorMessage) => `${field}:${errorMessage}`
                  );
                  formattedFieldErrors?.map((msg) => toast.error(msg));
                }
              }
            } catch {
              try {
                toast.error(JSON.stringify(errorObject));
              } catch {
                toast.error("Something went wrong");
              }
            }
          });
      }
    }
    GetMeta();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    getVideoCounts(chartData.videoHits?.date?.start, chartData.videoHits?.date?.end)
    // eslint-disable-next-line
  }, [chartData.videoHits.date.start])
  useEffect(() => {
    getLifeTimeEarning(chartData.earningHits?.date?.start, chartData.earningHits?.date?.end)
    // eslint-disable-next-line
  }, [chartData.earningHits.date.start])
  useEffect(() => {
    getUpcomingPayments(chartData.upcomingPayments?.date?.start, chartData.upcomingPayments?.date?.end)
    // eslint-disable-next-line
  }, [chartData.upcomingPayments.date.start])

  return (
    <Box>
      <Typography variant="h4">Dashboard</Typography>
      <Grid2
        container
        spacing={{ xs: 1, md: 2 }}
        justifyContent={{ xs: "center" }}
        mt={2}
      >
        <Grid2 xs={12} sm={4} lg={3}>
          <InfoBox
            title="Videos"
            count={meta.videos || 0}
            Icon={SlowMotionVideoIcon}
            bgcolor="linear-gradient(45deg, #004225, #68857B)"
          />
        </Grid2>
        <Grid2 xs={12} sm={4} lg={3}>
          <InfoBox
            title="Uploaded"
            bgcolor="linear-gradient(45deg, #2DA8D3, #1F7A8C)"
            count={meta.uploaded || 0}
            Icon={CloudDoneIcon}
          />
        </Grid2>
        <Grid2 xs={12} sm={4} lg={3}>
          <InfoBox
            title="Lifetime Earnings"
            count={`$ ${meta.lifeTime || 0}`}
            Icon={AttachMoneyIcon}
            bgcolor="linear-gradient(45deg, #444242,#d5d5d5)"
          />
        </Grid2>
        <Grid2 xs={12} sm={4} lg={3}>
          <InfoBox
            title="Upcoming Payments"
            count={`$ ${meta.upcoming || 0}`}
            Icon={AttachMoneyIcon}
            bgcolor="linear-gradient(45deg, #ca861b, #b7b184)"
          />
        </Grid2>
        {props.user?.is_staff && (
          <Grid2 xs={12} sm={4} lg={3}>
            <InfoBox
              title="Total users"
              count={meta.users || 0}
              Icon={PeopleIcon}
              bgcolor="linear-gradient(45deg, #2E8B57, #99d299)"
            />
          </Grid2>
        )}
        {props.user?.is_staff && (
          <Grid2 xs={12} sm={4} lg={3}>
            <InfoBox
              title="Creators"
              count={meta.creators || 0}
              Icon={MotionPhotosAutoIcon}
              bgcolor="linear-gradient(45deg, #147274, #2a9d9b)"
            />
          </Grid2>
        )}
        {props.user?.is_staff && (
          <Grid2 xs={12} sm={4} lg={3}>
            <InfoBox
              title="Non active"
              count={meta.nonactive_creators || 0}
              Icon={HourglassBottomIcon}
              bgcolor="linear-gradient(45deg, #FF6666, #CC3333)"
            />
          </Grid2>
        )}
        {props.user?.is_staff && (
          <Grid2 xs={12} sm={4} lg={3}>
            <InfoBox
              title="Ingested Assets"
              count={meta.assets_sheet || 0}
              Icon={HourglassBottomIcon}
              bgcolor="linear-gradient(45deg, #4c1130, #ff0055)"

            // bgcolor="linear-gradient(45deg, #4c1130, #b30021)"
            />
          </Grid2>
        )}
        {/* Graphs */}
        <Grid2 container xs={12} mt={3}>
          <Grid2 xs={12} >
            <Box >
              <Box
                p={"0px 30px"}
                display="flex"
                alignItems="center"
                justifyContent="space-between"
              >
                <Typography variant="h5">Videos</Typography>
                <Selector setDateRange={(start, end) => {
                  setChartData(pre => ({
                    ...pre, videoHits: { ...pre.videoHits, date: { start: start, end: end } }
                  }))
                }} />
              </Box>
              <Box>
                <LineBaseChart data={chartData.videoHits?.data} yDatakey={"video_count"} xDatakey={"date"} />
              </Box>
            </Box>
          </Grid2>
          <Grid2 xs={12} md={6}>
            <Box >
              <Box p={"0px 30px"} display="flex"
                alignItems="center" justifyContent="space-between">
                <Typography variant="h5">LifeTime Earnings</Typography>
                <Selector setDateRange={(start, end) => {
                  setChartData(pre => ({
                    ...pre, earningHits: { ...pre.earningHits, date: { start: start, end: end } }
                  }))
                }} />
              </Box>
              <Box>
                <LineBaseChart data={chartData.earningHits?.data || 0} yDatakey={"amount"} xDatakey={"date"} />
              </Box>
            </Box>
          </Grid2>
          <Grid2 xs={12} md={6}>
            <Box>
              <Box p={"0px 30px"} display="flex"
                alignItems="center" justifyContent="space-between">
                <Typography variant="h5">Upcoming Payments</Typography>
                <Selector setDateRange={(start, end) => {
                  setChartData(pre => ({
                    ...pre, upcomingPayments: { ...pre.upcomingPayments, date: { start: start, end: end } }
                  }))
                }} />
              </Box>
              <Box>
                <LineBaseChart data={chartData.upcomingPayments?.data} yDatakey={"amount"} xDatakey={"date"} />
              </Box>
            </Box>
          </Grid2>
        </Grid2>
      </Grid2>
    </Box>
  );
}
const mapStatesToProps = (state) => {
  return {
    user: state.user,
  };
};
export default connect(mapStatesToProps, null)(InformationPannel);
