import React, { useEffect, useState } from "react";
import InfoCard from "../components/InfoCard";
import api from "../../../conf/api";
import { Box, Divider, Grid, LinearProgress, Typography } from "@mui/material";

function ChannelsList({ channels, categories, handleChannelsSelect }) {
  const [data, setData] = useState([]);
  const [selectedChannels, selectChannels] = useState(channels);
  const [loading, setLoading] = useState(true);
  const handleAdd = (ctg) => {
    const _chs = selectedChannels.find(c => c.title === ctg.title);
    let new_chs;
    if (_chs) {
      new_chs = selectedChannels.filter(c => c.title !== ctg.title)
    }
    else {
      new_chs = [ctg, ...selectedChannels]
    }
    selectChannels(new_chs);
    handleChannelsSelect(new_chs);
  }
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const categoryIds = categories.map(ctg => ctg.id);
      await api
        .get("/youtube/channels", { params: { ignore_limit:1,disabled: 0, withCridentials: 1, categories: encodeURI(categoryIds) } })
        .then((res) => {
          const accounts = [];
          for (const obj of res.data) {
            accounts.push({
              title: obj.channel_name,
              email: obj.account?.email,
              verified: obj.verified,
              img: "https://cdn1.iconfinder.com/data/icons/user-pictures/101/malecostume-512.png",
              channels: obj.channel_id,
              category: obj.category,
              language: obj.language,
              post_description: obj.post_description,
            });
            // Sort the accounts array by account name
            accounts.sort((a, b) => {
              if (a.email < b.email) return -1;
              if (a.email > b.email) return 1;
              return 0;
            });

            setData(accounts);
          }
        }).catch(err => {
          console.log(err)
        })
        .finally(() => {
          setLoading(false);
        });
    };

    fetchData();
    // eslint-disable-next-line
  }, []);

  return (
    <Grid container spacing={2}>
      {loading && (
        <Grid item xs={12}>
          <LinearProgress />
        </Grid>
      )}
      {data.map((_data) => (
        <Grid item xs={12} sm={6} md={4}>
          <InfoCard data={_data} selectedChannels={selectedChannels} handleSelect={handleAdd} verified={_data.verified} selected={selectedChannels.find(ch => ch.title === _data.title)}>
            <Divider flexItem variant="inset" sx={{ mt: 1 }} />
            <Typography variant="subtitle2" mt={1}>
              Channel ID: <strong>{_data.channels}</strong>
            </Typography>
            <Box display="flex" gap={2}>
              <Typography variant="subtitle2">Category: <strong>{_data.category?.name}</strong></Typography>
              <Typography variant="subtitle2">Languages: <strong>{_data.language?.name}</strong></Typography>
            </Box>
          </InfoCard>
        </Grid>
      ))}
    </Grid>
  );
}

export default ChannelsList;
