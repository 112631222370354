import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Grid } from "@mui/material";
import { toast } from "react-toastify";
import api from "../../../conf/api";
import TextInput from "../../../Components/Fields/TextInput.js";
import UserSelector from "../../../Components/Selectors/UserSelector.js";

export default function ChannelPopupBox({
  open,
  is_update,
  selectedRow,
  refreshFunc,
  onClose,
  is_admin = false
}) {
  let initialFormData = {
    channel_id: selectedRow.channel_id || "",
    name: selectedRow.name || "",
    url: selectedRow.url || "",
  };
  if (is_admin) {
    initialFormData["user"] = selectedRow.user_obj || null
  }

  const [formData, setFormData] = React.useState(initialFormData);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleClose = () => {
    onClose();
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!validateYT()) {
      return;
    }
    if (is_update) {
      const updateChannel = async () => {
        await api
          .patch(`/promo/channels/${selectedRow.name}/`, formData)
          .then((res) => {
            if (res.status === 200) {
              toast.success("Channel updated successfully.");
              refreshFunc();
              setFormData(formData);
              onClose();
            }
          })
          .catch((error) => {
            const errorObject = error.response?.data;
            try {
              for (const field in errorObject) {
                if (errorObject?.hasOwnProperty(field)) {
                  const errors = errorObject[field];
                  const formattedFieldErrors = errors.map(
                    (errorMessage) => `${field}:${errorMessage}`
                  );
                  formattedFieldErrors?.map((msg) => toast.error(msg));
                }
              }
            } catch {
              try {
                toast.error(JSON.stringify(errorObject));
              } catch {
                toast.error("Something went wrong");
              }
            }
          });
      };
      updateChannel();
    } else {
      const data = formData;
      await api
        .post("/promo/channels/", data)
        .then((response) => {
          if (response.status === 201) {
            toast.success(response.data?.message);
            refreshFunc();
            onClose();
          }
        })
        .catch((error) => {
          const errorObject = error.response?.data;
          try {
            for (const field in errorObject) {
              if (errorObject?.hasOwnProperty(field)) {
                const errors = errorObject[field];
                const formattedFieldErrors = errors.map(
                  (errorMessage) => `${field}:${errorMessage}`
                );
                formattedFieldErrors?.map((msg) => toast.error(msg));
              }
            }
          } catch {
            try {
              toast.error(JSON.stringify(errorObject));
            } catch {
              toast.error("Something went wrong");
            }
          }
        });
    }
  };
  const handleUserSelect = (e, newValue) => {
    setFormData(pre => ({ ...pre, user: newValue }))
  }
  const validateYT = () => {
    const ytIdRegex = /^(?![_-])(?!.*[_-]{2})[a-zA-Z0-9_-]{8,}(?<![_-])$/;
    const ytUrlRegex = /^(?:https?:\/\/)?(?:www\.)?youtube\.com\/@([a-zA-Z0-9_.-]+)$/;
    if (!ytIdRegex.test(formData.channel_id)) {
      toast.error("Channel ID is not in correct format. Only alphanumeric characters, hyphens (-), or underscores (_) allowed.");
      return false;
    }
    else if (!ytUrlRegex.test(formData.url)) {
      toast.error("Youtube URL is not in correct format. It must be like `https://www.youtube.com/@username`");
      return false;
    }
    return true;
  }

  return (
    <Dialog maxWidth="md" open={open} onClose={handleClose}>
      <DialogTitle>{is_update ? "Update" : "Create"} Channel</DialogTitle>
      <DialogContent>
        <DialogContentText mb={2}>
          Please fill in the required information to{" "}
          {is_update ? "update" : "create"} a new channel.
        </DialogContentText>
        <form onSubmit={handleSubmit}>
          <Grid container marginBottom={3} spacing={3} alignItems="center">
            {is_admin &&
              <Grid item xs={12}>
                <UserSelector value={formData.user} onChange={handleUserSelect} disabled={is_update} />
              </Grid>
            }
            <Grid item xs={6}>
              <TextInput
                label="Channel ID"
                type="text"
                fullWidth
                name="channel_id"
                value={formData.channel_id}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={6}>
              <TextInput
                label="Channel Name"
                type="text"
                fullWidth
                required
                name="name"
                value={formData.name}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextInput
                label="URL"
                type="url"
                fullWidth
                required
                name="url"
                value={formData.url}
                onChange={handleChange}
              />
            </Grid>
          </Grid>
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button variant="contained" type="submit" color="primary">
              {is_update ? "Update" : "Create"} Channel
            </Button>
          </DialogActions>
        </form>
      </DialogContent>
    </Dialog>
  );
}
