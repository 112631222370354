import * as React from "react";
import { DataGrid } from "@mui/x-data-grid";
import {
  Box,
  Button,
  Divider,
  IconButton,
  InputAdornment,
  LinearProgress,
  TextField,
  Typography,
} from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import api from "../../../conf/api";
import DeleteIcon from "@mui/icons-material/Delete";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import DoneIcon from "@mui/icons-material/Done";
import LanguageIcon from "@mui/icons-material/Language";
import { toast } from "react-toastify";
import { ValidUrl } from "../../../utils";
import { connect } from "react-redux";

const WhiteListing = (props) => {
  const [loading, setLoading] = React.useState(false);
  const [rows, setRows] = React.useState([]);
  const channelUrlRef = React.useRef(null);
  const addUrlRef = React.useRef(null);
  const [paginationModel, setPaginationModel] = React.useState({
    page: 0,
    pageSize: 5,
  });
  const [rowCountState, setRowCountState] = React.useState(0);

  const columns = [
    { field: "id", headerName: "ID", width: 100 },
    props.is_admin && { field: "user", headerName: "User", width: 150 },
    {
      field: "channel_url",
      headerName: "Channel URL",
      flex: 1,
      renderCell: (params) => {
        return (
          <TextField
            size="small"
            variant="standard"
            fullWidth
            inputRef={channelUrlRef}
            defaultValue={params.row.channel_url}
            InputProps={{
              sx: { color: "primary.light" },
              endAdornment: (
                <InputAdornment position="end">
                  {channelUrlRef.current && (
                    <IconButton
                      onClick={handleUpdate(
                        params.row.id,
                        "channel_url",
                        channelUrlRef.current.value
                      )}
                    >
                      <DoneIcon color="success" />
                    </IconButton>
                  )}
                </InputAdornment>
              ),
              startAdornment: (
                <InputAdornment position="start">
                  <IconButton
                    onClick={() => {
                      window.open(params.row.channel_url, "_blank");
                    }}
                  >
                    <LanguageIcon color="primary" />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        );
      },
    },
    {
      field: "climed",
      headerName: "Climed",
      width: 100,
      renderCell: (params) =>
        params.value ? (
          <IconButton color="primary" disabled>
            <CheckCircleIcon color="success" />
          </IconButton>
        ) : (
          <IconButton color="error" disabled>
            <CancelIcon sx={{ color: "error.light" }} />
          </IconButton>
        ),
    },
    {
      field: "created_date",
      headerName: "Date Added",
      type: "dateTime",
      width: 200,
      valueGetter: (params) => new Date(params.row.created_date),
    },
    {
      field: "uploaded_date",
      headerName: "Excel Date",
      type: "dateTime",
      width: 200,
      valueGetter: (params) => new Date(params.row.uploaded_date),
    },
    {
      field: "action",
      headerName: "Action",
      width: 100,
      renderCell: (params) => (
        <IconButton
          disabled={loading}
          color="primary"
          onClick={() => handleDelete(params.row.id)}
        >
          <DeleteIcon color="error" />
        </IconButton>
      ),
    },
  ];

  const handleDelete = async (id) => {
    try {
      setLoading(true);
      await api.delete(`/users/whitelist/${id}`);
      setRows((prevRows) => prevRows.filter((row) => row.id !== id));
    } catch (error) {
      console.error("Error deleting row: ", error);
    } finally {
      setLoading(false);
    }
  };

  const handleUpdate = (id, field, value) => async () => {
    const updatedRecord = rows.find((r) => r.id === id);
    setLoading(true);
    try {
      const response = await api.put(`/users/whitelist/${id}/`, {
        ...updatedRecord,
        [field]: channelUrlRef.current.value,
      });
      if (response.status === 200) {
        toast.success(`${field} updated successfully`);
        FetchRows();
      }
    } catch (error) {
      toast.error("Error updating row: ", error);
    } finally {
      setLoading(false);
    }
  };

  const handleAddRow = async () => {
    try {
      setLoading(true);
      if (addUrlRef.current) {
        const value = addUrlRef.current.value;
        if (!ValidUrl(value)) {
          toast.warning("Enter a valid URL !");
          return;
        }
        const response = await api.post(
          "/users/whitelist/",
          {
            channel_url: value,
          },
          { timeout: 500000 }
        );
        // const newRow = response.data;
        addUrlRef.current.value = "";
        if (response.status === 201) {
          FetchRows();
        }
      }
    } catch (error) {
      console.error("Error adding a new row: ", error);
      const errorData = error.response?.data;
      console.log(error);
      if (errorData) {
        Object.keys(errorData).map((userKey) =>
          errorData[userKey].map((errorMessage, index) =>
            toast.error(`${userKey}; ${errorMessage}`)
          )
        );
      }
    } finally {
      setLoading(false);
    }
  };

  const FetchRows = async () => {
    setLoading(true);
    try {
      const response = await api.get("/users/whitelist/", {
        params: {
          page: paginationModel.page + 1,
          page_size: paginationModel.pageSize,
        },
      });
      const res = response.data;
      setRows(res.results);
      setRowCountState(res.count);
    } catch (error) {
      console.error("Error fetching data: ", error);
    } finally {
      setLoading(false);
    }
  };

  React.useEffect(() => {
    FetchRows();
    // eslint-disable-next-line
  }, [paginationModel.page, paginationModel.pageSize]);

  return (
    <Box>
      <Typography variant="h3" paragraph>
        Whitelisting
      </Typography>

      <Box
        mt={2}
        display="flex"
        flexDirection="column"
        p={2}
        sx={{
          bgcolor: "grey.200",
          borderRadius: 2,
          width: { xs: "100%", md: "80%" },
        }}
        gap={1.5}
      >
        <Typography fontWeight={700}>Channel URL</Typography>
        <Box
          display="flex"
          flexDirection={{ xs: "column", md: "row" }}
          width="100%"
          alignItems="center"
          gap={1.5}
        >
          <TextField
            fullWidth
            inputRef={addUrlRef}
            size="small"
            placeholder="https://www.example.com"
          />
          <Button
            onClick={handleAddRow}
            variant="contained"
            color="success"
            sx={{ minWidth: 150 }}
            startIcon={<AddCircleIcon />}
          >
            Add Row
          </Button>
        </Box>
      </Box>
      <Box
        sx={{
          height: 550,
          width: { xs: "100%", md: "80%" },
        }}
      >
        <Divider variant="middle" flexItem sx={{ mt: 2, mb: 2 }} />
        {loading && <LinearProgress />}

        <DataGrid
          loading={loading}
          rows={rows}
          columns={columns}
          rowCount={rowCountState}
          paginationMode="server"
          pageSizeOptions={[5, 10, 20, 50, 100, 200]}
          paginationModel={paginationModel}
          onPaginationModelChange={setPaginationModel}
        />
      </Box>
    </Box>
  );
};
const mapStatesToProps = (states) => {
  return {
    is_admin: states.user?.is_staff,
  };
};
export default connect(mapStatesToProps, null)(WhiteListing);
