import React, { useEffect, useState } from "react";
import SteppsIndexes from "./components/SteppsIndexes";
import { Box, Typography } from "@mui/material";
// import AccountSelection from "./Steps/AccountSelection";
import VideoSelection from "./Steps/VideoSelection";
import { toast } from "react-toastify";
import UploadVideo from "./Steps/UploadVideo";
import ScopsSelection from "./Steps/ScopeSelection";

function Index() {
  const [activeStep, setActiveStep] = useState(0);
  const [disableButtons, setDisableButtons] = useState({
    next: false,
    back: true,
  });
  const initialObjects = { categories: [], channels: [], videos: {} };
  const [selectedObject, selectObject] = useState(initialObjects);

  const handleCategoriesSelection = (_objs) => {
    selectObject((pre) => ({ ...pre, categories: _objs }));
  };
  const handleLanguageSelection = (_objs) => {
    selectObject((pre) => ({ ...pre, languages: _objs }));
  };
  const handleChannelsSelect = (_channels) => {
    var _videos = {};
    for (const ch in _channels) {
      const _ch = _channels[ch].title;
      if (selectedObject.videos[_ch]) {
        _videos = { ..._videos, [_ch]: selectedObject.videos[_ch] };
      }
      else {
        _videos = { ..._videos, [_ch]: [] };
      }
    }
    selectObject((pre) => ({ ...pre, channels: _channels, videos: _videos }));
  };
  // const handleAddVideo = (obj, channel) => {
  //   const video = selectedObject.videos[channel].find(
  //     (v) => v.unique_key === obj.unique_key
  //   );
  //   if (video === undefined) {
  //     selectObject((pre) => ({
  //       ...pre,
  //       videos: { ...pre.videos, [channel]: [...pre.videos[channel], obj] },
  //     }));
  //   } else {
  //     toast.warning("Video already exist");
  //   }
  // };
  const handleAddVideo = (obj, _ch) => {
    let updatedVideos = { ...selectedObject.videos };

    for (const channel in updatedVideos) {
      const videoExists = updatedVideos[channel].some(
        (v) => v.unique_key === obj.unique_key
      );
      if (!videoExists) {
        updatedVideos[channel] = [...updatedVideos[channel], obj];
      }
    }

    selectObject((pre) => ({
      ...pre,
      videos: updatedVideos,
    }));
  };
  const RemoveVideo = (obj, channel) => {
    const video = selectedObject.videos[channel].find(
      (v) => v.unique_key === obj.unique_key
    );
    if (video !== undefined) {
      selectObject((pre) => ({
        ...pre,
        videos: { ...pre.videos, [channel]: pre.videos[channel].filter((v) => video.unique_key !== v.unique_key) },
      }));
    } else {
      toast.warning("Video not found.");
    }
  };
  const Reset = () => {
    selectObject(initialObjects);
    setDisableButtons({
      next: false,
      back: true,
    });
    setActiveStep(0);
  };

  const steps = [
    {
      label: "Select Scope",
      Content: () => (
        <ScopsSelection
          categories={selectedObject.categories}
          languages={selectedObject.languages}
          handleLanguageSelection={handleLanguageSelection}
          handleCategoriesSelection={handleCategoriesSelection}
          channels={selectedObject.channels}
          handleChannelsSelect={handleChannelsSelect}
        />
      ),
    },
    {
      label: "Video Selection",
      Content: () => (
        <VideoSelection
          selectedVideos={selectedObject.videos}
          handleAddVideo={handleAddVideo}
          RemoveVideo={RemoveVideo}
          channels={selectedObject.channels}
        />
      ),
    },
    // {
    //   label: "Select Channel",
    //   Content: () => (
    //     <ChannelsList
    //       categories={selectedObject.categories}
    //       languages={selectedObject.languages}
    //       channels={selectedObject.channels}
    //       handleChannelsSelect={handleChannelsSelect}
    //     />
    //   ),
    // },
    {
      label: "Publish on youtube",
      Content: () => (
        <UploadVideo
          Reset={Reset}
          selectedObjects={selectedObject}
          RemoveVideo={RemoveVideo}
        />
      ),
    },
  ];

  // Disbale buttons
  useEffect(() => {
    let back = disableButtons.back;
    let next = disableButtons.next;
    if (activeStep + 1 === steps.length) {
      next = true;
    } else {
      next = false;
    }
    if (activeStep === 0) {
      back = true;
    } else {
      back = false;
    }
    switch (activeStep) {
      case 0:
        if (selectedObject.categories?.length < 1 || selectedObject.channels?.length < 1) {
          next = true;
        }
        break;
      case 1:
        for (const channelVideos in selectedObject.videos) {
          if (selectedObject.videos[channelVideos].length < 1) {
            next = true;
            break;
          }
        }
        break;
      default:
        break;
    }
    setDisableButtons({ back: back, next: next });
    // eslint-disable-next-line
  }, [activeStep, selectedObject]);

  return (
    <Box display="flex" flexDirection="column" gap={4}>
      <Typography variant="h4" textAlign="center" paragraph>
        Youtube Content Publishing
      </Typography>
      <SteppsIndexes
        backDisabled={disableButtons.back}
        nextDisabled={disableButtons.next}
        steps={steps}
        activeStep={activeStep}
        setActiveStep={setActiveStep}
      />
    </Box>
  );
}

export default Index;
