import React from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";

export default function PhoneSelector({
  phone,
  setPhone,
  required = true,
  country_code = "pk",
  ...inputProps
}) {
  return (
    <PhoneInput
      country={country_code}
      enableSearch={true}
      inputProps={{
        name: "phone",
        required: true,
        ...inputProps,
      }}
      inputStyle={{ width: "100%", height: "40px" }}
      value={phone}
      placeholder="Phone Number"
      onChange={(phone) => setPhone(phone)}
    />
  );
}
