import React, { useCallback, useEffect, useState } from "react";
import {
    IconButton,
    Paper,
    Tooltip
} from '@mui/material';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Scrollbars from 'react-custom-scrollbars-2';
import { DataGrid } from '@mui/x-data-grid';
import AddCircleIcon from "@mui/icons-material/AddCircle";
import CreateIcon from "@mui/icons-material/Create";
import DeleteIcon from "@mui/icons-material/Delete";
import api from "../../../../conf/api";
import ProxyBox from "../components/ProxyBox.js";
import ConfirmDeleteProxyBox from "../components/ConfirmDeleteProxyBox.js";

export default function Proxies() {
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const [rowCount, setRowCount] = useState(0);
    const [selectedRow, setSelectedRow] = useState(null);
    const [isUpdate, setIsUpdate] = useState(false);
    const [proxyBox, setProxyBox] = useState(false);
    const [deleteProxyBox, setDeleteProxyBox] = useState(false);

    const styles = {
        "& .deleted-row": {
            backgroundColor: "rgba(255, 0, 0, 0.1)",
        },
    };

    const columns = [
        {
            field: "action",
            headerName: "Action",
            align: "center",
            headerAlign: "center",
            sortable: true,
            width: 150,
            renderCell: (params) => (
                <Box display="flex" gap={1}>
                    <Tooltip title="Edit Proxy">
                        <IconButton size="small" onClick={handleupdateProxy(params.row.id)}>
                            <CreateIcon fontSize="small" color="primary" />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Delete Proxy">
                        <IconButton size="small" onClick={handleDeletetProxy(params.row.id)}>
                            <DeleteIcon fontSize="small" sx={{ color: "error.dark" }} />
                        </IconButton>
                    </Tooltip>
                </Box>
            ),
        },
        {
            field: "ip_address",
            headerName: "IP Address",
            sortable: true,
            width: 200,
            flex: 1,
        },
        {
            field: "port",
            headerName: "Port No.",
            sortable: true,
            minWidth: 100,
        },
        {
            field: "created_at",
            headerName: "Created At",
            type: "dateTime",
            sortable: true,
            width: 200,
            valueGetter: (params) => new Date(params.row.created_at),
        },
        {
            field: "updated_date",
            headerName: "Updated At",
            type: "dateTime",
            sortable: true,
            width: 200,
            valueGetter: (params) => new Date(params.row.updated_date),
        },
    ];

    async function fetchData() {
        await api
            .get("/proxies/")
            .then((res) => {
                setData(res.data);
                setRowCount(res.data.length);
            })
            .catch((err) => {
                console.log(err);
            })
            .finally(() => {
                setLoading(false);
            });
    }

    useEffect(
        () => {
            setLoading(true);
            fetchData();
        },
        // eslint-disable-next-line
        []
    );

    const handleupdateProxy = (id) => () => {
        setSelectedRow(id);
        setIsUpdate(true);
        setProxyBox(true);
    };
    const handleDeletetProxy = (id) => () => {
        setSelectedRow(id);
        setDeleteProxyBox(true);
    };

    const ProxyDialog = useCallback(
        () => (
            <ProxyBox
                open={proxyBox}
                selectedRow={selectedRow}
                is_update={isUpdate}
                onClose={() => {
                    setIsUpdate(false);
                    setSelectedRow({});
                    setLoading(false);
                    setProxyBox(false);
                }}
                refreshFunc={fetchData}
            />
        ),
        // eslint-disable-next-line
        [proxyBox, isUpdate, selectedRow]
    );
    const DeleteProxyDialog = useCallback(
        () => (
            <ConfirmDeleteProxyBox
                open={deleteProxyBox}
                selectedRow={selectedRow}
                setOpen={setDeleteProxyBox}
                refreshFunc={fetchData}
            />
        ),
        // eslint-disable-next-line
        [deleteProxyBox, selectedRow]
    );

    return (
        <Box mt={4} component={Paper} borderRadius={4}>
            <Box
                border="1px solid"
                borderColor="grey.400"
                borderRadius={"10px 10px 0px 0px"}
                p={1}
                display="flex"
                alignItems="center"
                justifyContent="space-between"
            >
                <Typography color="grey" fontWeight={600}>
                    All Proxies
                </Typography>
                <Tooltip title="Add New Proxy">
                    <IconButton
                        size="small"
                        onClick={() => {
                            setIsUpdate(false);
                            setProxyBox(true);
                        }}
                    >
                        <AddCircleIcon color="success" />
                    </IconButton>
                </Tooltip>
            </Box>
            <Scrollbars style={{ height: 500, width: "100%" }}>
                <DataGrid
                    rows={data}
                    columns={columns}
                    loading={loading}
                    sx={styles}
                    pageSize={10}
                    rowCount={rowCount}
                    rowSelection={false}
                    disableColumnSelector
                    disableDensitySelector
                    disableSelectionOnClick
                    getRowClassName={(params) => {
                        if (params.row?.deleted) {
                            return "deleted-row";
                        }
                        return "";
                    }}
                />
            </Scrollbars>
            {/* Add New Proxy */}
            <ProxyDialog />
            {/* Confirm Delete Box  */}
            <DeleteProxyDialog />
        </Box>
    );
}
