import { Box, ButtonBase, Divider, IconButton, Tooltip, Typography } from "@mui/material";
import ClearIcon from '@mui/icons-material/Clear';
import React from "react";
import DoneAllTwoToneIcon from '@mui/icons-material/DoneAllTwoTone';

function VideoCard({ video, handleAdd = undefined, onDelete = undefined, _component = ButtonBase, sx }) {
  return (
    <Box
      component={_component}
      onClick={handleAdd && handleAdd(video)}
      key={video.unique_key}
      sx={{ border: "0.5px solid #d3d3d3", ...sx }}
      display="flex"
      flexDirection="column"
      borderRadius={2}
      gap={1}
      p={1}
      width="100%"
    >

      {onDelete && (
        <Box width="100%" display="flex" justifyContent="space-between" alignItems="center">
          <IconButton size="small" onClick={() => onDelete(video)}>
            <ClearIcon fontSize="small" />
          </IconButton>
          <IconButton size="small" disabled>
            <DoneAllTwoToneIcon color="success" fontSize="small" />
          </IconButton>
        </Box>
      )
      }
      <Box textAlign="center" height={200} width={"100%"}>
        <Box
          borderRadius={2}
          component="video"
          controls
          loading="lazy"
          height={"100%"}
          minWidth={"100%"}
          maxWidth={"100%"}
          src={video.s3_url}
        />
      </Box>
      <Box display="flex" flexDirection="column" width="100%" minHeight={60}>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Typography variant="subtitle2" color="gray" align="left" >
            {video.unique_key}
          </Typography>
          {video.created_at &&
            <Typography variant="subtitle2" color="gray">{new Date(video.created_at).toDateString()}</Typography>
          }
        </Box>
        <Divider flexItem variant="middle" />
        {video.description ?
          <Tooltip title={video.description}>
            <Typography variant="body2" align="left">{video.title ? video.title : video.video_title}</Typography>
          </Tooltip> :
          <Typography variant="body2" align="left">{video.title ? video.title : video.video_title}</Typography>
        }
      </Box>
    </Box >
  );
}

export default VideoCard;
