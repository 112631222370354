import React, { useState } from 'react';
import HoverVideoPlayer from 'react-hover-video-player';
import { Box, CircularProgress, Skeleton, Typography } from '@mui/material';

const VideoScreen = ({ video, onClick }) => {
    const [thumbnailLoaded, setThumbnailLoaded] = useState(false);

    const handleThumbnailLoad = () => {
        setThumbnailLoaded(true);
    };
    const TextWrapper = ({ color = "#ffffff", ...props }) => {
        return <Box position="relative">
            <Typography sx={{ position: "absolute", top: 10, left: 10, color: color, width: "98%" }} variant='subtitle2'>{video.title}</Typography>
            {props.children}
            <Typography sx={{ position: "absolute", bottom: 10, left: 10, color: color, textDecoration: "underline" }} fontWeight="bold" variant='subtitle2'>{video.unique_key}</Typography>
        </Box>
    }

    return (
        <Box
            sx={{
                "& video": {
                    height: "206.21px",
                    width: "100%",
                    borderRadius: 6
                }
            }}
        >
            <HoverVideoPlayer
                style={{ width: "100%", maxWidth: { xs: 300, md: 350 }, cursor: "pointer" }}
                videoSrc={video?.url}
                onClick={() => { onClick(video) }}
                pausedOverlay={
                    thumbnailLoaded ? (
                        <TextWrapper>
                            <Box
                                component="img"
                                src={video?.thumbnail}
                                alt={video?.title.length > 150
                                    ? video?.title.substring(0, 150) + "..."
                                    : video?.title}
                                sx={{ height: "206.21px", width: "100%", borderRadius: 6 }}
                            />
                        </TextWrapper>
                    ) : (
                        <TextWrapper color='#000000'>
                            <Skeleton
                                variant="rectangular"
                                sx={{ height: "206.21px", width: "100%", borderRadius: 6 }}
                            />
                        </TextWrapper>
                    )
                }
                loadingOverlay={
                    <Box zIndex={2} height="100%" width="100%" display="flex" justifyContent="center" alignItems="center">
                        <CircularProgress size={50} />
                    </Box>
                }
                hoverTarget={
                    thumbnailLoaded ? (
                        <Box
                            component="img"
                            src={video?.thumbnail}
                            alt={video?.title}
                            sx={{ height: "206.21px", width: "100%", borderRadius: 6 }}
                        />
                    ) : (
                        <Skeleton
                            variant="rectangular"
                            sx={{ height: "206.21px", width: "100%", borderRadius: 6 }}
                        />
                    )
                }
            />
            <img
                src={video?.thumbnail}
                onLoad={handleThumbnailLoad}
                style={{ display: 'none' }}
                alt=""
            />
        </Box>
    )
};

export default VideoScreen;
