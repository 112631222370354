import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import { Box } from "@mui/material";
import { toast } from "react-toastify";
import api from "../../../../conf/api";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function ConfirmDeleteCategoryBox({
  open,
  setOpen,
  selectedRow,
  refreshFunc,
}) {
  const handleClose = () => {
    setOpen(false);
  };
  const DeleteChannel = async () => {
    await api.delete(`/youtube/categories/${selectedRow}/`)
      .then((res) => {
        if (res.status === 204) {
          toast.info("Category is deleted successfuly");
          setOpen(false);
          refreshFunc();
        }
      }).catch(res => {
        if (res.response?.data?.error) {
          toast.error(res.response?.data?.error);
        }
        else {
          console.log(res.response)
        }
      });
  };

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle>
        <Box display="flex" gap={2}>
          <WarningAmberIcon fontSize="large" color="error" />
          {`Are you sure you want to delete (${selectedRow})`}
        </Box>
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-slide-description">
          This action will delete the Category.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Disagree</Button>
        <Button onClick={DeleteChannel} color="error">
          Agree
        </Button>
      </DialogActions>
    </Dialog>
  );
}
