import React, { useState } from 'react';
import {
  Box,
  Tab
} from '@mui/material';
import {
  TabContext,
  TabList,
  TabPanel
} from '@mui/lab';
import Categories from './tabs/categories';
import Languages from './tabs/languages';
import Accounts from './tabs/Accounts';
import Proxies from './tabs/Proxies';

export default function LabTabs() {
  const [value, setValue] = useState('1');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: '100%', typography: 'body1' }}>
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabList onChange={handleChange}>
            <Tab label="Categories" value="1" />
            <Tab label="Languages" value="2" />
            <Tab label="Account" value="3" />
            <Tab label="Proxies" value="4" />
          </TabList>
        </Box>
        <TabPanel value="1">
          <Categories />
        </TabPanel>
        <TabPanel value="2">
          <Languages />
        </TabPanel>
        <TabPanel value="3">
          <Accounts />
        </TabPanel>
        <TabPanel value="4">
          <Proxies />
        </TabPanel>
      </TabContext>
    </Box>
  );
}
