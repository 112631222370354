import React, { useState } from "react";
import {
  Box,
  Typography,
  LinearProgress,
  Paper,
  TextField,
  CircularProgress,
  Autocomplete,
  IconButton,
} from "@mui/material";
import api from "../../../conf/api";
import GenericTable from "../../../Components/Table/GenericTable";
import { connect } from "react-redux";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";

const AssetTable = (props) => {
  const [data, setData] = useState([]);
  const [rowCountState, setRowCountState] = React.useState(10);
  const [options, setOptions] = useState({ loading: false, data: [] });
  const [loading, setLoading] = useState(true);
  const [selectedUser, selectUser] = useState("");

  const fetchData = async (
    page = 0,
    rowsPerPage = 10,
    orderBy = "created_date",
    order = "asc",
    user = selectedUser
  ) => {
    const params = {
      page: page + 1,
      page_size: rowsPerPage,
      ordering: order === "desc" ? `-${orderBy}` : orderBy,
      user: user,
    };
    setLoading(true);
    await api
      .get("videos/assets/", { params })
      .then((res) => {
        setData(res.data.results);
        console.log(res.data.results);
        setRowCountState(res.data.count);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally((e) => {
        setLoading(false);
      });
  };

  const fetchUsers = async () => {
    try {
      setOptions((pre) => ({ ...pre, loading: true }));
      const res = await api.get("videos/user/assets");
      setOptions((pre) => ({ ...pre, data: res.data }));
    } catch (err) {
      console.error(err);
    } finally {
      setOptions((pre) => ({ ...pre, loading: false }));
    }
  };

  return (
    <Box>
      <Typography variant="h4" paragraph>
        Assets Sheet
      </Typography>
      {!props.user?.creator && (
        <Autocomplete
          sx={{ width: 300, mb: 2 }}
          onOpen={fetchUsers}
          size="small"
          value={selectedUser}
          onChange={(e, value) => {
            selectUser(value);
            fetchData(undefined, undefined, undefined, undefined, value);
          }}
          options={options.data}
          loading={options.loading}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Users"
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <React.Fragment>
                    {options.loading ? (
                      <CircularProgress color="inherit" size={20} />
                    ) : null}
                    {params.InputProps.endAdornment}
                  </React.Fragment>
                ),
              }}
            />
          )}
        />
      )}

      <Box
        sx={{
          bgcolor: "grey.100",
          "&.MuiPaper-root": {
            borderRadius: 4,
          },
          "& .MuiTableCell-head:first-child ": {
            borderTopLeftRadius: "10px !important",
          },
          "& .MuiTableCell-head:last-child ": {
            borderTopRightRadius: "10px !important",
          },
        }}
        component={Paper}
        elevation={3}
      >
        {loading && <LinearProgress />}

        <GenericTable
          data={data}
          columns={[
            { id: "id", label: "ID", sortable: true },
            props.user?.is_staff && {
              id: "username",
              label: "User",
              sortable: true,
            },
            {
              id: "video_title",
              label: "Video Title",
              sortable: true,
              sx: { minWidth: 250 },
            },
            {
              id: "dropbox_link",
              label: "Link",
              render: (row) => (
                <a target="_blank" href={row.dropbox_link} rel="noreferrer">
                  Open
                </a>
              ),
            },
            {
              id: "created_date",
              label: "Created Date",
              sortable: true,
              sx: { minWidth: 250 },
              render: (row) => {
                const date = new Date(row.created_date);
                return date.toLocaleString();
              },
            },
            {
              id: "claimed",
              label: "Claimed",
              sortable: true,
              render: (row) => {
                return row.claimed ? (
                  <IconButton color="primary" disabled>
                    <CheckCircleIcon color="success" />
                  </IconButton>
                ) : (
                  <IconButton color="error" disabled>
                    <CancelIcon sx={{ color: "error.light" }} />
                  </IconButton>
                );
              },
            },
          ]}
          defaultOrderBy="created_date"
          defaultOrder="asc"
          defaultRowsPerPage={10}
          fetchData={fetchData}
          loading={loading}
          rowCountState={rowCountState}
        />
      </Box>
    </Box>
  );
};

const mapStatesToProps = (state) => {
  return {
    user: state.user,
  };
};

export default connect(mapStatesToProps, null)(AssetTable);
