import { Container, Typography } from "@mui/material";
import React from "react";

function TermsofServices() {
  const listItemStyle = {
    fontSize: "18px",
    color: "#1976d2",
    textAlign: "justify"
  };

  const paragraphStyle = {
    fontSize: "12px",
    color: "black"
  };
  return (
    <Container maxWidth="md">
      <Typography variant="h4" textAlign="center" color="primary" paragraph>
        CreatorVault User Agreement Summary
      </Typography>
      <Typography align="justify" variant="body1" paragraph>
        Thank you for agreeing to use CreatorVault to monetize your content and
        safeguard it against unauthorized use. Your agreement with CreatorVault
        explains what permissions you give to us, what our role is, and how we
        work to earn you money. The full terms are set out in the CreatorVault
        User Agreement, which you should read carefully. A summary of the key
        points in the Content Agreement is:
      </Typography>
      <Typography variant="body1" align="justify">
        <Typography component="div" variant="subtitle1" color="primary">
          Uses:
        </Typography>
        <Typography variant="body1" style={{ fontSize: "16px", lineHeight: "1.5", textAlign: "justify" }}>
          This agreement gives CreatorVault and its partners the rights they need
          to monetize and safeguard your video(s) worldwide. We will exclusively
          manage your video(s) globally which includes social media platforms such
          as YouTube, Facebook, TikTok, Instagram, Snap etc (no restrictions).
        </Typography>
      </Typography>
      <Typography variant="body1" align="justify">
        <Typography component="div" variant="subtitle1" color="primary">
          Exclusivity:
        </Typography>
        <Typography variant="body1" style={{ fontSize: "16px", textAlign: "justify" }}>
          The rights you grant to us are worldwide and exclusive. This is required
          to deliver our services.
        </Typography>
      </Typography>
      <Typography variant="body1" align="justify">
        <Typography component="div" variant="subtitle1" color="primary">
          Term:
        </Typography>
        <Typography variant="body1" style={{ fontSize: "16px", textAlign: "justify" }}>
          The initial term of this agreement is one year. We need this minimum
          term in order to best protect and monetize your video(s). You may seek
          to terminate this Agreement under the conditions as set forth in Section
          4 of the agreement.
        </Typography>
      </Typography>
      <Typography variant="body1" align="justify">
        <Typography component="div" variant="subtitle1" color="primary">
          Earnings:
        </Typography>
        <Typography variant="body1" style={{ fontSize: "16px", textAlign: "justify" }}>
          We will pay you an agreed percentage, as set forth in the agreement, of any
          money earned from your video. We will do our best to earn you as much
          revenue as possible but we cannot make any promises as to how much will
          be earned.
        </Typography>
      </Typography>
      <Typography variant="body1" align="justify">
        <Typography component="div" variant="subtitle1" color="primary">
          Ownership:
        </Typography>
        <Typography variant="body1" style={{ fontSize: "16px", textAlign: "justify" }}>
          You must have taken any videos you submit to us. You will retain your
          copyright ownership of them.
        </Typography>
      </Typography>
      <Typography variant="body1" align="justify">
        <Typography component="div" variant="subtitle1" color="primary">
          Your obligations:
        </Typography>
        <Typography variant="body1" style={{ fontSize: "16px", textAlign: "justify" }}>
          You must ensure that your videos are lawful, that you own them and that
          you are entitled to allow us to distribute them. You will not use any
          social media takedown tools (e.g. YouTube copyright Match Tool, Rights
          Manager etc.) to take down reuploads. Instead, you agree to allow
          CreatorVault to "monetize" all re-uploads. Please make sure that you
          comply with the CreatorVault User Agreement at all times.
        </Typography>
      </Typography>
      <Typography mt={2} variant="h6" color="primary" paragraph> CreatorVault User Agreement</Typography>
      <Typography variant="body1" align="justify">
        <Typography style={{ fontSize: "16px", lineHeight: "1.5", textAlign: "justify" }}>
          CreatorVault User Agreement, herein referred to as the (“agreement”)
          which is by and between WooGlobe Ltd. (“WooGlobe”, “we”) doing business
          as “CreatorVault” and the undersigned producer/content owner
          (“producer”, “you”, “your”) who is registering to the service, and has
          agreed to the terms of this user agreement, effective as of today's
          date. This is the effective date (“effective date”).
        </Typography>
        <ol>
          <li style={{ fontSize: "18px", color: "#1976d2", textAlign: "justify" }}>
            Scope
          </li>
          <ol style={{ color: "black" }}>
            <li>
              The purpose of this agreement is to grant global, exclusive,
              non-transferable, irrevocable content licensing rights to WooGlobe.
            </li>
            <li>
              You still retain ownership of all your content, videos, and
              intellectual property.
            </li>
            <li>
              By agreeing to the terms set forth, you are allowing WooGlobe to
              exclusively manage and monetize your video content worldwide without
              limitation.
            </li>
            <li>
              All social media channels listed and submitted by You are considered
              within the scope of this agreement. You are responsible for
              delivering content from these channels to WooGlobe through the
              CreatorVault portal or any other delivery mechanism agreed upon by
              WooGlobe. WooGlobe reserves the right, though is not obligated, to
              automatically download and use the clips from Your channels to
              deliver services under this agreement. By agreeing to this clause,
              You acknowledge and consent to WooGlobe's use of Your content for
              monetisation purposes.
            </li>
          </ol>

          <li style={{ fontSize: "18px", color: "#1976d2", textAlign: "justify" }}>
            Content For Licensing
          </li>
          <ol style={{ color: "black" }}>
            <li>
              The “content” means the content produced and posted by You to your
              social media channels that are being submitted upon registration and
              submission (examples include but not limited to YouTube, Instagram,
              TikTok, Facebook, Snap, etc.), as well as any other content that We
              and You agree in writing to include in this agreement from time to
              time.
            </li>
            <li>
              You may deliver additional content to WooGlobe through CreatorVault platform Or by writing to CreatorServices@CreatorVault.io and
              attaching the content you desire to submit as a file OR via cloud storage with
              your provider of choice, e.g. Dropbox, Onedrive, etc.
            </li>
            <li>
              At the time of this agreement, the social video content has been
              submitted by you to WooGlobe for exclusive, non-transferrable,
              irrevocable content management services; including but not limited
              to digital rights management on YouTube and other social media
              platforms (no restrictions).
            </li>
            <li>
              All content on the pages set forth on the onboarding registration
              submission form has been submitted to WooGlobe with global,
              exclusive, non-transferrable, and irrevocable rights and will be
              subject to review for its usage within WooGlobe and its network.
              Please note that not all content may be eligible, and that
              WooGlobe's team will review the submitted content herein to ensure
              third-party platform compliance.
            </li>
          </ol>

          <li style={{ fontSize: "18px", color: "#1976d2", textAlign: "justify" }}>
            Fees and Payments
          </li>
          <ol style={{ color: "black" }}>
            <li>
              WooGlobe will pay You the agreed share of the net revenue earned and
              received by WooGlobe less any expenses for all ad-revenues generated
              by Automatic algorithmic claiming (e.g. YouTube Auto Content ID
              etc).
            </li>
            <li>
              WooGlobe may, at its discretion, exploit Your content on social
              media platforms and collaborate with its affiliates to generate
              additional income for You. Any revenues generated through this
              activity shall be shared with You in accordance with the
              revenue-sharing terms outlined on
              https://www.creatorvault.io/drm-tos, which may be subject to change
              from time to time at WooGlobe's sole discretion. By accepting this
              clause, the Creator acknowledges and agrees to the revenue-sharing
              terms set forth by WooGlobe.
            </li>
            <li>
              Payments are sent out within 14-days of revenues received by us. If
              your content generated revenue in January, this revenue is expected
              to be paid on or around February 28th.
            </li>
            <li>
              You will be responsible for taxes and reporting your revenues in
              your own respective country, and WooGlobe will not be held liable in
              any way in the event you neglect your duties to report whatever
              ought to be reported.
            </li>
            <li>
              In the event that ads are displayed on other channels containing
              copyrighted content from multiple claimants, you will be entitled to
              collect a share of the divided revenue with other rights holders
              (e.g. music publishers).
            </li>
            <li>
              WooGlobe is not obligated to pay you for any re-uploaded videos that
              fall outside of Social Media content delivery requirements and
              criteria policies, including content that falls below social media
              platforms required minimum lengths for claiming.
            </li>
            <li>
              You agree that WooGlobe is entitled to deduct a reasonable sum from
              any revenue to cover the costs incurred for the operations. For the
              avoidance of doubt, any such deductions shall be made prior to our
              calculation of the revenue share.
            </li>
            <li>
              The network ("WooGlobe.") will have no obligation to pay any
              amounts, and is permitted to deduct or withhold any amounts owed,
              determined or reasonably suspected by WooGlobe, in its sole
              discretion, to have resulted from: (a) action fraud, including
              without limitation through any clicks originating from talent's IP
              addresses or computers under talent's control, solicited by payment
              of money, false representation or request for end users to click on
              ads, or (b) fraudulent, misleading or false activities or activities
              that WooGlobe or its social media platforms believe to be fraudulent
              or misleading or in violation of either of their respective terms of
              service, guidelines, rules, or privacy policies.
            </li>
            <li>
              The network ("WooGlobe") reserves the right to withhold or deduct
              any payments, pending WooGlobe's reasonable investigation of any of
              the foregoing or any breach of this agreement.
            </li>
            <li>
              Conflict resolution fees will be charged when WooGlobe argues and
              handles a disputed asset resolution on your behalf if you attempt to
              work with multiple content claiming providers at once, thus causing
              extra administrative work for WooGlobe. If in any given month there
              is one or more of these events, you will be charged an additional 5%
              of the partner ad-revenue generated in that given month.
            </li>
          </ol>

          <li style={{ fontSize: "18px", color: "#1976d2", textAlign: "justify" }}>
            Contract period
          </li>
          <ol style={{ color: "black" }}>
            <li>
              The period of this agreement will be for 12-months with automatic
              renewals thereafter, unless you terminate this agreement with
              90-days written notice prior to the renewal date in form of legal
              writing to copyright@creatorvault.io
            </li>
            <li>
              If you terminate this agreement then WooGlobe will refrain from
              placing any future content id matches on any newly uploaded content
              after the agreement termination date takes effect.
            </li>
            <li>
              If you terminate this agreement, then all future payments to you
              will cease. You acknowledge and accept that by terminating your
              agreement with WooGlobe, CreatorVault, or any of its authorized
              affiliates will result in no further payments being made to you
              after the termination date takes place, and you are forfeiting your
              share of the revenue that is being generated by WooGlobe and its
              services.
            </li>
            <li>
              The content, video assets, and intellectual property that you
              created, published, and/or generated prior to, and during the tenure
              of your partnership with the WooGlobe will remain active and the
              exclusive content license will remain non-transferable and active
              within its content management systems. The exclusive license will
              survive the termination of this agreement and will remain
              non-transferable for all content submitted upon registration and
              during the duration of the contract.
            </li>
            <li>
              WooGlobe will retain its exclusive, non-transferable content license
              rights even after termination for all content submitted prior to the
              effective date of your termination. If you decide to work with
              another content claiming company, WooGlobe will keep its right to
              manage the content internally and externally within its systems,
              including but not limited to: Content ID, Rights Manager, and tools
              of this nature.
            </li>
            <li>
              WooGlobe may terminate this agreement immediately with no obligation to You if You are
              in breach of any term of the contract.
            </li>
          </ol>

          <li style={{ fontSize: "18px", color: "#1976d2", textAlign: "justify" }}>
            WooGlobe's Rights and Licenses
          </li>
          <p style={paragraphStyle}>
            You hereby grant to each of the following exclusive and
            non-transferable rights:
          </p>
          <ol style={{ color: "black" }}>
            <li>
              The exclusive right and license to use, reproduce, digitize, encode,
              modify, monetize format, transmit, broadcast, repurpose the content
              into its proprietary software and collect revenues from the
              management of the content. You will be paid your share of the
              partner ad-revenue on a monthly basis for as long as this agreement
              is active.
            </li>
            <li>
              The exclusive right and license to administer content claiming,
              including without limitation, content id claims, and monetization
              services for the content across third-party platforms.
            </li>
            <li>
              The ability to sub-license the content internally or externally to
              third-parties.
            </li>
          </ol>

          <li style={listItemStyle}>
            Ownership/Clearances/Warranties
          </li>
          <ol style={{ color: "black" }}>
            <li>You retain full ownership of your content.</li>
            <li>
              You must have all the necessary rights and clearances in connection
              with the content, and you have the legal right to grant the rights
              to us. By registering, digi-signing, and submitting your content and
              rights to WooGlobe, you hereby absorb all legal liability in the
              event of legal proceedings relating to the content you submitted for
              management.
            </li>
            <li>
              You warrant and represent that the content does not infringe on the
              rights of any third party or violate third-party platform, including
              YouTube’s, terms of use. Additionally, you agree to defend,
              indemnify and hold harmless WooGlobe for any and all claims to the
              contrary.
            </li>
          </ol>

          <li style={listItemStyle}>
            Other Provisions
          </li>
          <ol style={{ color: "black" }}>
            <li>You agree to keep the terms of this agreement confidential.</li>
            <li>
              WooGlobe reserves the right to engage third-party service providers
              to fulfil its obligations under this agreement. These third-party
              service providers may include but are not limited to, vendors,
              subcontractors, and other entities. WooGlobe shall ensure that any
              third-party service providers engaged in delivering services under
              this agreement adhere to the same standards and obligations as
              outlined in this contract. The engagement of third-party service
              providers does not absolve
            </li>
            <li>
              You warrant and represent that the content does not infringe on the
              rights of any third party or violate third-party platform, including
              YouTube’s, terms of use. Additionally, you agree to defend,
              indemnify and hold harmless WooGlobe for any and all claims to the
              contrary.WooGlobe of its responsibilities and liabilities under this
              agreement. WooGlobe shall remain fully responsible for the actions
              and performance of any third-party service providers it engages.
            </li>
            <li>
              You acknowledges and agrees that WooGlobe is not making any
              representation, guarantee or agreement regarding the total amount of
              revenue, if any, which will be generated by the Licensed Rights. You
              agree that the judgment of WooGlobe regarding the exploitation of
              the Licensed Rights shall be binding and conclusive upon You and
              agrees not to make any claim or action that WooGlobe has not
              properly exploited the Licensed Rights, that more revenue could have
              been earned than was actually earned by the exploitation of the
              Licensed Rights, or that any buyout or one-time payment to Licensor
              is insufficient in comparison to the revenue earned by the
              exploitation of the Licensed Rights. Nothing in this Agreement shall
              obligate WooGlobe to actually use or to exploit the Licensed Rights.
            </li>
            <li>
              You agree that by entering your name into the space designated above
              or through the use of any electronic signature software/service or
              by any other means, you are agreeing to the terms of this agreement
              electronically. You agree that the electronic signature is the legal
              equivalent of manual signature on this Agreement and that no
              certification authority or other third party verification is
              necessary to validate Licensor’s e-signature. The lack of such
              certification or third party verification will not in any way affect
              the enforceability of Your e-signature or any resulting contract
              between You and WooGlobe.
            </li>
            <li>
              You hereby agree to indemnify, release and hold harmless WooGlobe,
              its successors, licensees, sub-distributors and assigns, and the
              directors, officers, employees, representatives and agents of each
              of the foregoing, from any and all claims, demands, causes of
              action, damages, judgments, liabilities, losses, costs, expenses,
              and attorney’s fees arising out of or resulting from (i) any breach
              of any warranty, representation or any other provision of this
              Agreement, and/or (ii) any claims of or respecting slander, libel,
              defamation, invasion of privacy or right of publicity, false light,
              infringement of copyright or trademark, or violations of any other
              rights arising out of or relating to any use by WooGlobe of the
              rights granted under this Agreement. You acknowledge that WooGlobe
              is relying on the representations contained in this Agreement and a
              breach by You would cause WooGlobe irrevocable injury and damage
              that cannot be adequately compensated by damages in an action at law
              and Licensor therefore expressly agrees that, without limiting
              WooGlobe’s remedies, WooGlobe shall be entitled to injunctive and
              other equitable relief.
            </li>
            <li>
              WooGlobe reserves the right to terminate this agreement at its sole
              discretion by providing written notice to the other party at least
              30 days prior to the intended termination date. Upon receipt of such
              notice, both parties shall cooperate to conclude any outstanding
              obligations in an orderly manner. In the event of termination, the
              other party shall not be entitled to any compensation, damages, or
              remedies, except for the outstanding payment due to You up to the
              effective date of termination. Termination of this agreement shall
              not relieve either party of any obligations accrued prior to the
              effective date of termination.
            </li>
            <li>
              This Agreement shall be deemed to have been executed and delivered
              within England, UK, and the rights and obligations of the parties
              hereunder shall be construed and enforced in accordance with English
              law, without regard to the conflicts of law principles thereof.
            </li>
            <li>
              Any disputes relating to these terms and conditions shall be subject
              to the non-exclusive jurisdiction of the courts of England. The
              parties agree to the personal jurisdiction by and venue in England,
              and waive any objection to such jurisdiction or venue irrespective
              of the fact that a party may not be a resident of England. Except
              for WooGlobe’s equitable rights as set forth in this Agreement, the
              parties hereby agree to submit any disputes or controversies arising
              from, relating to or in connection with this Agreement or the
              parties’ respective obligations in connection therewith to binding
              arbitration in England in accordance with the English law and only
              for actual monetary damages, if any. In the event of any dispute,
              Licensor shall not be entitled to, and does hereby waive all right
              to, any equitable relief whatsoever, including the right to rescind
              its agreement to these Terms, to rescind any rights granted
              hereunder, or to enjoin, restrain or interfere in any manner with
              the marketing, advertisement, distribution or exploitation of the
              Licensed Rights. All rights to recover consequential, incidental
              and/or punitive damages are waived by You.
            </li>
            <li>
              Upon submission of acceptance of this user agreement, You agree that
              all information in this form is true and accurate. You also agree
              that if You are under 18, your parent/guardian will sign this user
              agreement and absorb all legal liability for this user agreement.
            </li>
            <li>
              You agree to, and are hereby bound to, our terms of service, found
              here, which may be amended from time to time. These terms include
              the exclusive, explicit, non-transferable content licensing rights
              to the content contractually submitted by you to WooGlobe. These
              rights remain even if you terminate this agreement, in accordance to
              section 4 of this agreement.
            </li>
            <li>
              Electronic communication is a sufficient method to notify a party.
            </li>
            <li>
              By signing, you agree as follows: "I understand I am granting a
              non-transferable, irrevocable, fully exclusive license to all of the
              content submitted on the social media pages I am submitting, and
              that WooGlobe (WooGlobe Ltd, CreatorVault, and any other of its
              authorized divisions without limitation) may represent and manage
              this content on my behalf without limitation."
            </li>

          </ol>
        </ol>
      </Typography>
    </Container>
  );
}

export default TermsofServices;

