import { Box, Divider, IconButton, Paper, Typography } from '@mui/material'
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import SlowMotionVideoIcon from "@mui/icons-material/SlowMotionVideo";
import React, { useEffect } from 'react'
import api from '../../conf/api';
import { useState } from 'react';
import LineBaseChart from "../../Components/Charts/LineBaseChart";
import Selector from "../../Components/Selectors/Selector";


const InfoBox = (props) => {
    const { title, count, Icon, bgcolor } = props;

    return (
        <Box
            component={Paper}
            display="flex"
            borderRadius={4}
            gap={{ xs: 1, md: 2 }}
            padding={"25px 15px"}
            maxHeight={"160px"}
            minHeight={"100%"}
            width="100%"
            flexDirection={{ xs: "column", md: "row" }}
            sx={{ background: bgcolor }}
            elevation={3}
        >
            <IconButton
                sx={{
                    width: { xs: 40, md: 80 },
                    height: { xs: 40, md: 80 },
                    bgcolor: "rgba(0, 0, 0, 0.2)",
                }}
            >
                <Icon sx={{ fontSize: { xs: 20, md: 50 }, color: "white" }} />
            </IconButton>
            <Box display="flex" flexDirection="column" flexGrow={1}>
                <Typography
                    color="white"
                    fontWeight={300}
                    noWrap
                    sx={{
                        variant: { xs: "h6", md: "h5" },
                        paragraph: { xs: false, md: true },
                    }}
                >
                    {title}
                </Typography>
                <Divider sx={{ bgcolor: "#fff", mt: 1 }} />
                <Typography variant="h5" color="white">
                    {count}
                </Typography>
            </Box>
        </Box>
    );
};
function Dashboard() {
    const [meta, setMeta] = useState({
        channels_data: 0,
        compilations_data: 0,
        compilation_videos_data: 0
    })
    const [dateRange, setDateRange] = useState({ start: null, end: null })
    const [graphs, setGraphs] = useState({
        "channels_data": { loading: false, date: { start: null, end: null }, data: [] },
        "compilations_data": { loading: false, date: { start: null, end: null }, data: [] },
        "compilation_videos_data": { loading: false, date: { start: null, end: null }, data: [] }
    })
    const GetGraphsData = async () => {
        setGraphs((prevData) => ({
            ...prevData,
            channels_data: { ...prevData.channels_data, loading: true, data: [] },
            compilations_data: { ...prevData.compilations_data, loading: true, data: [] },
            compilation_videos_data: { ...prevData.compilation_videos_data, loading: true, data: [] },
        }));
        api.get("/promo/graphs", { params: dateRange }).then(res => {
            if (res.status === 200) {
                setGraphs((prevData) => ({
                    ...prevData,
                    channels_data: { ...prevData.channels_data, loading: false, data: res.data.channels_data },
                    compilations_data: { ...prevData.compilations_data, loading: false, data: res.data.compilations_data },
                    compilation_videos_data: { ...prevData.compilation_videos_data, loading: false, data: res.data.compilation_videos_data },
                }));
            }
        }).catch(error => {
        })
    }
    useEffect(() => {
        const GetMeta = async () => {
            api.get("/promo/counts").then(res => {
                if (res.status === 200) {
                    setMeta(res.data)
                }
            }).catch(error => {
            })
        }
        GetMeta()
    }, [])
    useEffect(() => {
        GetGraphsData()
        // eslint-disable-next-line
    }, [dateRange])
    return (
        <Box>
            <Typography variant="h4">Dashboard</Typography>
            <Grid2
                container
                spacing={{ xs: 1, md: 2 }}
                justifyContent={{ xs: "center", md: "flex-start" }}
                mt={2}
            >
                <Grid2 xs={12} sm={4} lg={3}>
                    <InfoBox
                        title="Channels"
                        count={meta.channels_data || 0}
                        Icon={SlowMotionVideoIcon}
                        bgcolor="linear-gradient(45deg, #147274, #2a9d9b)"
                    />
                </Grid2>
                <Grid2 xs={12} sm={4} lg={3}>
                    <InfoBox
                        title="Compilations"
                        count={meta.compilations_data || 0}
                        Icon={SlowMotionVideoIcon}
                        bgcolor="linear-gradient(45deg, #FF6666, #CC3333)"
                    />
                </Grid2>
                <Grid2 xs={12} sm={4} lg={3}>
                    <InfoBox
                        title="Compilation Videos"
                        count={meta.compilation_videos_data || 0}
                        Icon={SlowMotionVideoIcon}
                        bgcolor="linear-gradient(45deg, #2E8B57, #99d299)"
                    />
                </Grid2>
                <Grid2 container xs={12} mt={3}>
                    <Grid2 xs={12} p={"0px 50px"} sx={{ marginBottom: 2 }}>
                        <Box width="100%" textAlign="-webkit-right">
                            <Selector sx={{ width: "50%", textAlign: "left" }} setDateRange={(start, end) => {
                                setDateRange({ start: start, end: end })
                            }} />
                        </Box>
                    </Grid2>
                    <Grid2 xs={12} >
                        <Box >
                            <Box
                                p={"0px 30px"}
                                display="flex"
                                alignItems="center"
                                justifyContent="space-between"
                            >
                                <Typography variant="h5">Channels</Typography>

                            </Box>
                            <Box>
                                <LineBaseChart data={graphs.channels_data?.data} yDatakey={"channels"} xDatakey={"date"} />
                            </Box>
                        </Box>
                    </Grid2>
                    <Grid2 xs={12} md={6}>
                        <Box >
                            <Box p={"0px 30px"} display="flex"
                                alignItems="center" justifyContent="space-between">
                                <Typography variant="h5">Compilations</Typography>

                            </Box>
                            <Box>
                                <LineBaseChart data={graphs.compilations_data?.data} yDatakey={"compilations"} xDatakey={"date"} />
                            </Box>
                        </Box>
                    </Grid2>
                    <Grid2 xs={12} md={6}>
                        <Box>
                            <Box p={"0px 30px"} display="flex"
                                alignItems="center" justifyContent="space-between">
                                <Typography variant="h5">Compilation Videos</Typography>

                            </Box>
                            <Box>
                                <LineBaseChart data={graphs.compilation_videos_data?.data} yDatakey={"videos"} xDatakey={"date"} />
                            </Box>
                        </Box>
                    </Grid2>
                </Grid2>
            </Grid2>
        </Box >
    )
}

export default Dashboard
