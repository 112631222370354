import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import api from '../../../conf/api.js';
import { Box, CircularProgress, Divider, IconButton, LinearProgress, Tooltip } from '@mui/material';
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import TextInput from "../../../Components/Fields/TextInput.js";
import { toast } from 'react-toastify';
import { isValidYtId } from '../../../utils.js';

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

export default function UserChannelsPreview({ open, setOpen, selectedRow }) {
	const [channels, setChannels] = React.useState([]);
	const [loading, setLoading] = React.useState(false);

	const handleClose = () => {
		setOpen(false);
	};

	const getUserChannels = async () => {
		try {
			setLoading(true)
			const response = await api.get(`/promo/channals/${selectedRow.id}`);
			if (response.status === 200) {
				setChannels(response.data);
			}
		} catch (error) {
			console.error('Error fetching user channels:', error);
		}
		finally {
			setLoading(false)
		}
	};
	React.useEffect(() => {
		if (open && selectedRow) {
			getUserChannels();
		}
		// eslint-disable-next-line
	}, [open, selectedRow]);


	const ApproveChannal = (channel) => async () => {
		if (!isValidYtId(channel.channel_id)) {
			toast.error("YouTube ID is not valid");
			return;
		}
		setLoading(true)
		await api.put(`/promo/channals/status/${channel.name}`).then(response => {
			if (response.status >= 200) {
				toast.success(response.data);
				getUserChannels();
			}

		}).catch(res => {
			if (res.response?.status === 406) {
				toast.dismiss()
				toast.warning(res.response.data);
			}
			else {
				console.error('Error fetching user channels:', res);
			}
		}).finally((res) => {
			setLoading(false);
		});

	}
	const handleChannalUpdate = (channel) => async (e) => {
		if (!isValidYtId(channel.channel_id)) {
			toast.error("YouTube ID is not valid");
			return;
		}
		setLoading(true)
		await api.put(`/promo/channels/${channel.name}/`, { ...channel, channel_id: e.target.value }).then(response => {
			if (response.status >= 200) {
				toast.success(response.data);
				getUserChannels();
			}

		}).catch(res => {
			if (res.response?.status === 406) {
				toast.dismiss()
				toast.warning(res.response.data);
			}
			else {
				console.error('Error fetching user channels:', res);
			}
		}).finally((res) => {
			setLoading(false);
		});

	}
	const handleChange = (e, channel) => {
		const updatedChannels = channels.map(ch => {
			if (ch.name === channel.name) {
				return { ...ch, channel_id: e.target.value };
			}
			return ch;
		});
		setChannels(updatedChannels);
	};

	return (
		<Dialog
			open={open}
			TransitionComponent={Transition}
			onClose={handleClose}
			aria-describedby="alert-dialog-slide-description"
			maxWidth="xl"
		>
			<DialogTitle>{`${selectedRow.email} / ${selectedRow.username}`}</DialogTitle>
			<DialogContent>
				<DialogContentText id="alert-dialog-slide-description">
					<strong>Channels</strong> associated with selected user (<i style={{ textDecoration: "underline" }}>{selectedRow.username}</i>):
				</DialogContentText>
				<Divider variant="inset" sx={{ mb: 1 }} />
				{loading &&
					<Box width="100%" mt={2} mb={1}><LinearProgress /></Box>
				}
				<TableContainer component={Paper} >
					<Table size="small" >
						<TableHead sx={{ bgcolor: "primary.light" }}>
							<TableRow>
								<TableCell>Name</TableCell>
								<TableCell>URL</TableCell>
								<TableCell>Created Date</TableCell>
								<TableCell sx={{ minWidth: 120 }}>Is Approved</TableCell>
								<TableCell sx={{ minWidth: 150 }}>Channel ID</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{channels.map(channel => (
								<TableRow key={channel.name}>
									<TableCell>{channel.name}</TableCell>
									<TableCell component={"a"} href={channel.url} target="_blank">{channel.url}</TableCell>
									<TableCell>{(new Date(channel.created_date)).toLocaleString()}</TableCell>
									<TableCell sx={{ textAlign: "center" }}>{loading ? <CircularProgress size={22} /> : channel.is_approved ?
										<DoneIcon color='success' />
										:
										<Tooltip title="Click to approve">
											<IconButton sx={{ bgcolor: "grey.200" }} size='small' onClick={ApproveChannal(channel)}>
												<CloseIcon color='error' />
											</IconButton>
										</Tooltip>
									}
									</TableCell>
									<TableCell>
										<Tooltip title="Press enter to Change">
											<TextInput
												value={channel.channel_id}
												onChange={(e) => handleChange(e, channel)}
												placeholder="Channal id"
												size="small"
												onKeyDown={(e) => e.key === 'Enter' && handleChannalUpdate(channel)(e)}
											/>
										</Tooltip>
									</TableCell>
								</TableRow>
							))}
						</TableBody>
					</Table>
				</TableContainer>
			</DialogContent>
			<DialogActions>
				<Button onClick={handleClose}>Close</Button>
			</DialogActions>
		</Dialog >
	);
}
