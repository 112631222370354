import React, { useCallback, useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import {
	Autocomplete,
	Box,
	Checkbox,
	FormControlLabel,
	Grid,
	TextField,
	Typography
} from "@mui/material";
import { toast } from "react-toastify";
import TextInput from "../../../../Components/Fields/TextInput.js"
import api from "../../../../conf/api.js";
import TimeDurationPicker from "../../../../Components/Fields/TimeDurationPicker.js";
import dayjs from "dayjs";

export default function ChannelSchedulerBox({
	open,
	selectedRow,
	onClose
}) {
	let initialFormData = {
		channel: null,
		selected_days: [],
		time_from: "",
		time_to: "",
		min_uploading_gap: "",
		max_uploading_gap: ""
	};
	let daysOfWeek = [
		{ value: 0, label: "Monday" },
		{ value: 1, label: "Tuesday" },
		{ value: 2, label: "Wednesday" },
		{ value: 3, label: "Thursday" },
		{ value: 4, label: "Friday" },
		{ value: 5, label: "Saturday" },
		{ value: 6, label: "Sunday" },
	]

	const [isUpdate, setIsUpdate] = useState(false);
	const [formData, setFormData] = useState(initialFormData);
	const [channels, setChannels] = useState([]);

	const handleChange = (event) => {
		const { name, value } = event.target;
		setFormData({
			...formData,
			[name]: value,
		});
	};
	const handleDays = (event) => {
		const value = parseInt(event.target.value);
		if (
			formData.selected_days?.find(day => day === value)
			|| formData.selected_days?.find(day => day === value) === 0
		) {
			setFormData(pre => ({ ...pre, selected_days: pre.selected_days.filter(d => d !== value) }))
		}
		else {
			setFormData(pre => ({ ...pre, selected_days: [...pre.selected_days, value] }))
		}
	}
	const handleDuration = (field_name) => (_value) => {
		var value = _value;
		if (_value) {
			value = `${_value.hour()}:${_value.minute()}:${_value.second()}`;
		}
		setFormData(pre => ({ ...pre, [field_name]: value }));
	}
	const handleClose = () => {
		onClose();
	};
	const handleSubmit = async (event) => {
		event.preventDefault();
		const data = formatData();
		if (!isValidTimeRange(data.time_from, data.time_to)) {
			toast.error("Invalid time range.");
			return;
		}
		if (isUpdate) {
			await updateChannelScheduler(data);
		} else {
			await addChannelScheduler(data);
		}
	};

	const updateChannelScheduler = async (data) => {
		try {
			const response = await api.patch(`/youtube/channel/scheduler/${formData.id}/`, data);
			if (response.status === 200) {
				toast.success("Channel Scheduler updated successfully.");
				onClose();
			}
		} catch (error) {
			handleErrorResponse(error);
		}
	};

	const addChannelScheduler = async (data) => {
		try {
			const response = await api.post("/youtube/channel/scheduler/", data);
			if (response.status === 201) {
				toast.success(response.data?.message);
				toast.success("Channel Scheduler added successfully.");
				onClose();
			}
		} catch (error) {
			handleErrorResponse(error);
		}
	};

	const handleErrorResponse = (error) => {
		const errorObject = error.response?.data;
		if (errorObject) {
			for (const field in errorObject) {
				if (errorObject.hasOwnProperty(field)) {
					const errors = errorObject[field];
					errors.forEach((errorMessage) => {
						toast.error(`${field}: ${errorMessage}`);
					});
				}
			}
		} else {
			toast.error("Something went wrong");
		}
	};

	async function fetchData() {
		await api
			.get(`/youtube/channel/get-scheduler/${selectedRow.channel_name}/`)
			.then((res) => {
				if (res.status === 200) {
					if (res.data.results.length > 0) {
						var _data = res.data?.results[0];
						_data.time_from = convertStringToDuration(_data.time_from);
						_data.time_to = convertStringToDuration(_data.time_to);
						setIsUpdate(true);
						setFormData(_data);
					}
				}
				else {
					onClose();
				}
			})
			.catch((err) => {
				console.log(err);
			})
	}
	async function fetchChannels() {
		await api
			.get("/youtube/channels/view", { params: { page: 1, page_size: 10000 } })
			.then((res) => {
				const resp = res.data?.results;
				setChannels(resp);
				if (!isUpdate) {
					const channel = resp.find(c => c.channel_name === selectedRow.channel_name)
					if (channel) {
						setFormData(pre => ({ ...pre, channel: channel }))
					}
				}
			})
	}

	const convertStringToDuration = useCallback(
		(_time) => {
			const [hour, min, sec] = _time.split(":");
			const duration = dayjs().set('hour', hour).set('minute', min).set('second', sec);
			return duration;
		},
		// eslint-disable-next-line
		[]
	);
	const SelectDays = useCallback(
		() => (
			daysOfWeek.map((day) => (
				<Grid item>
					<FormControlLabel
						control={
							<Checkbox
								value={day.value}
								onChange={handleDays}
								checked={
									formData.selected_days?.find(d => d === day.value)
									|| formData.selected_days?.find(d => d === day.value) === 0
								}
							/>
						}
						label={day.label}
					/>
				</Grid>
			))
		),
		// eslint-disable-next-line
		[formData.selected_days]
	);

	useEffect(() => {
		console.log(formData.channel, selectedRow)
	}, [formData.channel])

	useEffect(
		() => {
			fetchData();
			fetchChannels();
		},
		// eslint-disable-next-line
		[]
	);

	function formatData() {
		let _data = { ...formData };
		_data.channel = formData.channel?.channel_name;
		var _time_from = _data.time_from;
		var _time_to = _data.time_to;
		if (dayjs.isDayjs(_time_from)) {
			_time_from = `${_time_from.hour()}:${_time_from.minute()}:${_time_from.second()}`;
		}
		if (dayjs.isDayjs(_time_to)) {
			_time_to = `${_time_to.hour()}:${_time_to.minute()}:${_time_to.second()}`;
		}
		_data.time_from = _time_from;
		_data.time_to = _time_to;
		return _data;
	};
	const isValidTimeRange = (timeFrom, timeTo) => {
		const [fromHour, fromMin, fromSec] = timeFrom.split(":").map(Number);
		const [toHour, toMin, toSec] = timeTo.split(":").map(Number);
		const fromTotalSeconds = fromHour * 3600 + fromMin * 60 + fromSec;
		const toTotalSeconds = toHour * 3600 + toMin * 60 + toSec;
		return fromTotalSeconds < toTotalSeconds;
	};

	return (
		<Dialog maxWidth="md" open={open} onClose={handleClose}>
			<DialogTitle>{isUpdate ? "Update" : "Add"} Channel Scheduler</DialogTitle>
			<DialogContent>
				<DialogContentText mb={2}>
					Please fill in the required information to{" "}
					{isUpdate ? "update" : "add"} a new channel scheduler.
				</DialogContentText>
				<form onSubmit={handleSubmit}>
					<Grid container marginBottom={3} spacing={3} alignItems="center">
						<Grid item xs={12}>
							<Autocomplete
								readOnly={isUpdate}
								value={formData.channel}
								options={channels}
								getOptionLabel={(op) => op.channel_name || null}
								size="small"
								fullWidth
								placeholder="Select Channel"
								onChange={(event, newValue) => {
									setFormData(pre => ({ ...pre, channel: newValue }));
								}}
								renderInput={(params) => (
									<TextField
										{...params}
										required
										label="Channel"
										InputProps={{
											...params.InputProps,
											endAdornment: (
												<React.Fragment>
													{params.InputProps.endAdornment}
												</React.Fragment>
											),
										}}
									/>
								)}
							/>
						</Grid>
						<Grid
							container
							item
							sx={{ justifyContent: "space-between", padding: "16px 0 0 16px" }}
						>
							<SelectDays />
						</Grid>
						<Grid item container xs={12} spacing={2}>
							<Grid item xs={6}>
								<Box display="flex" flexDirection="column" gap={.5}>
									<TimeDurationPicker
										sx={{ width: "100%" }}
										required
										label="From"
										value={formData.time_from}
										onChange={handleDuration("time_from")}
									/>
									<Typography variant="caption">Note: You are 5 hours ahead of server time.</Typography>
								</Box>
							</Grid>
							<Grid item xs={6}>
								<Box display="flex" flexDirection="column" gap={.5}>
									<TimeDurationPicker
										sx={{ width: "100%" }}
										ampm={false}
										required
										label="To"
										value={formData.time_to}
										onChange={handleDuration("time_to")}
									/>
									<Typography variant="caption">Note: You are 5 hours ahead of server time.</Typography>
								</Box>
							</Grid>
						</Grid>
						<Grid item container xs={12} spacing={2}>
							<Grid item xs={6}>
								<TextInput
									label="Min Uploading Gap (in minutes)"
									type="text"
									fullWidth
									required
									name="min_uploading_gap"
									value={formData.min_uploading_gap}
									onChange={handleChange}
								/>
							</Grid>
							<Grid item xs={6}>
								<TextInput
									label="Max Uploading Gap (in minutes)"
									type="text"
									fullWidth
									required
									name="max_uploading_gap"
									value={formData.max_uploading_gap}
									onChange={handleChange}
								/>
							</Grid>
						</Grid>
					</Grid>
					<DialogActions>
						<Button onClick={handleClose}>Cancel</Button>
						<Button variant="contained" type="submit" color="primary">
							{isUpdate ? "Update" : "Add"} Channel Scheduler
						</Button>
					</DialogActions>
				</form>
			</DialogContent>
		</Dialog>
	);
}
