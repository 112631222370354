import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import { Box } from "@mui/material";
import api from "../../../conf/api";
import { toast } from "react-toastify";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function ConfirmPassReset({
  open,
  setOpen,
  setPassDialog,
  username,
  data,
}) {
  const handleClose = () => {
    setOpen(false);
    setPassDialog(false);
  };
  const updatePass = async () => {
    await api.post(`auth/reset-password/`, {
        username: username,
        new_password: data.password
    }).then((res) => {
      if (res.status === 200) {
        toast.success("Password is updated successfuly");
        setOpen(false);
        setPassDialog(false);
      }
    });
  };

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
    >
      <DialogTitle>
        <Box display="flex" gap={2}>
          <WarningAmberIcon fontSize="large" color="warning" />
          {`Are you sure you want to update (${username})'s password.`}
        </Box>
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-slide-description">
          This action will update the user's password. Remember the new password from now on.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Disagree</Button>
        <Button onClick={updatePass} color="error">
          Agree
        </Button>
      </DialogActions>
    </Dialog>
  );
}
