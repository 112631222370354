import { toast } from "react-toastify";

export function DelayedExecution(callback, seconds = 5000) {
  setTimeout(() => {
    callback();
  }, seconds);
}

export const ValidUrl = (string) => {
  try {
    const urlRegex = /^(https?:\/\/)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(:\d+)?(\/[a-zA-Z0-9-_.~%\/?#&=]*)?$/;
    return urlRegex.test(string.trim());
  }
  catch {
    return null;
  }
};
export const isValidIPv4 = (string) => {
  try {
    const ipv4Regex = /^(25[0-5]|2[0-4][0-9]|[0-1]?[0-9]?[0-9])\.(25[0-5]|2[0-4][0-9]|[0-1]?[0-9]?[0-9])\.(25[0-5]|2[0-4][0-9]|[0-1]?[0-9]?[0-9])\.(25[0-5]|2[0-4][0-9]|[0-1]?[0-9]?[0-9])$/;
    return ipv4Regex?.test(string?.trim());
  }
  catch {
    return null;
  }
};

export const isValidYtId = (string) => {
  const ytIdRegex = /^(?![_-])(?!.*[_-]{2})[a-zA-Z0-9_-]{8,}(?<![_-])$/;
  return ytIdRegex.test(string);
}

export const isValidDateRange = (date_from, date_to) => {
  if (date_from && date_to) {
    if (date_from >= date_to) {
      return false;
    }
  }
  else if (date_from || date_to) {
    return false;
  }
  return true;
};

export function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}
export const downloadFile = (url, fileName = "downloaded-file") => {
  // Use the Fetch API to fetch the file
  fetch(url)
    .then((response) => response.blob())
    .then((blob) => {
      // Create a blob URL for the fetched content
      const blobUrl = window.URL.createObjectURL(blob);

      // Create a temporary anchor element
      const anchor = document.createElement("a");

      // Set the download attribute with the desired file name
      anchor.download = fileName;

      // Set the href attribute to the blob URL
      anchor.href = blobUrl;

      // Append the anchor to the document body
      document.body.appendChild(anchor);

      // Trigger a click event on the anchor to start the download
      anchor.click();

      // Remove the anchor from the document body
      document.body.removeChild(anchor);

      // Revoke the blob URL to free up resources
      window.URL.revokeObjectURL(blobUrl);
    })
    .catch((error) => {
      console.error("Error downloading file:", error);
    });
};

export const passwordValidation = (password) => {
  const minLength = 8;
  const hasUppercase = /[A-Z]/.test(password);
  const hasLowercase = /[a-z]/.test(password);
  const hasDigit = /\d/.test(password);
  // eslint-disable-next-line
  const hasSpecialChar = /[!@#$%^&*()_+{}\[\]:;<>,.?~\\-]/.test(password);
  if (password.length < minLength) {
    toast.error("Password should be at least 8 characters long.");
    return false;
  }
  if (!hasUppercase) {
    toast.error("Password should contain at least one uppercase letter.");
    return false;
  }
  if (!hasLowercase) {
    toast.error("Password should contain at least one lowercase letter.");
    return false;
  }
  if (!hasDigit) {
    toast.error("Password should contain at least one digit.");
    return false;
  }
  if (!hasSpecialChar) {
    toast.error("Password should contain at least one special character.");
    return false;
  }

  return true;
};