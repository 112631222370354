// DataTable.js

import React, { useCallback } from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Checkbox from "@mui/material/Checkbox";
import { alpha } from "@mui/material/styles";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Scrollbars from "react-custom-scrollbars-2";
import { Button } from "@mui/material";

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const EnhancedTableHead = (props) => {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
    headCells,
    is_admin,
  } = props;

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };
  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={
              headCell.align
                ? headCell.align
                : headCell.numeric
                ? "right"
                : "left"
            }
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
            </TableSortLabel>
          </TableCell>
        ))}
        {is_admin && <TableCell align="center">Action</TableCell>}
      </TableRow>
    </TableHead>
  );
};

const EnhancedTableToolbar = (props) => {
  const { numSelected, title, rows, handleAprroveVideos, handlePayClick } =
    props;
  let count = 0;
  rows.map((r) => (count += r.amount));
  const not_approved = rows.filter((r) => r.approval !== true);
  const payables = rows.filter((r) => r.approval === true && !r.paid);
  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity
            ),
        }),
      }}
    >
      {numSelected > 0 ? (
        <Typography
          sx={{ flex: "1 1 100%" }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          Selected{" "}
          <b>
            {numSelected}
            <small>r</small>
          </b>{" "}
          - Total revenue <b>${count.toFixed(2)}</b>
        </Typography>
      ) : (
        <Typography
          sx={{ flex: "1 1 100%" }}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          {title}
        </Typography>
      )}
      {not_approved.length > 0 && (
        <Button
          sx={{ minWidth: 100 }}
          onClick={() => handleAprroveVideos(not_approved)}
          variant="contained"
          color="success"
        >
          Aprrove
        </Button>
      )}
      {payables.length > 0 && (
        <Button
          sx={{ ml: 2, minWidth: 100 }}
          onClick={() => handlePayClick(payables)}
          variant="contained"
          color="primary"
        >
          Pay
        </Button>
      )}
    </Toolbar>
  );
};

const DataTable = (props) => {
  const {
    title,
    headCells,
    tableRows,
    page,
    setPage,
    rowsPerPage,
    setRowsPerPage,
    handleAprroveVideos,
    ActionRow,
    is_admin,
    handlePayClick,
  } = props;

  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("column0");
  const [selected, setSelected] = React.useState([]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      setSelected(tableRows);
    } else {
      setSelected([]);
    }
  };

  const handleClick = (row) => () => {
    const selectedRows = selected.find((r) => r.id === row.id);

    if (selectedRows === undefined) {
      setSelected((pre) => [...pre, row]);
    } else {
      setSelected((pre) => pre.filter((r) => r.id !== row.id));
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (row) => selected.find((r) => r.id === row.id);

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - tableRows.length) : 0;

  const visibleRows = React.useMemo(
    () =>
      stableSort(tableRows, getComparator(order, orderBy)).slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage
      ),
    [order, orderBy, page, rowsPerPage, tableRows]
  );
  const TableCustomRow = useCallback(
    ({ row, index, ActionRow }) => {
      const isItemSelected = isSelected(row) !== undefined;
      const labelId = `enhanced-table-checkbox-${index}`;
      return (
        <TableRow
          hover
          onClick={handleClick(row)}
          role="checkbox"
          tabIndex={-1}
          key={index}
          selected={isItemSelected}
          sx={{ cursor: "pointer" }}
        >
          <TableCell padding="checkbox">
            <Checkbox
              color="primary"
              checked={isItemSelected}
              inputProps={{
                "aria-labelledby": labelId,
              }}
            />
          </TableCell>
          {headCells.map((headCell, columnIndex) =>
            headCell.valueFormate ? (
              <TableCell
                align="center"
                sx={headCell.sx}
                key={columnIndex}
                id={labelId}
              >
                {headCell.valueFormate(row[headCell.id])}
              </TableCell>
            ) : (
              <TableCell
                sx={headCell.sx}
                key={columnIndex}
                id={labelId}
                align={headCell.numeric ? "right" : "left"}
                padding="none"
              >
                {row[headCell.id]}
              </TableCell>
            )
          )}
          <TableCell align="center">
            <ActionRow row={row} />
          </TableCell>
        </TableRow>
      );
    },
    // eslint-disable-next-line
    [selected]
  );

  return (
    <Paper sx={{ width: "100%", mb: 2, borderRadius: 4 }}>
      <EnhancedTableToolbar
        handleAprroveVideos={handleAprroveVideos}
        title={title}
        numSelected={selected.length}
        rows={selected}
        handlePayClick={handlePayClick}
      />
      <TableContainer sx={{ p: 2 }}>
        <Box component={Scrollbars} autoHide style={{ height: "50vh" }}>
          <Table stickyHeader sx={{ minWidth: 1000 }}>
            <EnhancedTableHead
              is_admin={is_admin}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={tableRows.length}
              headCells={headCells}
            />
            <TableBody>
              {visibleRows.map((row, index) => {
                return (
                  <TableCustomRow
                    row={row}
                    index={index}
                    ActionRow={ActionRow}
                  />
                );
              })}
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: 53 * emptyRows,
                  }}
                >
                  <TableCell colSpan={headCells.length + 1} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </Box>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 50, 100]}
        component="div"
        count={tableRows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
};

DataTable.propTypes = {
  headCells: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      numeric: PropTypes.bool,
      disablePadding: PropTypes.bool,
    })
  ).isRequired,
  tableRows: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default DataTable;
