import {
  Container,
  Typography,
} from "@mui/material";
import React from "react";

function TermsDialog() {
  return (
    <Container maxWidth="md">
            <Typography variant="h6" textAlign="center" color="primary" paragraph>
              CreatorVault User Agreement Summary
            </Typography>
            <Typography align="justify" variant="body1" paragraph>
              Thank you for agreeing to use CreatorVault to monetise your content and
              safeguard it against unauthorised used. Your agreement with CreatorVault
              explains what permissions you give to us, what our role is and how we
              work to earn you money. The full terms are set out in the CreatorVault
              User Agreement, which you should read carefully. A summary of the key
              points in the Content Agreement is:
            </Typography>
            <Typography variant="body1" align="justify">
              <Typography component="div" variant="subtitle1" color="primary">
                Uses:
              </Typography>
              This agreement gives CreatorVault and its partners the rights they need
              to monetise and safeguard your video(s) worldwide. We will exclusively
              manage your video(s) globally which includes social media platforms such
              as YouTube, Facebook, TikTok, Instagram, Snap etc (no restrictions).
            </Typography>
            <Typography variant="body1" align="justify">
              <Typography component="div" variant="subtitle1" color="primary">
                Exclusivity:
              </Typography>
              The rights you grant to us are worldwide and exclusive. This is required
              to deliver our services.
            </Typography>
            <Typography variant="body1" align="justify">
              <Typography component="div" variant="subtitle1" color="primary">
                Term:
              </Typography>
              The initial term of this agreement is one year. We need this minimum
              term in order to best protect and monetise your video(s). You may seek
              to terminate this Agreement under the conditions as set forth in Section
              4 of the agreement.
            </Typography>
            <Typography variant="body1" align="justify">
              <Typography component="div" variant="subtitle1" color="primary">
                Earnings:
              </Typography>
              We will pay you agreed percentage, as set forth in agreement, of any
              money earned from your video. We will do our best to earn you as much
              revenue as possible but we cannot make any promises as to how much will
              be earned.
            </Typography>
            <Typography variant="body1" align="justify">
              <Typography component="div" variant="subtitle1" color="primary">
                Ownership:
              </Typography>
              You must have taken any videos you submit to us. You will retain your
              copyright ownership of them.
            </Typography>
            <Typography variant="body1" align="justify">
              <Typography component="div" variant="subtitle1" color="primary">
                Your obligations:
              </Typography>
              You must ensure that your videos are lawful, that you own them and that
              you are entitled to allow us to distribute them. You will not use any
              social media takedown tools (e.g. YouTube copyright Match Tool, Rights
              Manager etc.) to take down reuploads. Instead, you agree to allow
              CreatorVault to "monetize" all re-uploads. Please make sure that you
              comply with the Creator Vault User Agreement at all times
            </Typography>
          </Container>
  );
}

export default TermsDialog;

