import React, { useCallback, useEffect, useState } from "react";
import {
  Autocomplete,
  Box,
  Divider,
  IconButton,
  Paper,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import dayjs from "dayjs";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import api from "../../conf/api.js";
import { connect } from "react-redux";
import { DataGrid } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import PriceCheckIcon from "@mui/icons-material/PriceCheck";
import RecommendIcon from "@mui/icons-material/Recommend";
import ApprovalDialogBox from "./components/ApprovalDialogBox.js";
import PaymentDialog from "./components/PaymentDialog.js";

function UsersEvenue(props) {
  const initialFilterField = {
    from_date: dayjs().subtract(7, "days"),
    to_date: dayjs(),
    user: null,
  };
  const [filters, setFilters] = React.useState(initialFilterField);
  const [users, setUsers] = useState([]);
  const [selectedRows, selectRows] = useState([]);
  const [approveDialog, showApproveDialog] = useState(false);
  const [paymentDialog, showPaymentDialog] = useState(false)

  const [data, setData] = useState([]);

  const handleDateFilter = (_fields) => (newValue) => {
    setFilters((pre) => ({ ...pre, [_fields]: newValue }));
  };

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const navigation = useNavigate();

  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  const handlePageSizeChange = (newPageSize) => {
    setRowsPerPage(newPageSize);
  };
  const fetchData = async () => {
    try {
      const formattedStartDate = filters.from_date.format("YYYY-MM-DD");
      const formattedEndDate = filters.to_date.format("YYYY-MM-DD");
      const params = {
        page: page + 1,
        page_size: rowsPerPage,
        start_date: formattedStartDate,
        end_date: formattedEndDate,
      };
      const user = filters.user;
      if (user) {
        params["user"] = filters.user.username;
      }

      const response = await api(`/earning/promo/summary`, { params: params });
      const result = response;
      if (response.status === 200) {
        setData(result.data.results);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const UserAutoComplete = useCallback(
    () => (
      <Autocomplete
        size="small"
        sx={{ minWidth: 535 }}
        options={users || []}
        value={filters.user}
        onChange={(e, value) => {
          setFilters((pre) => ({ ...pre, user: value }));
        }}
        getOptionLabel={(option) => option?.username}
        renderInput={(params) => <TextField {...params} label="Select User" />}
      />
    ),
    [users, filters.user]
  );
  const handleApprovalDialog = (row) => (event) => {
    event.stopPropagation();
    FechRows(row.id, "approval");
  };
  const handlePaymentDialog = (row) => (event) => {
    event.stopPropagation();
    FechRows(row.id, "paid_only");
  };
  const FechRows = async (user_id, _param) => {
    try {
      const params = { page_size: 10000 };

      params[_param] = false;

      params["user"] = user_id;

      const response = await api(`/earning/promo/list`, { params: params });
      const result = response;
      if (response.status === 200) {
        selectRows(result.data);
        if (_param === "approval") {
          showApproveDialog(true);
        } else if (_param === "paid_only") {
          showPaymentDialog(true);
        }
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const ApprovalDialog = useCallback(() => {
    return (
      <ApprovalDialogBox
        open={approveDialog}
        setOpen={showApproveDialog}
        rows={selectedRows}
        refreshFunc={fetchData}
        is_promo={true}
      />
    );
    // eslint-disable-next-line
  }, [approveDialog, selectedRows]);

  const PaymentDialogBox = useCallback(() => {
    return (
      <PaymentDialog
        selectedRows={selectedRows}
        open={paymentDialog}
        setOpen={showPaymentDialog}
        refreshFunc={fetchData}
        setSelection={selectRows}
        is_promo={true}
      />
    );
    // eslint-disable-next-line
  }, [selectedRows, paymentDialog]);

  const columns = [
    {
      field: "action",
      headerName: "Action",
      width: 200,
      renderCell: (row) => {
        return (
          <Box display="flex" justifyContent="space-between">
            <Tooltip title="Pay">
              <IconButton onClick={handlePaymentDialog(row)}>
                <PriceCheckIcon color="success" />
              </IconButton>
            </Tooltip>
            <Tooltip title="Approve payments">
              <IconButton onClick={handleApprovalDialog(row)}>
                <RecommendIcon color="success" />
              </IconButton>
            </Tooltip>
          </Box>
        );
      },
    },
    { field: "username", headerName: "User", flex: 1 },
    {
      field: "total_approval",
      headerName: "Aprroved",
      type: "number",
    },
    {
      field: "total_paid",
      headerName: "Paid",
      type: "number",
    },
    {
      field: "total_partner_revenue",
      headerName: "Total revenues",
      type: "number",
      width: 200,
    },
    {
      field: "total_none_approval_amount",
      headerName: "Not Aprroved",
      type: "number",
    },
    {
      field: "total_none_paid_amount",
      headerName: "Not Paid",
      type: "number",
    },

    {
      field: "total_wooglobe_revenue",
      headerName: "Wooglobe revenus",
      type: "number",
      width: 200,
    },
  ];
  useEffect(() => {
    if (props.user?.creator && props.user?.user_id) {
      navigation(`/earning/assets/:${props.user?.user_id}`)
    }

    // eslint-disable-next-line
  }, [props.user?.creator]);

  useEffect(() => {
    if (filters.from_date && filters.to_date) {
      fetchData();
    }

    // eslint-disable-next-line
  }, [
    filters.to_date,
    filters.from_date,
    filters.paid_only,
    filters.approval,
    filters.user,
    page,
    rowsPerPage,
  ]);


  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await api("/users/list", {
          params: { page: 1, page_size: 50000, is_promouser: true },
        }); // Adjust the API endpoint accordingly
        if (response.status === 200) {
          setUsers(response.data.results);
        }
      } catch (error) {
        console.error("Error fetching users:", error);
      }
    };
    fetchUsers();

    // eslint-disable-next-line
  }, []);

  return (
    <Box display="flex" flexDirection="column" gap={2}>
      <Typography variant="h4">Promo Earnings By Assets</Typography>

      <Box
        display={"flex"}
        gap={2}
        alignItems={!props.is_admin ? "center" : "inherit"}
        justifyContent={!props.is_admin ? "space-between" : "flex-start"}
        flexDirection={!props.is_admin ? "row" : "column"}
      >
        <Box display="flex" justifyContent="space-between">
          {props.is_admin && <UserAutoComplete />}
        </Box>
        <Box
          display="flex"
          gap={2}
          sx={{
            "& input": {
              height: 10,
            },
          }}
        >
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label="Controlled picker"
              value={filters.from_date}
              onChange={handleDateFilter("from_date")}
            />
          </LocalizationProvider>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label="Controlled picker"
              value={filters.to_date}
              onChange={handleDateFilter("to_date")}
            />
          </LocalizationProvider>
        </Box>
      </Box>
      <Divider />
      <Box component={Paper} height={500} elevation={1}>
        <DataGrid
          density="compact"
          rows={data}
          columns={columns}
          onRowClick={(row) => {
            navigation(`/earning/promo/${row.id}`);
          }}
          rowSelection={false}
          page={page}
          pageSize={rowsPerPage}
          onPageChange={handlePageChange}
          onPageSizeChange={handlePageSizeChange}
        />
      </Box>
      <ApprovalDialog />
      <PaymentDialogBox />
    </Box>
  );
}
const mapStatesToProps = (state) => {
  return {
    is_admin: state.user?.is_staff,
    user: state.user
  };
};
export default connect(mapStatesToProps, null)(UsersEvenue);
