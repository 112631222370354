import {
  Box,
  Container,
  Typography,
} from "@mui/material";
import React from "react";
import wooglobeLogo from "../../../static/images/wooglobe.png";

function PromoTermsDialog() {
  return (
    <Container maxWidth="md">
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Box
          component="img"
          src={wooglobeLogo}
          height="70px"
          display={{ xs: "none", sm: "block" }}
        />
      </Box>
      <Typography variant="h6" textAlign="center" color="black" paragraph>
        <b>CONTENT LICENSE AGREEMENT</b>
      </Typography>
      <Typography align="justify" variant="body1" paragraph>
        This Content License Agreement (the <b><i>“Agreement”</i></b>) is entered into as of the last signature date
        indicated below (the <b><i>“Effective Date”</i></b>) by and between WooGlobe Ltd., a corporation organized and
        operating under the laws of England and Wales, United Kingdom, whose address is 9 Barn End
        Drive, Dartford, United Kingdom (<b><i>“Wooglobe”</i></b>), and the undersigned content licensor
        (“Producer”). In consideration of the terms and conditions contained herein and other good and
        valuable consideration, the receipt and sufficiency of which is hereby acknowledged, the parties
        agree as follows:
      </Typography>
      <Typography variant="body1" align="justify" paragraph>
        <Typography component="div" variant="subtitle1" color="black">
          <b>COMPILATION CONTENT</b>
        </Typography>
        In order to provide Producer with an incentive to create compilation videos that embody clips made
        available to you via WooGlobe portal (“WooGlobe Licensed Clips”), WooGlobe hereby grants
        Producer the rights necessary to incorporate WooGlobe Licensed Clips into Compilation Videos
        (“Compilation Videos”) on the following conditions: (a) Producer includes only WooGlobe Licensed
        Clips in any Compilations Videos posted to the Approved Channels/Pages; and (b) Producer
        acknowledges and agrees that WooGlobe will claim and collect 100% of the revenue generated
        from the Compilation Videos, and shall pay Producer the Compilation Ad Net Revenue Share
        specified in paragraph 5.
      </Typography>
      <Typography variant="body1" align="justify" paragraph>
        <Typography component="div" variant="subtitle1" color="black">
          <b>OWNERSHIP</b>
        </Typography>
        Producer shall retain full ownership of, and absolute liability for, your page, including all material
        contained therein, whether related to its creation, procurement, uploading, or other activity, and the
        ongoing management, and arrangement of the page(s). Producer may not sell ownership of the
        page, or enter into agreements with any content owners other than WooGlobe. Producer may not
        create similar compilation pages during the term that are not included under this agreement.
        Producer may not promote, link or drive traffic to pages, websites, social profiles outside of this
        agreement without prior written consent from WooGlobe. WooGlobe may require rebranding of the
        page, including a name change and implementation of new design elements. Any design elements
        or trademarks implemented on the page shall be owned by the party responsible for creating those
        design elements and or trademarks.
      </Typography>
      <Typography variant="body1" align="justify" paragraph>
        <Typography component="div" variant="subtitle1" color="black">
          <b>TERM</b>
        </Typography>
        The term of this Agreement (the <b><i>“Term”</i></b>) will commence upon the Effective Date and will continue
        for five (5) years. The Term shall automatically renew for consecutive one (1) year periods, unless
        one party notifies the other party in writing at least Thirty (30) days prior to the expiration of the
        then-current period that the Term shall not extend past the expiration of such period.
      </Typography>
      <Typography variant="body1" align="justify" paragraph>
        <Typography component="div" variant="subtitle1" color="black">
          <b>REMOVAL</b>
        </Typography>
        WooGlobe may request that you remove certain Content, or disable advertising on certain Content,
        if it is deemed unsuitable for advertising purposes. Such reasonable requests will be issued by
        WooGlobe via email or telephone, and must be handled within Forty-Eight (48) hours by Producer.
        In the event that Producer fails to notify WooGlobe within Forty-Eight (48) hours of Producer's
        receipt of a takedown notice, WooGlobe shall have the right to termination of this Agreement, in its
        sole discretion.
      </Typography>
      <Typography variant="body1" align="justify" paragraph>
        <Typography component="div" variant="subtitle1" color="black">
          <b>NET REVENUE SHARE</b>
        </Typography>
        WooGlobe shall pay Producer Thirty Percent (30%) of Compilation Advertising Net Revenue.
        (<b><i>“Compilation Ad Net Revenue”</i></b>) shall be defined as gross revenue collected by WooGlobe from
        advertising or other revenue associated with Producer's Compilation Content claimed by
        WooGlobe, less any costs or expenses incurred by WooGlobe in connection therewith.
      </Typography>
      <Typography variant="body1" align="justify" paragraph>
        <Typography component="div" variant="subtitle1" color="black">
          <b>TAXES</b>
        </Typography>
        All payments made in connection with this Agreement are exclusive of taxes imposed by
        governmental entities of whatever kind and imposed with respect to the transactions for services
        provided under this Agreement. Each party shall be responsible for any taxes relating to payments
        it makes or receives under this Agreement, and shall indemnify each other for any claim based on
        the contrary. Producer must submit a completed IRS Form W-9 or W-8, where applicable,
        (www.irs.gov) to WooGlobe prior to receiving payment.
      </Typography>
      <Typography variant="body1" align="justify" paragraph>
        <Typography component="div" variant="subtitle1" color="black">
          <b>ACCOUNTING AND PAYMENT</b>
        </Typography>
        Producer grants WooGlobe the rights to collect, calculate, and distribute net revenue generated
        from the Compilation Content. Producer is required to set up a PayPal account to receive
        payments. WooGlobe will issue payments to Producer on a monthly basis via PayPal. All
        payments will be processed by WooGlobe and delivered to Producer within sixty (60) days of when
        WooGlobe receives funds. Any wire transfer fees, ACH fees, or other reasonable administrative
        fees charged to WooGlobe in order to process monthly payments shall be deducted from net
        revenue before payment is issued by WooGlobe. Send all email inquires regarding payments to
        (payments@WooGlobemanagement.com). WooGlobe shall provide Producer with full disclosure of
        monetization data through a Digital Entertainment Service's analytics, if available (i.e., Facebook
        Analytics), upon request. <b>Failure to meet the conditions set out in section one (1)
          'Compilation Content' to WooGlobe's reasonable satisfaction will result in payments being
          delayed or withheld.</b>
      </Typography>
      <Typography variant="body1" align="justify" paragraph>
        <Typography component="div" variant="subtitle1" color="black">
          <b>CONFIDENTIAL INFORMATION</b>
        </Typography>
        <b><i>“Confidential Information”</i></b> means information concerning business methods, talent roster, and
        business plans of WooGlobe or Producer, technical information concerning their business, the
        terms and conditions of this Agreement, or any other information disclosed by one party to the
        other that is clearly designated as confidential. Neither party will use the other party's Confidential
        Information except as necessary for the performance of this Agreement and will not disclose such
        Confidential Information to any third party except to those of its employees and subcontractors that
        need to know such Confidential Information for the purpose of performing this Agreement, provided
        that each such employee and subcontractor is subject to a written agreement that includes binding
        use and disclosure restrictions that are at least as protective as those set forth herein. Each party
        will use all reasonable efforts to maintain the confidentiality of all such Confidential Information in
        its possession or control, but in no event less that the efforts that such party ordinarily uses with
        respect to its own proprietary information of similar nature or importance. The foregoing obligations
        will not restrict either party from disclosing Confidential Information of the other party: (a) pursuant
        to the order or requirement of a court, administrative agency, or other governmental body, provided
        that the party required to make such a disclosure gives reasonable notice to the other party to
        contest such order or requirement; or (b) on a confidential basis to its legal or financial advisors.
      </Typography>
      <Typography variant="body1" align="justify" paragraph>
        <Typography component="div" variant="subtitle1" color="black">
          <b>TERMINATION</b>
        </Typography>
        WooGlobe may terminate this Agreement at any time for any reason by giving at least thirty (30)
        days notice in writing to Producer. Such termination shall not be deemed a breach of contract.
        WooGlobe agrees to pay Producer for all unpaid earnings up to the date of termination.
      </Typography>
      <Typography variant="body1" align="justify" paragraph>
        <Typography component="div" variant="subtitle1" color="black">
          <b>REPRESENTATIONS AND WARRANTIE</b>
        </Typography>
        Producer represents and warrants to WooGlobe that Producer has full power and authority to enter
        into this Agreement and to fulfill its obligations hereunder.
      </Typography>
      <Typography variant="body1" align="justify" paragraph>
        <Typography component="div" variant="subtitle1" color="black">
          <b>INDEMNIFICATION</b>
        </Typography>
        Producer will indemnify, defend, and hold WooGlobe and its officers, directors, agents, Affiliates,
        and employees, harmless from and against any and all damages, awards, judgments, liabilities,
        costs, or expenses, including reasonable attorneys' fees and costs resulting from any third party
        allegation, claim, action, or proceedings arising out of or related to Producer's breach or alleged
        breach of any obligation, representation, or warranty in this Agreement. As an express condition to
        Producer's obligation under this paragraph 11, WooGlobe must: (a) promptly notify Producer in
        writing of the claim; (b) grant Producer sole control of the defense and settlement of the claim; and
        (c) provide Producer, at Producer's expense, with all assistance, information and authority
        reasonably required for the defense and settlement of the claim. Producer will not be bound by any
        settlement or compromise that WooGlobe enters into without Producer's prior written consent.
      </Typography>
    </Container>
  );
}

export default PromoTermsDialog;

