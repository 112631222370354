import React, { useCallback, useEffect, useState } from 'react'
import api from '../../../conf/api'
import Grid from '@mui/material/Unstable_Grid2';
import { Chip, Divider, Typography } from '@mui/material';
import DoneIcon from '@mui/icons-material/Done';
import ChannelsList from './ChannelsList';

function CategoriesSelection({ selectedCtgs, handleAdd }) {
    const [data, setData] = useState([])

    useEffect(() => {
        async function fetchCategorie() {
            api.get("/youtube/categories/").then(res => {
                setData(res.data)
            }).catch(err => {
                console.log(err)
            })
        }
        fetchCategorie()
    },
        // eslint-disable-next-line
        [])

    return <Grid container spacing={2}>
        <Grid xs={12}>
            <Typography>Categories</Typography>
        </Grid>
        {data.map(ctg => (
            <Grid container p={2} key={ctg.name}>
                <Chip
                    label={`${ctg.name}`}
                    onClick={() => handleAdd(ctg)}
                    sx={{ minWidth: 150 }}
                    variant={selectedCtgs.find(pre => pre.id === ctg.id) ? "outlined" : "filled"}
                    color={selectedCtgs.find(pre => pre.id === ctg.id) ? 'success' : "default"}
                    icon={selectedCtgs.find(pre => pre.id === ctg.id) && <DoneIcon />}
                />
            </Grid>
        ))}
    </Grid>
}
// function LanguagesSelection({ selectedLanguages, handleAdd }) {
//     const [data, setData] = useState([])
//     useEffect(() => {
//         async function fetchCategorie() {
//             api.get("/youtube/languages/").then(res => {
//                 setData(res.data)
//             }).catch(err => {
//                 console.log(err)
//             })
//         }
//         fetchCategorie()
//     }, [])
//     return <Grid container spacing={2}>
//         <Grid xs={12}>
//             <Typography>Languages</Typography>
//         </Grid>
//         {data.map(ctg => (
//             <Grid container p={2}>
//                 <Chip
//                     label={`${ctg.name}`}
//                     onClick={() => handleAdd(ctg)}
//                     sx={{ minWidth: 150 }}
//                     variant={selectedLanguages.find(pre => pre.id === ctg.id) ? "outlined" : "filled"}
//                     color={selectedLanguages.find(pre => pre.id === ctg.id) ? 'success' : "default"}
//                     icon={selectedLanguages.find(pre => pre.id === ctg.id) && <DoneIcon />}
//                 />
//             </Grid>
//         ))}
//     </Grid>
// }

function ScopsSelection({ categories, channels, handleCategoriesSelection, handleChannelsSelect }) {
    const [selectedCtgs, selectCtgs] = useState(categories);

    const handleAddCategory = (ctg) => {
        const _ctg = selectedCtgs.find(c => c.id === ctg.id);
        let new_ctgs;
        if (_ctg) {
            new_ctgs = selectedCtgs.filter(c => c.id !== ctg.id)
        }
        else {
            new_ctgs = [ctg, ...selectedCtgs]
        }
        selectCtgs(new_ctgs);
        handleCategoriesSelection(new_ctgs);
    }
    const ChannelList = useCallback(() => {
        return (
            < ChannelsList
                channels={channels}
                categories={categories}
                handleChannelsSelect={handleChannelsSelect}
            />
        )
    },
        // eslint-disable-next-line
        [channels, selectedCtgs])

    return (
        <Grid container spacing={2} sx={{ justifyContent: { xs: "flex-start", md: "space-around" } }} alignItems="center">
            <Grid>
                <CategoriesSelection selectedCtgs={selectedCtgs} handleAdd={handleAddCategory} />
            </Grid>
            <Grid xs={12}>
                <Divider variant="middle" />
            </Grid>
            <Grid xs={12}>
                {selectedCtgs.length > 0 &&
                    <ChannelList />
                }
            </Grid>
        </Grid>
    )
}

export default ScopsSelection
