import React, { useState } from "react";
import {
  Box,
  Button,
  Typography,
  LinearProgress,
} from "@mui/material";
import SigninImage from "../../../static/images/signin.png";
import { connect } from "react-redux";
import { authLogin } from "../../../store/actions/actions.js";
import PasswordField from "../../../Components/Fields/PasswordField.js";
import TextInput from "../../../Components/Fields/TextInput.js";

function Login(props) {
  const initialFields = { email: "", password: "" };
  const [fields, setFields] = useState(initialFields);

  const handleFieldChange = (e) => {
    setFields((pre) => ({ ...pre, [e.target.name]: e.target.value }));
  };
  const handleLoginReq = (e) => {
    e.preventDefault();
    props._login(fields.email, fields.password);
  };

  return (
    <Box
      height="95vh"
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <Box
        p={{ xs: "20px 40px", md: "40px 100px" }}
        display="flex"
        gap={2.5}
        flexDirection="column"
        alignItems="center"
      >
        <Box
          gap={12}
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <Box
            component="img"
            src={SigninImage}
            height="300px"
            display={{ xs: "none", sm: "block" }}
          />
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            gap={1.5}
            minWidth={300}
            component={"form"}
            onSubmit={handleLoginReq}
          >
            <Box display="flex" alignItems="center" gap={0.5}>
              <Box
                component="img"
                src={
                  "https://static.vecteezy.com/system/resources/previews/021/013/593/original/key-lock-icon-on-transparent-background-free-png.png"
                }
                height={25}
              />
              <Typography variant="h4" fontWeight="bold">
                Login
              </Typography>
            </Box>
            <TextInput
              value={fields.email}
              onChange={handleFieldChange}
              name="email"
              required
              placeholder="Email address"
              variant="outlined"
              type="email"
            />
            <PasswordField
              value={fields.password}
              onChange={handleFieldChange}
              name="password"
              placeholder="Password"
            />
            {/* <FormControlLabel
              control={<Checkbox defaultChecked size="small" />}
              disableTypography
              labelPlacement="end"
              label="Remember me"
              sx={{ fontFamily: `"Roboto","Helvetica","Arial",sans-serif` }}
            /> */}
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: 1.5,
              }}
            >
              {props.loading ? (
                <Box width="100%">
                  <LinearProgress color="success" />
                </Box>
              ) : (
                <Button
                  type="submit"
                  disabled={props.loading}
                  fullWidth
                  variant="contained"
                >
                  Login
                </Button>
              )}
              {/* Divider with "or" written in the center */}
              {/* <Divider sx={{ width: "100%", color: "grey" }}>
                <Typography variant="body2" sx={{ color: "grey" }}>
                  OR
                </Typography>
              </Divider>
              <Button
                fullWidth
                variant="contained"
                sx={{
                  backgroundColor: "success.main",
                }}
                component={Link}
                to="/creator_signup"
              >
                Create new account
              </Button> */}
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
const mapStatesToProps = (state) => {
  return {
    loading: state.loading,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    _login: (email, password) => dispatch(authLogin(email, password)),
  };
};
export default connect(mapStatesToProps, mapDispatchToProps)(Login);
